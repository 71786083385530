import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState("citizen");

  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    setIsAuthenticated(!!accessToken);
  }, []);

  const login = (token) => {
    sessionStorage.setItem("accessToken", token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    sessionStorage.removeItem("accessToken");
    setIsAuthenticated(false);
  };

  const setRoleBasedRoute = (r) => {
    setRole(r);
  } 

  return (
    <AuthContext.Provider value={{ isAuthenticated, role, setRoleBasedRoute, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
