import React, { useState, useEffect, useRef } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Edit, CommandColumn, Inject } from "@syncfusion/ej2-react-grids";

import { USStates } from "./../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from '../../firebase';  // Import Firebase config
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import "./../style.css";

const ContactsDisaster = ({ className = "" }) => {
    const [disasterContacts, setDisasterContacts] = useState([]);
    const gridRef = useRef(null);
    const [userId, setUserId] = useState(null);

    const fetchContacts = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'disaster-contacts'));
            const contactsData = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(contact => !contact.is_deleted);
            setDisasterContacts(contactsData);
        } catch (error) {
            console.error("Error fetching contacts:", error);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
                fetchContacts();
            } else {
                setUserId(null);
                setDisasterContacts([]);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleAddContactsDisaster = () => {
        if (gridRef.current) {
            gridRef.current.addRecord();
        }
    };

    const generateContactID = () => {
        return `CID-${Math.random().toString(36).substr(2, 9)}`;
    };

    const actionBegin = async (args) => {
        if (args.requestType === 'save') {
            const data = args.data;

            if (!data.name || !data.email || !data.phone || !data.role || !data.department) {
                args.cancel = true;
                alert("Please fill in all fields before saving.");
                return;
            }

            data.contactID = data.contactID || generateContactID();
            data.last_updated = serverTimestamp();
            data.user_id = userId;
            data.is_deleted = false;

            try {
                if (args.action === 'add') {
                    data.inserted_at = serverTimestamp();
                    const docRef = await addDoc(collection(db, 'disaster-contacts'), data);
                    data.id = docRef.id; // Add the new document ID to the data
                } else if (args.action === 'edit') {
                    const contactDoc = doc(db, 'disaster-contacts', data.id);
                    await updateDoc(contactDoc, data);
                }
                
                // Update the local state
                setDisasterContacts(prevContacts => {
                    const index = prevContacts.findIndex(contact => contact.id === data.id);
                    if (index !== -1) {
                        // Update existing contact
                        return prevContacts.map(contact => contact.id === data.id ? {...contact, ...data} : contact);
                    } else {
                        // Add new contact
                        return [...prevContacts, data];
                    }
                });
            } catch (error) {
                console.error("Error saving document:", error);
            }
        } else if (args.requestType === 'delete') {
            args.cancel = true; // Prevent actual deletion
            const data = args.data[0];

            try {
                if (data.id) {
                    const contactDoc = doc(db, 'disaster-contacts', data.id);
                    await updateDoc(contactDoc, { is_deleted: true });
                    console.log("Contact marked as deleted:", data.id);
                    
                    // Remove the deleted contact from the local state
                    setDisasterContacts(prevContacts => 
                        prevContacts.filter(contact => contact.id !== data.id)
                    );
                }
            } catch (error) {
                console.error("Error marking contact as deleted:", error);
                alert("Error marking the contact as deleted in the database.");
            }
        }
    };

    return (
        <div className="w-full relative flex flex-col items-start justify-center leading-[normal] tracking-[normal] mt-2">
            <div className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch rounded-3xs border-[1px] border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left font-raleway text-xs text-darkslateblue">
                <div className="self-stretch bg-white flex flex-col items-start justify-start max-w-full">
                    <div
                        className={`w-[1588px] max-w-full flex flex-row items-start justify-between leading-[normal] tracking-[normal] gap-5 text-left text-xs text-dodgerblue font-raleway mq280:flex-wrap ${className}`}
                    >
                        <div className="w-[159px] flex flex-col items-start justify-start">
                            <a className="[text-decoration:none] relative leading-[21px] font-bold text-[inherit] inline-block min-w-[52px]">
                                Contacts
                            </a>
                            <div className="self-stretch relative leading-[21px] font-medium text-silver-200">
                                Last Edited: 4 minutes ago
                            </div>
                        </div>
                        <div className="flex flex-col items-start justify-start pt-[7px] px-0 pb-0">
                            <div className="flex flex-row items-start justify-start gap-2.5">
                                <button
                                    type="button"
                                    className="cursor-pointer [border:none] py-[5px] px-[9px] bg-dodgerblue rounded-md flex flex-row items-start justify-start gap-2"
                                    onClick={handleAddContactsDisaster}
                                >
                                    <div className="flex flex-col items-start justify-start pt-px px-0 pb-0">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M14.0672 6.39191H13.2651V5.60774C13.2651 5.27296 12.9966 5 12.6658 5C12.3357 5 12.0665 5.27296 12.0665 5.60774V6.39191H11.2658C10.935 6.39191 10.6665 6.66487 10.6665 6.99965C10.6665 7.33443 10.935 7.6074 11.2658 7.6074H12.0665V8.39226C12.0665 8.72704 12.3357 9 12.6658 9C12.9966 9 13.2651 8.72704 13.2651 8.39226V7.6074H14.0672C14.3973 7.6074 14.6665 7.33443 14.6665 6.99965C14.6665 6.66487 14.3973 6.39191 14.0672 6.39191Z" fill="white"/>
                                            <path d="M6.33325 10.0107C3.63607 10.0107 1.33325 10.442 1.33325 12.1648C1.33325 13.887 3.62205 14.3337 6.33325 14.3337C9.02977 14.3337 11.3333 13.9025 11.3333 12.1797C11.3333 10.4568 9.04445 10.0107 6.33325 10.0107Z" fill="white"/>
                                            <path opacity="0.4" d="M6.33332 8.3698C8.1697 8.3698 9.64174 6.87879 9.64174 5.01873C9.64174 3.15868 8.1697 1.66699 6.33332 1.66699C4.49694 1.66699 3.0249 3.15868 3.0249 5.01873C3.0249 6.87879 4.49694 8.3698 6.33332 8.3698Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <a className="[text-decoration:none] relative text-xs font-semibold font-raleway text-white text-left inline-block">
                                        Add New
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="self-stretch bg-white flex flex-col items-start justify-start max-w-full">
                    <GridComponent
                        dataSource={disasterContacts}
                        editSettings={{
                            allowEditing: true,
                            allowAdding: true,
                            allowDeleting: true,
                            showDeleteConfirmDialog: true,
                            mode: 'Dialog',
                        }}
                        ref={gridRef}
                        actionBegin={actionBegin}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field="contactID" headerText="Contact ID" isPrimaryKey={true} isIdentity={true} visible={false} />
                            <ColumnDirective field="name" headerText="Name" validationRules={{ required: true }} />
                            <ColumnDirective field="email" headerText="Email" validationRules={{ required: true }} />
                            <ColumnDirective field="phone" headerText="Phone" validationRules={{ required: true }} />
                            <ColumnDirective field="role" headerText="Role" validationRules={{ required: true }} />
                            <ColumnDirective field="department" headerText="Department" validationRules={{ required: true }} />
                            <ColumnDirective headerText="Commands"
                                commands={[
                                    { type: 'Edit', buttonOption: { content: '<i class="fas fa-edit"></i>', cssClass: "e-outline custom-button", } },
                                    { type: 'Delete', buttonOption: { content: '<i class="fas fa-trash-alt"></i>', cssClass: "e-outline custom-button", } },
                                ]}
                            />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter, Edit, CommandColumn]} />
                    </GridComponent>
                </div>
            </div>
        </div>
    );
};

export default ContactsDisaster;