import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown, FaPlus, FaDownload, FaUserPlus } from 'react-icons/fa';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Toolbar, Filter, Edit, Page, Sort, Inject, actionBegin } from "@syncfusion/ej2-react-grids";
import { UploaderComponent ,Uploader} from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { getFirestore, collection, query, doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { onAuthStateChanged, getAuth } from "firebase/auth";
import assistanceLogService from "../../service/citizen/assistanceLogService";

const sortSettings = {
  allowSorting: true,
};

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
};

const filterSettings = {
  type: "Menu",
};

// Initialize Firebase Firestore
const db = getFirestore();
const auth = getAuth();

const RecoveryPlanDisasters = ({ className = '' }) => {
 // State for Recovery Assistance Data
 const [recoveryAssistanceData, setRecoveryAssistanceData] = useState([]);
 const [userId, setUserId] = useState(null);
 const [data, setData] = useState([]);
 
  let elem;
  let uploadObj;
  let strm;
  let selectedRow;

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
            setUserId(currentUser.uid);
            console.log("userid is", currentUser.uid);
        } else {
            setData(null);
            console.log("No user is logged in");
        }
    });

    return () => unsubscribe();
}, []);

useEffect(() => {
    if (userId) {
        fetchData();
    }
}, [userId]);


const fetchData = async () => {
    try {
        // Fetch user document
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
            setData({ id: userDoc.id, ...userDoc.data() });
            console.log("User data:", data); // Log user data

            const recoveryAssistance = await assistanceLogService.fetchRecords(
                userId,
                "recoveryAssistance"
            );
            console.log("Recovery Assistance Data:", recoveryAssistance); // Log recovery assistance data

            setRecoveryAssistanceData(recoveryAssistance); // Ensure this is set correctly
        } else {
            console.error("No such document!");
        }
    } catch (error) {
        console.error("Error fetching data: ", error);
    }
};



  const [showPopup, setShowPopup] = useState(false);

    const toggleGiveAccess = () => {
        setShowPopup(!showPopup);
    };

    const onDialogClose = () => {
        setShowPopup(false);
    };

    const footerTemplate = () => {
      return (
          <div className="flex justify-end gap-3.5 p-2">
              <button className="px-3 py-1.5 rounded cursor-pointer border font-raleway border-gray-300 text-gray-700 font-semibold hover:border-gray-500 hover:bg-gray-100 hover:shadow-sm transition-all duration-300 ease-in-out" onClick={toggleGiveAccess}>Cancel</button>
              <button className="px-3 py-1.5 bg-blue-500 text-white font-raleway rounded flex items-center gap-1 text-xs font-semibold cursor-pointer hover:bg-blue-600 hover:shadow-md transition-all duration-300 ease-in-out">Give Access</button>
          </div>
      );
  };
  
  const formatLastEditedTime = (time) => {
      if (!time) return 'Never';
      
      const now = new Date();
      const diffInSeconds = Math.floor((now - time) / 1000);
      
      if (diffInSeconds < 60) return 'Just now';
      if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
      if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
      if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
      
      return time.toLocaleDateString();
  };

  // State for General section
  const [RecoveryPlanData, setRecoveryPlanData] = useState([]);
  const [isRecoveryPlanVisible, setIsRecoveryPlanVisible] = useState(true);
  const [lastRecoveryPlanEditedTime, setLasRecoveryPlanEditedTime] = useState(null);
  const [maxRecoveryPlanID, setMaxRecoveryPlanID] = useState(RecoveryPlanData.length > 0 ? Math.max(...RecoveryPlanData.map(item => item.RecoveryPlanID)) : 0);

  const toggleRecoveryPlan = (event) => {
      event.preventDefault();
      setIsRecoveryPlanVisible(!isRecoveryPlanVisible);
  };

  const RecoveryPlanGridRef = useRef(null);

  const handleAddClickRecoveryPlan = () => {
      if (RecoveryPlanGridRef.current) {
      RecoveryPlanGridRef.current.addRecord();
      }
  };

  function gridTemplate(props) {
      const src = props.photoRecoveryPlan; // Get the image path from the data source

      return (
          <div className='flex flex-col items-center'>
              {src ? (
                  <img src={src} alt={props.RecoveryPlanID} style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover' }} />
              ) : (
                  <div className="flex flex-col items-center justify-center" style={{ width: '100px', height: '100px', borderRadius: '50%', border: '1px solid #ddd' }}>
                      <p>No Image</p>
                  </div>
              )}
          </div>
      );
  }

  const templateRecoveryPlan = gridTemplate;

  /*
  const imageEditRecoveryPlan = {
      create: () => {
          elem = document.createElement('div');
          return elem;
      },
      read: () => {
          return strm;
      },
      destroy: () => {
          if (uploadObj) {
              uploadObj.destroy();
          }
          strm = null;
      },
      write: args => {
          const path = {
              removeUrl: 'https://services.syncfusion.com/react/production/api/FileUploader/Remove',
              saveUrl: 'https://services.syncfusion.com/react/production/api/FileUploader/Save'
          };

          elem.innerHTML = `
              <div class="flex flex-col justify-center w-full">
                  <label htmlFor="access" class="block mb-2 text-xs text-gray-500">Photo Attachment</label>
                  <div class="flex flex-col items-center">
                      <div id="imagePreview" class="mb-4 flex flex-col items-center justify-center">
                          <!-- Image will be dynamically inserted here -->
                      </div>
                      <div id="dropArea">
                          <label class="upload-button" id="uploadLabel">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                  <polyline points="17 8 12 3 7 8"></polyline>
                                  <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                              Upload Image
                          </label>
                          <input type="file" id="defaultUpload" style="display:none;" />
                      </div>
                  </div>
              </div>
          `;

          const inputElement = elem.querySelector('#defaultUpload');
          const uploadLabel = elem.querySelector('#uploadLabel');
          const imagePreview = elem.querySelector('#imagePreview');

          uploadObj = new Uploader({
              asyncSettings: path,
              success: onUploadSuccess,
              failure: onUploadFailure,
              multiple: false,
              allowedExtensions: ".jpeg, .png, .jpg",
              cssClass: "hidden"
          });

          uploadObj.appendTo(inputElement);

          uploadLabel.addEventListener('click', () => {
              inputElement.click();
          });

          inputElement.addEventListener('change', () => {
              if (inputElement.files.length > 0) {
                  uploadObj.upload(inputElement.files);
              }
          });

          function onUploadSuccess(args) {
              if (args.operation === 'upload') {
                  const file = args.file.rawFile;
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = function () {
                      strm = reader.result;
                      displayUploadedImage(strm, args.file.name);
                  };
                  reader.onerror = function (error) {
                      console.log('Error: ', error);
                  };
              }
          }

          function onUploadFailure(args) {
              console.log('File failed to upload');
          }

          function displayUploadedImage(imageSrc, fileName) {
              imagePreview.innerHTML = `
                  <img src="${imageSrc}" alt="Uploaded Image" style="width: 100px; height: 100px; border-radius: 50%; object-fit: cover;" />
                  <p class="text-sm text-blue-500 mt-2">${fileName}</p>
              `;
          }

          // Display the current image if available
          if (args.rowData.photoRecoveryPlan) {
              displayUploadedImage(args.rowData.photoRecoveryPlan, 'Current Image');
          } else {
              imagePreview.innerHTML = '';
          }
      }
  };
  */

  /*

  const actionBeginRecoveryPlan = (args) => {
      if (args.requestType === 'save' && args.action === 'add') {
        const newRecoveryPlanID = maxRecoveryPlanID + 1;
        args.data.RecoveryPlanID = newRecoveryPlanID;
        setMaxRecoveryPlanID(newRecoveryPlanID);
      }
      if (args.requestType === 'beginEdit') {
          selectedRow = args.rowData;
      }
  };

  const actionCompleteRecoveryPlan = (args) => {

      if (args.requestType === 'save' && args.action === 'edit') {
          const updatedData = RecoveryPlanData.map(item =>
              item.RecoveryPlanID === selectedRow.RecoveryPlanID ? args.data : item
          );
          console.log('Data saved successfully:', args.data);
          setRecoveryPlanData(updatedData);
      }
  };

  */

  

  return <div className={`w-full max-w-[1640px] flex flex-col items-start justify-start py-[15px] box-border ${className}`}>
  <div className="w-full">
      <section className="self-stretch h-auto mt-4 rounded-3xs bg-white border-silver-100 border-[1px] border-solid box-border overflow-hidden p-5">
          <header className="self-stretch flex flex-row items-center justify-between mb-2">
              <div>
              <h2 className="text-sm font-bold font-raleway text-blue-500 mb-1">Recovery Plan</h2>
              <p className="text-xs font-medium font-poppins text-gray-500">
                  Last Edited: {formatLastEditedTime(lastRecoveryPlanEditedTime)}
              </p>
              </div>
              <div className="flex items-center gap-2">
              

              <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 cursor-pointer bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition duration-200"
              >
                  <FaDownload className="mr-2 h-3.5 w-3.5" />
                  Download Unmet Needs Report
              </button>
              <div>
            <button
                type="button"
                className="flex items-center justify-center px-4 py-2 cursor-pointer bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition duration-200"
                onClick={toggleGiveAccess}
            >
                <FaUserPlus className="mr-2 h-4 w-4" />
                Give Access
            </button>

            <DialogComponent
                visible={showPopup}
                width="480px"
                isModal={true}
                closeOnEscape={true}
                header="Give Access"
                footerTemplate={footerTemplate}
                target={document.body}
                close={onDialogClose}
                cssClass="e-grid-dialog"
            >
                
                <div className="flex flex-col px-4 py-2">
                    <div className="flex">
                        <div className="my-auto mr-4 font-semibold text-gray-700">I give permission for</div>
                        <input className="input-style grow rounded-xl bg-zinc-50 py-2.5 pl-3.5 pr-2.5 text-zinc-500" placeholder="Name of Person Receiving" />
                    </div>
                    <div className="mt-5 flex">
                        <div className="my-auto grow font-semibold text-gray-700">from</div>
                        <input className="input-style grow rounded-xl bg-zinc-50 py-2.5 pl-3.5 pr-2.5 text-zinc-500" placeholder="Organization" />
                    </div>
                    <div className="mt-5 font-semibold text-gray-700">access to (until my recovery plan is closed):</div>
                    <ul>
                        <li className="mb-3 flex items-center">
                            <input type="checkbox" id="item1" className="mr-2" />
                            <label htmlFor="item1">View and/or update information to my Damage Assessment, Assistance Log, and/or Recovery Plan.</label>
                        </li>
                        <li className="mb-3 flex items-center">
                            <input type="checkbox" id="item2" className="mr-2" />
                            <label htmlFor="item2">View and respond to Case Management Notes made by myself, my case manager, or a nonprofit partner.</label>
                        </li>
                        <li className="mb-3 flex items-center">
                            <input type="checkbox" id="item3" className="mr-2" />
                            <label htmlFor="item3">View My Past Recovery Plans.</label>
                        </li>
                    </ul>
                    <div className="mt-5 flex">
                        <div className="my-auto font-semibold text-neutral-700">Send access code to</div>
                        <input className="input-style grow rounded-xl bg-zinc-50 py-2.5 pl-3.5 pr-2.5 text-zinc-500" placeholder="Organization Email Address" />
                    </div>
                </div>
            </DialogComponent>
        </div>
              

              <button 
                  type="button"
                  className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer transition-all duration-300 ease-in-out hover:bg-gray-300"
                  onClick={toggleRecoveryPlan}
              >
                  <span className={`transition-transform duration-300 ease-in-out ${isRecoveryPlanVisible ? 'rotate-0' : 'rotate-180'}`}>
                  <FaChevronDown />
                  </span>
              </button>
              </div>
          </header>
          {isRecoveryPlanVisible && (
              <div className="self-stretch flex flex-col gap-4">
         <GridComponent
          dataSource={recoveryAssistanceData.filter(item => !item.is_deleted)} // Use recoveryAssistanceData here
          allowSorting={true}
          sortSettings={sortSettings}
          editSettings={editing}
          filterSettings={filterSettings}
          allowFiltering={true}
        >
                      <ColumnsDirective>
                      <ColumnDirective
                          field="RecoveryPlanID"
                          headerText="RecoveryPlanID"
                          isPrimaryKey={true}
                          visible={false}
                      />
                      <ColumnDirective field='category' headerText='Category' width='100' />
                      <ColumnDirective field='subcategory' headerText='Sub Category' width='100' />
                      <ColumnDirective field='damage' headerText='Description of Damage' width='100' />
                      <ColumnDirective field='photoRecovery' headerText='Photo Attachment' width='100' template={templateRecoveryPlan}/>
                      <ColumnDirective field='estimate' headerText='Estimate to Repair' width='100'/>
                      <ColumnDirective field='donated' headerText='Donated Goods/Services' width='100'/>
                      <ColumnDirective field='funding' headerText='Other Source of Funding' width='100'/>
                      <ColumnDirective field='fundingAmount' headerText='Amount of Funding' width='100'/>
                      <ColumnDirective field='fundingGap' headerText='Funding Gap' width='100'/>
                      
                     
                      {/* <ColumnDirective
                          headerText="Actions"
                          width="120"
                          commands={[
                          {
                              type: "Edit",
                              buttonOption: {
                              content: '<i class="fas fa-edit"></i>',
                              cssClass: "e-outline custom-button",
                              },
                          },
                          {
                              type: "Delete",
                              buttonOption: {
                              content: '<i class="fas fa-trash-alt"></i>',
                              cssClass: "e-outline custom-button",
                              },
                          },
                          ]}
                      /> */}
                      </ColumnsDirective>
                      <Inject services={[Page, Toolbar, CommandColumn, Sort, Filter]} />
                  </GridComponent>
              </div>
          )}
      </section>

  </div>
</div>
};

export default RecoveryPlanDisasters;

