import React, { useState, useRef, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Edit,
  Toolbar,
  Inject,
  DetailRow,
  actionBegin,
} from "@syncfusion/ej2-react-grids";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { enableRipple } from "@syncfusion/ej2-base";
import { auth } from "../../firebase";
import { db } from "../../firebase";
import {
  doc,
  setDoc,
  collection,
  addDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import communityProfileService from "../../service/center/communityProfileService"; // Adjusted path
import { USStates } from "../datasource";
enableRipple(true);

const committeeOptions = [
  "Construction ",
  "Spiritual and Mental health",
  "Case management",
  "Donations",
];

const titleOptions = [
  { title: "Chair", value: "Chair" },
  { title: "Vice chair", value: "Vice chair" },
  { title: "Board of director", value: "Board of director" },
];

const MembersGrid = ({ data, onMembersChange }) => {
  const gridRef = useRef(null);
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Dialog",
  };

  const toolbar = ["Add", "Edit", "Delete", "Update", "Cancel"];

  const [gridData, setGridData] = useState(
    data.filter((item) => !item.is_deleted)
  );

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return `${(d.getMonth() + 1).toString().padStart(2, "0")}/${d
      .getDate()
      .toString()
      .padStart(2, "0")}/${d.getFullYear()}`;
  };

  const actionComplete = (args) => {
    if (args.requestType === "save") {
      const updatedData = Array.isArray(data) ? [...data] : [];
      const index = updatedData.findIndex((item) => item.id === args.data.id);
      if (index !== -1) {
        updatedData[index] = Object.fromEntries(
          Object.entries(args.data).map(([key, value]) => {
            if (key === "start_date" || key === "end_date") {
              return [key, formatDate(value)];
            }
            return [key, value ?? ""];
          })
        );
      } else {
        const newItem = {
          ...Object.fromEntries(
            Object.entries(args.data).map(([key, value]) => {
              if (key === "start_date" || key === "end_date") {
                return [key, formatDate(value)];
              }
              return [key, value ?? ""];
            })
          ),
          id: Math.random().toString(36).substr(2, 9),
          is_deleted: false,
        };
        updatedData.push(newItem);
      }
      setGridData(updatedData.filter((item) => !item.is_deleted));
      onMembersChange(updatedData);
    } else if (args.requestType === "delete") {
      const updatedData = Array.isArray(data)
        ? data.map((item) =>
            item.id === args.data[0].id ? { ...item, is_deleted: true } : item
          )
        : [];
      setGridData(updatedData.filter((item) => !item.is_deleted));
      onMembersChange(updatedData);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (gridRef.current) {
        const column = gridRef.current.getColumnByField("actions");
        if (column) {
          column.visible = false;
          gridRef.current.refreshColumns();
        }
      }
    } else if (args.requestType === "cancel") {
      if (gridRef.current) {
        const column = gridRef.current.getColumnByField("actions");
        if (column) {
          column.visible = true;
          gridRef.current.refreshColumns();
        }
      }
    }

    // Refresh the grid to reflect the changes
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  };

  useEffect(() => {
    const formattedData = data
      .filter((item) => !item.is_deleted)
      .map((item) => ({
        ...item,
        start_date: formatDate(item.start_date),
        end_date: formatDate(item.end_date),
      }));
    setGridData(formattedData);
  }, [data]);

  const deleteTemplate = (props) => {
    return (
      <button
        onClick={() => handleDelete(props.id)}
        className="e-btn e-flat"
        title="Delete"
        style={{ border: "none", background: "none", cursor: "pointer" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="red"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M3 6h18"></path>
          <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
          <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
          <line x1="10" y1="11" x2="10" y2="17"></line>
          <line x1="14" y1="11" x2="14" y2="17"></line>
        </svg>
      </button>
    );
  };

  const handleDelete = (id) => {
    const updatedData = Array.isArray(data)
      ? data.map((member) =>
          member.id === id ? { ...member, is_deleted: true } : member
        )
      : [];
    const filteredData = updatedData.filter((item) => !item.is_deleted);
    setGridData(filteredData);
    onMembersChange(updatedData);

    if (gridRef.current) {
      gridRef.current.refresh();
    }
  };

  const editTemplate = (args) => {
    console.log("args", args);
    switch (args.column.field) {
      case "title":
        return <TextBoxComponent id="title" value={args.value} />;
      case "volunteer":
        return (
          <SwitchComponent
            id={`volunteer-${args.rowIndex}`}
            checked={args.value}
            onLabel="Yes"
            offLabel="No"
            onChange={(e) => args.onChange(e.checked)}
            cssClass="e-switch-toggle"
          />
        );
      case "status":
        return (
          <SwitchComponent
            id={`status-${args.rowIndex}`}
            checked={args.value === "Active"}
            onLabel="Active"
            offLabel="Inactive"
            onChange={(e) => args.onChange(e.checked ? "Active" : "Inactive")}
            cssClass="e-switch-toggle"
          />
        );
      case "start_date":
      case "end_date":
        return (
          <DatePickerComponent
            id={args.column.field}
            value={args.value}
            format="MM/dd/yyyy"
            change={(e) => args.onChange(formatDate(e.value))}
          />
        );
      default:
        return null;
    }
  };

  return (
    <GridComponent
      dataSource={gridData}
      editSettings={editSettings}
      toolbar={toolbar}
      actionComplete={actionComplete}
    >
      <ColumnsDirective>
        <ColumnDirective
          field="id"
          headerText="ID"
          isPrimaryKey={true}
          width="100"
          visible={false}
        />
        <ColumnDirective field="name" headerText="Name" width="150" />
        <ColumnDirective field="email" headerText="Email" width="200" />
        <ColumnDirective field="org" headerText="Organization" width="150" />
        <ColumnDirective
          field="title"
          headerText="Title"
          width="150"
          editType="stringedit"
          edit={editTemplate}
        />
        <ColumnDirective
          field="start_date"
          headerText="Start Date"
          width="130"
          format="MM/dd/yyyy"
          type="date"
          editType="datepickeredit"
          edit={editTemplate}
        />
        <ColumnDirective
          field="end_date"
          headerText="End Date"
          width="130"
          format="MM/dd/yyyy"
          type="date"
          editType="datepickeredit"
          edit={editTemplate}
        />
        <ColumnDirective
          field="volunteer"
          headerText="Volunteer"
          width="100"
          editType="booleanedit"
          edit={editTemplate}
          allowFiltering={false}
        />
        <ColumnDirective
          field="status"
          headerText="Active"
          width="100"
          editType="booleanedit"
          edit={editTemplate}
          allowFiltering={false}
        />
        <ColumnDirective
          headerText="Actions"
          width="100"
          template={deleteTemplate}
          allowEditing={false}
        />
      </ColumnsDirective>
      <Inject services={[Page, Edit, Toolbar, DropDownListComponent]} />
    </GridComponent>
  );
};

const DetailTemplate = ({ data, onSave, userId }) => {
  const fields = [
    "committee_type",
    "committee_name",
    "start_date",
    "end_date",
    "address_1",
    "address_2",
    "city",
    "state",
    "zip",
    "first_name",
    "last_name",
    "email",
    "contact_address_1",
    "contact_address_2",
    "contact_city",
    "contact_state",
    "contact_zip",
    "contact_email",
    "contact_phone_number",
  ];
  const [localData, setLocalData] = useState({ ...data, is_deleted: false });

  useEffect(() => {
    console.log("localData", localData);
  }, [localData]);

  const handleLocalInputChange = (field, value) => {
    setLocalData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleLocalSave = async () => {
    console.log("localData", localData);
    try {
      // Format dates before saving
      const formattedData = {
        ...localData,
        start_date: formatDate(localData.start_date),
        end_date: formatDate(localData.end_date),
      };

      // Save committee details
      const committeeDetailsRef = doc(
        db,
        "committee",
        userId,
        "committee_details",
        formattedData.id
      );
      await setDoc(committeeDetailsRef, formattedData, { merge: true });

      onSave(formattedData);
    } catch (error) {
      console.error("Error saving data: ", error);
    }
  };

  const handleMembersChange = (updatedMembers) => {
    setLocalData((prevData) => ({ ...prevData, members: updatedMembers }));
  };

  const getPlaceholder = (field) => {
    return field
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return `${(d.getMonth() + 1).toString().padStart(2, "0")}/${d
      .getDate()
      .toString()
      .padStart(2, "0")}/${d.getFullYear()}`;
  };

  const renderField = (field) => {
    switch (field) {
      case "committee_type":
        return (
          <DropDownListComponent
            dataSource={committeeOptions}
            value={localData[field]}
            change={(e) => handleLocalInputChange(field, e.value)}
            cssClass="e-outline"
            placeholder={getPlaceholder(field)}
            floatLabelType="Auto"
            style={{ width: "100%" }}
          />
        );
      case "state":
        return (
          <DropDownListComponent
            dataSource={USStates}
            fields={{ text: "abbreviation", value: "abbreviation" }}
            value={localData[field]}
            change={(e) => handleLocalInputChange(field, e.value)}
            cssClass="e-outline"
            floatLabelType="Auto"
            placeholder={getPlaceholder(field)}
            style={{ width: "100%" }}
          />
        );
      case "contact_state":
        return (
          <DropDownListComponent
            dataSource={USStates}
            fields={{ text: "abbreviation", value: "abbreviation" }}
            value={localData[field]}
            change={(e) => handleLocalInputChange(field, e.value)}
            cssClass="e-outline"
            floatLabelType="Auto"
            placeholder={getPlaceholder(field)}
            style={{ width: "100%" }}
          />
        );
      case "start_date":
      case "end_date":
        return (
          <DatePickerComponent
            value={localData[field] ? new Date(localData[field]) : null}
            change={(e) => handleLocalInputChange(field, formatDate(e.value))}
            cssClass="e-outline"
            placeholder={getPlaceholder(field)}
            floatLabelType="Auto"
            format="MM/dd/yyyy"
            style={{ width: "100%" }}
          />
        );
      default:
        return (
          <TextBoxComponent
            value={localData[field]}
            change={(e) => handleLocalInputChange(field, e.value)}
            cssClass="e-outline"
            placeholder={getPlaceholder(field)}
            floatLabelType="Auto"
            style={{ width: "100%" }}
          />
        );
    }
  };

  return (
    <div
      className="detail-template-container"
      style={{
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <h2 style={{ marginBottom: "20px" }}>Committee</h2>
      <div
        className="detail-form"
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          {renderField("committee_type")}
          {renderField("committee_name")}
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          {renderField("start_date")}
          {renderField("end_date")}
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {renderField("address_1")}
          {renderField("address_2")}
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          {renderField("city")}
          {renderField("state")}
          {renderField("zip")}
        </div>
        <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
          Contact Person Information
        </h3>
        <div style={{ display: "flex", gap: "20px" }}>
          {renderField("first_name")}
          {renderField("last_name")}
        </div>
        {renderField("contact_address_1")}
        {renderField("contact_address_2")}
        <div style={{ display: "flex", gap: "20px" }}>
          {renderField("contact_city")}
          {renderField("contact_state")}
          {renderField("contact_zip")}
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          {renderField("contact_email")}
          {renderField("contact_phone_number")}
        </div>
      </div>
      <div
        className="members-grid"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <h3>Committee Members</h3>
        <MembersGrid
          data={localData.members || []}
          onMembersChange={handleMembersChange}
        />
      </div>
      <div className="detail-actions" style={{ textAlign: "right" }}>
        <button
          onClick={handleLocalSave}
          className="e-btn e-primary"
          style={{ marginRight: "10px" }}
        >
          Save
        </button>
        <button onClick={() => onSave(data)} className="e-btn">
          Cancel
        </button>
      </div>
    </div>
  );
};

const Members = () => {
  const gridRef = useRef(null);
  const [membersData, setMembersData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [isAddingNewRecord, setIsAddingNewRecord] = useState(false);
  const [data, setData] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserId = () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (userId) {
        try {
          const committeeDetailsRef = collection(
            db,
            "committee",
            userId,
            "committee_details"
          );
          const committeeDetailsSnapshot = await getDocs(committeeDetailsRef);
          const committeeData = committeeDetailsSnapshot.docs
            .map((doc) => {
              const data = doc.data();
              return {
                id: doc.id,
                ...data,
                start_date: formatDate(data.start_date),
                end_date: formatDate(data.end_date),
              };
            })
            .filter((item) => !item.is_deleted);

          setMembersData(committeeData);
          console.log("committeeData", committeeData);
        } catch (error) {
          console.error("Error fetching committee data: ", error);
        }
      }
    };
    fetchData();
  }, [userId]);

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return `${(d.getMonth() + 1).toString().padStart(2, "0")}/${d
      .getDate()
      .toString()
      .padStart(2, "0")}/${d.getFullYear()}`;
  };

  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Normal",
  };

  const actionBegin = (args) => {
    if (args.requestType === "add") {
      args.cancel = true;
      handleAddRecord();
    }
  };

  const handleAddRecord = () => {
    const newRecord = {
      id: Math.random().toString(36).substr(2, 9),
      committee_type: "",
      committee_name: "",
      first_name: "",
      last_name: "",
      start_date: null,
      end_date: null,
      status: "Inactive",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zip: "",
      contact_address_1: "",
      contact_address_2: "",
      contact_city: "",
      contact_state: "",
      contact_zip: "",
      contact_email: "",
      contact_phone_number: "",
      is_deleted: false,
      volunteer: false,
      members: [],
    };
    setMembersData([...membersData, newRecord]);
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  };

  const handleSave = async (updatedData) => {
    console.log("Data", updatedData);
    const updatedMembersData = membersData.map((member) =>
      member.id === updatedData.id ? { ...member, ...updatedData } : member
    );
    setMembersData(updatedMembersData);
    setIsAddingNewRecord(false);
    setCurrentData(null);
    if (gridRef.current) {
      gridRef.current.refresh();
    }

    // Update Firestore
    try {
      const committeeRef = doc(
        db,
        "committee",
        userId,
        "committee_details",
        updatedData.id
      );
      await updateDoc(committeeRef, updatedData);
    } catch (error) {
      console.error("Error updating Firestore: ", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      // Update local state
      const updatedMembersData = membersData.map((member) =>
        member.id === id ? { ...member, is_deleted: true } : member
      );
      setMembersData(updatedMembersData);

      // Update Firestore
      const committeeRef = doc(
        db,
        "committee",
        userId,
        "committee_details",
        id
      );
      await updateDoc(committeeRef, { is_deleted: true });

      // Refresh the grid
      if (gridRef.current) {
        gridRef.current.refresh();
      }
    } catch (error) {
      console.error("Error deleting committee: ", error);
    }
  };

  const deleteTemplate = (props) => {
    return (
      <button
        onClick={() => handleDelete(props.id)}
        className="e-btn e-flat"
        title="Delete"
        style={{ border: "none", background: "none", cursor: "pointer" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="red"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M3 6h18"></path>
          <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
          <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
          <line x1="10" y1="11" x2="10" y2="17"></line>
          <line x1="14" y1="11" x2="14" y2="17"></line>
        </svg>
      </button>
    );
  };

  const detailTemplate = (props) => {
    return <DetailTemplate data={props} onSave={handleSave} userId={userId} />;
  };

  const handleActionComplete = (args) => {
    if (args.requestType === "detailExpand") {
      setCurrentData({ ...args.data });
    } else if (args.requestType === "save") {
      handleSave(args.data);
    }
  };

  const handleRowDoubleClick = (args) => {
    args.cancel = true;
    const rowData = args.data;
    if (gridRef.current && gridRef.current.detailRowModule) {
      if (gridRef.current.getExpandedRows().indexOf(rowData.id) > -1) {
        gridRef.current.detailRowModule.collapse(rowData.id);
      } else {
        gridRef.current.detailRowModule.expand(rowData.id);
      }
    }
  };

  useEffect(() => {
    if (isAddingNewRecord && gridRef.current) {
      const gridInstance = gridRef.current;
      if (gridInstance && gridInstance.detailRowModule) {
        gridInstance.detailRowModule.expand(
          membersData[membersData.length - 1].id
        );
      }
    }
  }, [isAddingNewRecord, membersData]);

  return (
    <div>
      <h1>Committees</h1>
      <GridComponent
        dataSource={membersData.filter((item) => !item.is_deleted)}
        ref={gridRef}
        allowPaging={true}
        toolbar={["Add", "Edit", "Delete", "Update", "Cancel"]}
        editSettings={editSettings}
        actionBegin={actionBegin}
        actionComplete={handleActionComplete}
        rowDoubleClick={handleRowDoubleClick}
        detailTemplate={detailTemplate}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            headerText="ID"
            isPrimaryKey={true}
            width="100"
            visible={false}
          />
          <ColumnDirective
            field="committee_type"
            headerText="Committee Type"
            width="200"
            editType="dropdownedit"
            edit={{
              params: {
                dataSource: committeeOptions,
                fields: { text: "committee", value: "committee" },
              },
            }}
          />
          <ColumnDirective
            field="committee_name"
            headerText="Committee Name"
            width="150"
          />
          <ColumnDirective
            field="first_name"
            headerText="Contact Person First Name"
            width="150"
          />
          <ColumnDirective
            field="last_name"
            headerText="Contact Person Last Name"
            width="150"
          />
          <ColumnDirective
            headerText="Actions"
            width="100"
            template={deleteTemplate}
            allowEditing={false}
          />
        </ColumnsDirective>
        <Inject services={[Page, Edit, Toolbar, DetailRow]} />
      </GridComponent>
    </div>
  );
};

export default Members;
