import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

const generateUniqueID = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

const partnerProfileService = {
  getPartnerProfile: async (collectionName, docId) => {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error reading document: ", error);
      throw error;
    }
  },

  updatePartnerProfile: async (collectionName, docId, data) => {
    try {
      const docRef = doc(db, collectionName, docId);
      const updatedData = { ...data, updated_at: new Date() };
      await updateDoc(docRef, updatedData);
      console.log("Document successfully updated!");
      return { id: docId, ...updatedData };
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  // Get all  members for a citizen
  getMembers: async (partnerId) => {
    try {
      const membersRef = collection(db, "users", partnerId, "members");
      const snapshot = await getDocs(membersRef);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error getting members: ", error);
      throw error;
    }
  },

  // Add a new member
  addMember: async (userId, memberData) => {
    try {
      console.log("Adding member:", memberData); // Log the data being passed
      const docRef = await addDoc(
        collection(db, "users", userId, "members"),
        memberData
      );
      console.log("Document written with ID: ", docRef.id);
      return { ...memberData, id: docRef.id };
    } catch (error) {
      console.error("Error adding member: ", error);
      throw error;
    }
  },

  // Update a member
  updateMember: async (partnerId, memberId, data) => {
    if (!partnerId || !memberId || !data) {
      throw new Error("Invalid arguments passed to updateMember");
    }
  
    try {
      const memberRef = doc(db, "users", partnerId, "members", memberId);
      await setDoc(memberRef, data, { merge: true });
      console.log("Member updated successfully");
      return { id: memberId, ...data };
    } catch (error) {
      console.error("Error updating member: ", error);
      throw error;
    }
  },
  

  // Delete a member
  deleteMember: async (userId, memberId) => {
    try {
      console.log("Deleting member:", userId, memberId);
      const memberRef = doc(db, "users", userId, "members", memberId);
      console.log("Document reference:", memberRef.path);
      await deleteDoc(memberRef);
      console.log("Member deleted successfully");
    } catch (error) {
      console.error("Error deleting member:", error);
      throw error;
    }
  },

  // Get all  disasters for a citizen
  getDisasters: async (partnerId) => {
    try {
      const disastersRef = collection(db, "users", partnerId, "disasters");
      const snapshot = await getDocs(disastersRef);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error getting members: ", error);
      throw error;
    }
  },

  // Add a new member
  addDisasters: async (userId, disasterData) => {
    try {
      console.log("Adding disaster:", disasterData); // Log the data being passed
      const docRef = await addDoc(
        collection(db, "users", userId, "disasters"),
        disasterData
      );
      console.log("Document written with ID: ", docRef.id);
      return { ...disasterData, id: docRef.id };
    } catch (error) {
      console.error("Error adding disaster: ", error);
      throw error;
    }
  },

  // Update a disaster
  updateDisasters: async (partnerId, disasterId, data) => {
    try {
      // Reference to the specific disaster document
      const disasterRef = doc(db, "users", partnerId, "disasters", disasterId);

      // Reference to the partner document where the lastUpdated timestamp will be set
      const partnerRef = doc(db, "users", partnerId);

      // Update the disaster document with the provided data
      await setDoc(disasterRef, data, { merge: true });

      // Update the partner document with the lastUpdated timestamp
      await setDoc(
        partnerRef,
        { lastUpdatedDisasters: serverTimestamp() },
        { merge: true }
      );

      console.log("Disaster and lastUpdated timestamp updated successfully");
      return { id: disasterId, ...data };
    } catch (error) {
      console.error("Error updating disaster: ", error);
      throw error;
    }
  },

  deleteDisaster: async (userId, disasterId) => {
    try {
      console.log("Deleting member:", userId, disasterId);
      const disasterRef = doc(db, "users", userId, "disasters", disasterId);
      console.log("Document reference:", disasterRef.path);
      await deleteDoc(disasterRef);
      console.log("Member deleted successfully");
    } catch (error) {
      console.error("Error deleting member:", error);
      throw error;
    }
  },
};
export default partnerProfileService;
