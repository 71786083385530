import React, { useState, useRef, useEffect } from "react";
import LockImage from "../assets/lockbox.png";

import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  GridComponent,
  ColumnDirective,
  CommandColumn,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import LockBoxService from "../service/citizen/LockboxService";
import { db, auth } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { Query } from "@syncfusion/ej2-data";
import { DialogComponent } from '@syncfusion/ej2-react-popups';

const sortSettings = {
  allowSorting: true,
};

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  mode: "Dialog",

  dialog: { cssClass: "custom-dialog" },
};

const filterSettings = {
  type: "Menu",
};

const datepickerparams = {
  params: {
    format: "MM/dd/yyyy",
    showClearButton: false,
    showTodayButton: false,
  },
};

const documentTypes = [
  { idName: "ID", id: "1" },
  { idName: "Credit Cards/Banking Information", id: "2" },
  { idName: "Insurance Information", id: "3" },
  { idName: "Photos", id: "4" },
  { idName: "Titles/Deed/Registration", id: "5" },
];

const typeParams = {
  params: {
    allowFiltering: true,
    dataSource: documentTypes,
    fields: { text: "idName", value: "idName" },
    query: new Query(),
  },
};

const Lockbox = ({ className = "", onNavigateBack }) => {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState("");
  const [correctPIN, setCorrectPIN] = useState("1111");
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [PhotosData, setPhotosData] = useState([]);
  const [isDocumentsVisible, setIsDocumentsVisible] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
        // Fetch the user's PIN
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().pin) {
          console.log("PIN:", userDoc.data().pin);
          setCorrectPIN(userDoc.data().pin);
        }
        // If no PIN is set, it will use the default '1111'
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });
        console.log(data);

        const documents = await LockBoxService.fetchDocumentsFromLockbox(
          userId,
          "documents_data"
        );
    
        const activeDocuments = documents.filter(doc => !doc.is_deleted);
    
        setDocumentsData(activeDocuments);
        console.log("Active documents:", activeDocuments);

        const photos = await LockBoxService.fetchDocumentsFromLockbox(
          userId,
          "photos_data"
        );
    
        // Filter out photos with status set to false
        const activePhotos = photos.filter(photo => !photo.is_deleted);
    
        setPhotosData(activePhotos);

      
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleUnlock = () => {
    if (enteredPIN === correctPIN) {
      setIsUnlocked(true);
    } else {
      setWrongPinMessage(true);
      setEnteredPIN("");
      setInputValues(["", "", "", ""]);
      inputRefs.current[0].focus();

      setTimeout(() => {
        setWrongPinMessage(false);
      }, 3000);
    }
  };

  const onInput = (index, event) => {
    const value = event.target.value;
    if (/^\d$/.test(value)) {
      const newValues = [...inputValues];
      newValues[index] = value;
      setInputValues(newValues);
      setEnteredPIN(newValues.join(""));

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const onKeyDown = (index, event) => {
    if (event.key === "Backspace" && !inputValues[index]) {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  const handleGoBack = () => {
    // Call the onNavigateBack function passed as a prop
    if (onNavigateBack) {
      onNavigateBack();
    }
  };

  let elem;
  let uploadObj;
  let strm;
  let selectedRow;
  const accessOptions = [
    { value: "Local Recovery Center", text: "Local Recovery Center" },
    { value: "Recovery Center Partners", text: "Recovery Center Partners" },
    { value: "Local Emergency Management", text: "Local Emergency Management" },
  ];
  const fields = { value: "value", text: "text" };

  const [isPinCorrect, setIsPinCorrect] = useState(false);
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [wrongPinMessage, setWrongPinMessage] = useState(false);
  const inputRefs = useRef([]);

  const correctPin = "1111"; // Set your correct PIN here

  const checkPin = (newValues) => {
    const enteredPin = newValues.join("");
    if (enteredPin === correctPIN) {
      setIsPinCorrect(true);
      setWrongPinMessage(false);
    } else if (enteredPin.length === 4) {
      setWrongPinMessage(true);
      // Clear input values
      setInputValues(["", "", "", ""]);
      // Focus on the first input
      inputRefs.current[0].focus();
      // Set a timeout to clear the wrong PIN message
      setTimeout(() => {
        setWrongPinMessage(false);
      }, 3000); // Message will disappear after 3 seconds
    } else {
      setWrongPinMessage(false);
    }
  };

  {
    /* Documents */
  }
  const [documentAccessData, setDocumentAccessData] = useState({ access: [] });

  const accessEditTemplateDocuments = (props) => {
    const currentAccess = (props && props.access) || [];

    return (
      <div>
        <label htmlFor="access" className="mb-2 block text-xs text-gray-500">
          People with Access
        </label>
        <MultiSelectComponent
          id="access"
          dataSource={accessOptions}
          fields={fields}
          value={currentAccess}
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  const toggleDocuments = (event) => {
    event.preventDefault();
    setIsDocumentsVisible(!isDocumentsVisible);
  };

  const PhotosGridRef = useRef(null);

  const handleAddClickdocuments = () => {
    if (documentGridRef.current) {
      documentGridRef.current.addRecord();
    }
  };

  function compressImage(file, maxSizeMB) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxWidth = img.width;
          const maxHeight = img.height;
          let width = img.width;
          let height = img.height;

          // Resize the image maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.floor((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.floor((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.9; // Start with high quality
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          // Compress the image by reducing quality until it's below maxSizeMB
          while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
            quality -= 0.1;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          resolve(dataUrl);
        };

        img.onerror = function (error) {
          reject(error);
        };
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  // Upload image to Firebase Storage
  async function uploadImageToStorage(filePath, dataUrl) {
    const storage = getStorage();
    const storageRef = ref(storage, filePath);

    // Upload the base64 string directly
    await uploadString(storageRef, dataUrl, "data_url");

    // Get the download URL
    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  }

  const documentsTemplate = (props) => {
    const src = props.photodocuments; // Get the image path from the data source

    return (
      <div className="image">
        {src ? (
          <img
            src={src}
            alt={props.documentsID}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <div
            className="flex flex-col items-center justify-center"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              border: "1px solid #ddd",
            }}
          >
            <p>No Image</p>
          </div>
        )}
      </div>
    );
  };

  const documentsEdit = {
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
        <div class="flex flex-col justify-center w-full">
          <label htmlFor="access" class="block mb-2 text-xs text-gray-500">Image</label>
          <div class="flex flex-col items-center">
            <div id="imagePreview" class="mb-4 flex flex-col items-center justify-center">
              <!-- Image will be dynamically inserted here -->
            </div>
            <div id="dropArea">
              <label class="upload-button" id="uploadLabel">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="17 8 12 3 7 8"></polyline>
                  <line x1="12" y1="3" x2="12" y2="15"></line>
                </svg>
                Upload Image
              </label>
              <input type="file" id="defaultUpload" style="display:none;" />
            </div>
          </div>
        </div>
      `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagePreview = elem.querySelector("#imagePreview");

      // If there's an existing image, update the button text to "Reupload Image"
      if (args.rowData.photodocuments) {
        uploadLabel.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="17 8 12 3 7 8"></polyline>
          <line x1="12" y1="3" x2="12" y2="15"></line>
        </svg>
        Reupload Image
      `;
        strm = args.rowData.photodocuments;
        displayUploadedImage(args.rowData.photodocuments, "Current Image");
      } else {
        imagePreview.innerHTML = "";
      }

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        multiple: false,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);

            const filePath = `lockbox/documents/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );
            strm = downloadURL;
            console.log("Image uploaded successfully:", downloadURL);

            displayUploadedImage(downloadURL, args.file.name);
          } catch (error) {
            console.error("Error during upload:", error);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function displayUploadedImage(imageSrc, fileName) {
        imagePreview.innerHTML = `
          <img src="${imageSrc}" alt="Uploaded Image" style="width: 100px; height: 100px; border-radius: 50%; object-fit: cover;" />
          <p class="text-sm text-blue-500 mt-2">${fileName}</p>
        `;
      }
    },
  };

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] !== undefined) {
        sanitizedData[key] = data[key];
      }
    }
    return sanitizedData;
  };

  const [deleteDialogProps, setDeleteDialogProps] = useState({
    isOpen: false,
    title: '',
    content: '',
    onConfirm: null
  });

  const showDeleteConfirmDialog = (title, content, onConfirm) => {
    setDeleteDialogProps({
      isOpen: true,
      title,
      content,
      onConfirm
    });
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogProps(prev => ({ ...prev, isOpen: false }));
  };

  const handleDeleteConfirm = () => {
    if (deleteDialogProps.onConfirm) {
      deleteDialogProps.onConfirm();
    }
    handleDeleteDialogClose();
  };

  const actionBeginDocuments = async (args) => {
    if (args.requestType === "save" && args.action === "add") {
      args.data.documentsID = generateUniqueId();
      args.data.is_deleted = false;

      if (args.data.date) {
        const dateObj = new Date(args.data.date);
        args.data.date = dateObj.toLocaleDateString("en-US");
      }

      const sanitizedData = sanitizeData(args.data);
      await LockBoxService.addDocumentToLockbox(
        userId,
        sanitizedData,
        "documents_data"
      );
      console.log("Add:", args.data);
    }

    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
      console.log("edit: ", args.rowData);
    }

    if (args.requestType === "delete") {
      args.cancel = true;
      showDeleteConfirmDialog(
        'Delete Confirmation',
        'Are you sure you want to delete this document?',
        () => deleteDocument(args.data)
      );
      await fetchData();
    }
  };

  const deleteDocument = async (data) => {
    try {
      for (const docData of data) {
        const documentId = docData.documentsID;
        const fileUrl = docData.photodocuments;

        if (fileUrl) {
          const storage = getStorage();
          const fileRef = ref(storage, fileUrl);

          await deleteObject(fileRef);
          console.log("File deleted from storage:", fileUrl);
        }

        console.log("Attempting to delete document with ID:", documentId);

        const updatedData = { ...docData, is_deleted: true };
      
        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          updatedData,
          "documents_data"
        );
        console.log("Document deleted:", documentId);
      }
      // Refresh the grid data after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const actionCompleteDocuments = async (args) => {
    if (args.requestType === "save" && args.action === "edit") {
      try {
        console.log("Edit saved ", selectedRow);

        const documentId = selectedRow.documentsID;

        if (args.data.date) {
          const dateObj = new Date(args.data.date);
          args.data.date = dateObj.toLocaleDateString("en-US");
        }

        const sanitizedData = sanitizeData(args.data);

        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          sanitizedData,
          "documents_data"
        );

        const updatedData = documentsData.map((item) =>
          item.documentsID === documentId ? sanitizedData : item
        );

        setDocumentsData(updatedData);
        console.log("Data saved:", updatedData);
      } catch (error) {
        console.error("Error updating document:", error);
      }
    }
  };

  const handleAddClickDocument = () => {
    if (documentGridRef.current) {
      documentGridRef.current.addRecord();
    }
  };

  const accessEditTemplatePhotos = (props) => {
    const currentAccess = (props && props.access) || [];

    return (
      <div>
        <label htmlFor="access" className="mb-2 block text-xs text-gray-500">
          People with Access
        </label>
        <MultiSelectComponent
          id="access"
          dataSource={accessOptions}
          fields={fields}
          value={currentAccess}
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  const [isPhotosVisible, setIsPhotosVisible] = useState(true);

  const togglePhotos = (event) => {
    event.preventDefault();
    setIsPhotosVisible(!isPhotosVisible);
  };

  const documentGridRef = useRef(null);

  const handleAddClickPhotos = () => {
    if (PhotosGridRef.current) {
      PhotosGridRef.current.addRecord();
    }
  };

  const PhotosTemplate = (props) => {
    const src = props.photoPhotos; // Get the image path from the data source

    return (
      <div className="image">
        {src ? (
          <img
            src={src}
            alt={props.PhotosID}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <div
            className="flex flex-col items-center justify-center"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              border: "1px solid #ddd",
            }}
          >
            <p>No Image</p>
          </div>
        )}
      </div>
    );
  };

  const PhotosEdit = {
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      console.log("desrtuoed");
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
          <div class="flex flex-col justify-center w-full">
            <label htmlFor="access" class="block mb-2 text-xs text-gray-500">Title</label>
            <div class="flex flex-col items-center">
              <div id="imagePreview" class="mb-4 flex flex-col items-center justify-center">
                <!-- Image will be dynamically inserted here -->
              </div>
              <div id="dropArea">
                <label class="upload-button" id="uploadLabel">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" y1="3" x2="12" y2="15"></line>
                  </svg>
                  Upload Image
                </label>
                <input type="file" id="defaultUpload" style="display:none;" />
              </div>
            </div>
          </div>
        `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagePreview = elem.querySelector("#imagePreview");

      // If there's an existing image, update the button text to "Reupload Image"
      if (args.rowData.photoPhotos) {
        uploadLabel.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="17 8 12 3 7 8"></polyline>
          <line x1="12" y1="3" x2="12" y2="15"></line>
        </svg>
        Reupload Image
      `;
        strm = args.rowData.photoPhotos;
        displayUploadedImage(args.rowData.photodocuments, "Current Image");
      } else {
        imagePreview.innerHTML = "";
      }

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        multiple: false,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);
  
            const filePath = `lockbox/photos/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );
            strm = downloadURL;
            console.log("Image uploaded successfully:", downloadURL);
  
            displayUploadedImage(downloadURL, args.file.name);
          } catch (error) {
            console.error("Error during upload:", error);
            strm = null; // Set to null if upload fails
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function displayUploadedImage(imageSrc, fileName) {
        imagePreview.innerHTML = `
            <img src="${imageSrc}" alt="Uploaded Image" style="width: 100px; height: 100px; border-radius: 50%; object-fit: cover;" />
            <p class="text-sm text-blue-500 mt-2">${fileName}</p>
          `;
      }

      // Display the current image if available
      if (args.rowData.photoPhotos) {
        strm = args.rowData.photoPhotos;
        displayUploadedImage(args.rowData.photoPhotos, "Current Image");
      } else {
        imagePreview.innerHTML = "";
      }
    },
  };

  let grid;
  const actionBeginPhotos = async (args) => {
    if (PhotosGridRef.current && args.requestType === "beginEdit") {
      const cols = PhotosGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "modifiedDate") {
          col.visible = false; // Hide modifiedDate when editing
        }
      }
    }

    if (PhotosGridRef.current && args.requestType === "add") {
      const cols = PhotosGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "modifiedDate") {
          col.visible = false; // Hide modifiedDate when adding
        }
      }
    }

    if (PhotosGridRef.current && args.requestType === "save") {
      const cols = PhotosGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "modifiedDate") {
          col.visible = true; // Show modifiedDate after saving
        }
      }
    }

    if (args.requestType === "save" && args.action === "add") {
      args.data.PhotosID = generateUniqueId();
      args.data.is_deleted = false;

      // Format the modifiedDate field to MM/DD/YYYY
      const dateObj = new Date();
      args.data.modifiedDate = dateObj.toLocaleDateString("en-US");

      const sanitizedData = sanitizeData(args.data);

      try {
        await LockBoxService.addDocumentToLockbox(
          userId,
          sanitizedData,
          "photos_data"
        );
        console.log("Photo added successfully:", args.data);
      } catch (error) {
        console.error("Error adding photo:", error);
      }
    }

    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      args.cancel = true;
      showDeleteConfirmDialog(
        'Delete Confirmation',
        'Are you sure you want to delete this photo?',
        () => deletePhoto(args.data)
      );
      await fetchData();
    }
  };

  const deletePhoto = async (data) => {
    try {
      for (const photoData of data) {
        const photoId = photoData.PhotosID;
        const fileUrl = photoData.photoPhotos;
  
        if (fileUrl) {
          const storage = getStorage();
          const fileRef = ref(storage, fileUrl);
  
          await deleteObject(fileRef);
          console.log("File deleted from storage:", fileUrl);
        }
  
        console.log("Attempting to delete photo with ID:", photoId);
  
        const updatedData = { ...photoData, is_deleted: true };
      
        await LockBoxService.updateDocumentInLockbox(
          userId,
          photoId,
          updatedData,
          "photos_data"
        );
      console.log("Photo status set to false:", photoId);
      }
      // Refresh the grid data after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  const actionCompletePhotos = async (args) => {
    if (args.requestType === "save" && args.action === "edit") {
      try {
        const documentId = selectedRow.PhotosID;
  
        if (args.data.modifiedDate) {
          const dateObj = new Date(args.data.modifiedDate);
          args.data.modifiedDate = dateObj.toLocaleDateString("en-US");
        }
  
        const sanitizedData = sanitizeData(args.data);
  
        // If photoPhotos is undefined, remove it from the update
        if (sanitizedData.photoPhotos === undefined) {
          delete sanitizedData.photoPhotos;
        }
  
        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          sanitizedData,
          "photos_data"
        );
  
        const updatedData = PhotosData.map((item) =>
          item.PhotosID === documentId ? { ...item, ...sanitizedData } : item
        );
  
        setPhotosData(updatedData);
        console.log("Photo updated successfully:", updatedData);
      } catch (error) {
        console.error("Error updating photo:", error);
      }
    }
  };

  if (!isUnlocked) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
          <img
            src={LockImage}
            alt="Lockbox"
            className="mb-6 h-[290px] w-[290px]"
          />
          <div className="mb-4 flex space-x-8">
            {inputValues.map((value, index) => (
              <input
                key={index}
                type="password"
                maxLength="1"
                className="lockbox-input text-2xl h-12 w-12 rounded-xl border border-gray-300 bg-gray-300 text-center"
                pattern="[0-9]"
                value={value}
                onChange={(event) => onInput(index, event)}
                onKeyDown={(event) => onKeyDown(index, event)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          {wrongPinMessage && (
            <div className="mb-4 text-sm text-red-600">
              Wrong PIN entered, please try again.
            </div>
          )}
          <div className="flex w-full space-x-4">
            <button
              onClick={handleGoBack}
              className="flex flex-1 cursor-pointer items-center justify-center rounded-lg border border-blue-500 bg-white px-4 py-2 text-blue-500"
            >
              <span className="mr-2">←</span> Go Back
            </button>
            <button
              onClick={handleUnlock}
              className="flex flex-1 cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-4 py-2 text-white"
            >
              <svg
                width="18"
                height="21"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <g id="Shield Done">
                  <path
                    id="Fill 1"
                    opacity="0.4"
                    d="M9.08649 20.5C8.96266 20.5 8.83884 20.4716 8.72709 20.4137L5.12599 18.5496C4.10415 18.0201 3.30481 17.4259 2.68063 16.7336C1.31449 15.2195 0.554402 13.276 0.542321 11.2599L0.500038 4.62426C0.495004 3.85842 0.989312 3.17103 1.72826 2.91215L8.3405 0.606788C8.73313 0.466564 9.17106 0.464603 9.57073 0.599924L16.2081 2.82684C16.9511 3.07493 17.4535 3.75742 17.4575 4.52228L17.4998 11.1628C17.5129 13.176 16.779 15.1274 15.434 16.6581C14.8168 17.3602 14.0245 17.9632 13.0128 18.5025L9.44388 20.4088C9.33314 20.4686 9.21032 20.499 9.08649 20.5Z"
                    fill="white"
                  />
                  <path
                    id="Fill 4"
                    d="M8.31936 12.8209C8.12607 12.8219 7.93278 12.7523 7.78378 12.6091L5.86695 10.7656C5.57097 10.4793 5.56795 10.0145 5.86091 9.72617C6.15387 9.4369 6.63207 9.43396 6.92906 9.71931L8.30829 11.0451L11.6758 7.72479C11.9698 7.43552 12.448 7.43258 12.744 7.71793C13.041 8.00426 13.044 8.47004 12.751 8.75735L8.85193 12.6022C8.70494 12.7474 8.51266 12.8199 8.31936 12.8209Z"
                    fill="white"
                  />
                </g>
              </svg>
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start py-[15px] ${className}`}
    >
      <div className="w-full">
      <DialogComponent
        header={deleteDialogProps.title}
        content={deleteDialogProps.content}
        visible={deleteDialogProps.isOpen}
        showCloseIcon={true}
        closeOnEscape={true}
        width="300px"
        buttons={[
          { click: handleDeleteConfirm, buttonModel: { content: 'Yes', isPrimary: true } },
          { click: handleDeleteDialogClose, buttonModel: { content: 'No' } }
        ]}
        close={handleDeleteDialogClose}
        cssClass="delete-confirmation-dialog"
      />
        <section className="box-border flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch px-0 pb-[5px] pt-0 text-left font-raleway text-xs text-dodgerblue">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                DOCUMENTS
              </h2>
              <div className="relative font-poppins font-medium leading-[21px] text-darkslategray-200">
                The following documents may be needed after a disaster to prove
                your identity, home ownership, rights to residency, and/or
                verify eligibility for various types of recovery assistance.
              </div>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickDocument}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleDocuments}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isDocumentsVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>

          {isDocumentsVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
  key={documentsData.length}
  ref={documentGridRef}
  dataSource={documentsData}
  allowSorting={true}
  sortSettings={sortSettings}
  editSettings={editing}
  filterSettings={filterSettings}
  allowFiltering={true}
  actionBegin={actionBeginDocuments}
  actionComplete={actionCompleteDocuments}
>
                <ColumnsDirective>
                  <ColumnDirective
                    field="documentsID"
                    headerText="documentsID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="type"
                    headerText="Type"
                    width="100"
                    editType="dropdownedit"
                    edit={typeParams}
                  />
                  <ColumnDirective
                    field="description"
                    headerText="Description"
                    width="100"
                  />
                  <ColumnDirective
                    field="date"
                    headerText="Date"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                  />
                  <ColumnDirective
                    field="photodocuments"
                    headerText="Image"
                    width="100"
                    template={documentsTemplate}
                    edit={documentsEdit}
                  />
                  <ColumnDirective
                      field="access"
                      headerText="People with Access"
                      width="100"
                      editTemplate={accessEditTemplateDocuments}
                      template={(props) => {
                        return props.access && props.access.length > 0 ? props.access.join(', ') : 'None';
                      }}
                    />
                    
                  <ColumnDirective
                    headerText="Actions"
                    width="120"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      {
                        type: "Delete",
                        buttonOption: {
                          content: '<i class="fas fa-trash-alt"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject services={[Page, Edit, Toolbar, Sort, Filter]} />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch text-left font-raleway text-xs text-dodgerblue">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                PHOTOS
              </h2>
              <div className="relative font-poppins font-medium leading-[21px] text-darkslategray-200">
                Uploading pre-disaster and after disaster photos of your home
                and goods aid in determining loss and replacement value.
              </div>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickPhotos}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={togglePhotos}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isPhotosVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>

          {isPhotosVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
  ref={PhotosGridRef}
  dataSource={PhotosData}
  allowSorting={true}
  sortSettings={sortSettings}
  editSettings={editing}
  filterSettings={filterSettings}
  allowFiltering={true}
  actionComplete={actionCompletePhotos}
  actionBegin={actionBeginPhotos}
>
  <ColumnsDirective>
    <ColumnDirective
      field="PhotosID"
      headerText="PhotosID"
      isPrimaryKey={true}
      visible={false}
    />
    
                  <ColumnDirective
                    field="photoPhotos"
                    headerText="Image"
                    width="100"
                    template={PhotosTemplate}
                    edit={PhotosEdit}
                  />

                  <ColumnDirective
                    field="modifiedDate"
                    headerText="Modified"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    visible={true}
                  />
                  <ColumnDirective
                    field="access"
                    headerText="People with Access"
                    width="100"
                    editTemplate={accessEditTemplatePhotos}
                    template={(props) => {
                      return props.access && props.access.length > 0 ? props.access.join(', ') : 'None';
                    }}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    width="120"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      {
                        type: "Delete",
                        buttonOption: {
                          content: '<i class="fas fa-trash-alt"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject services={[Page, Edit, Toolbar, Sort, Filter]} />
              </GridComponent>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default Lockbox;
