import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as CentersIcon } from "../icons/Maps/centers.svg";
import { ReactComponent as PartnershipIcon } from "../icons/Maps/partnership.svg";
import { ReactComponent as FireIcon } from "../icons/Maps/fire.svg";
import { ReactComponent as FloodIcon } from "../icons/Maps/flood.svg";
import { ReactComponent as EarthquakeIcon } from "../icons/Maps/Earthquake.svg";
import { ReactComponent as HurricaneIcon } from "../icons/Maps/hurricane.svg";
import { ReactComponent as TornadoIcon } from "../icons/Maps/tornado.svg";
import {
  MapsComponent,
  LayersDirective,
  NavigationLineDirective,
  LayerDirective,
  Zoom,
  MarkersDirective,
  NavigationLine,
  NavigationLinesDirective,
  MarkerDirective,
  Marker,
  Inject,
  Maps,
  MapsTooltip,
} from "@syncfusion/ej2-react-maps";
import getCoordinates from "../service/geocoding/Geocoding";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import citizenProfileService from "../service/citizen/CitizenProfile";
import { DialogComponent } from "@syncfusion/ej2-react-popups";

const Map = (className = "") => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      userId
    );
    if (citizenData) {
      setData(citizenData);
      console.log("Citizen data: ", citizenData);
      console.log(
        "Coordinates: ",
        citizenData.address1 +
          " " +
          citizenData.city +
          " " +
          citizenData.state +
          " " +
          citizenData.zip
      );

      if (!citizenData.latitude && !citizenData.longitude) {
        console.log("No lat and long");
        try {
          const coords = await getCoordinates(
            citizenData.address1,
            citizenData.city,
            citizenData.state,
            citizenData.zip
          );
          setCoordinates(coords);
          console.log(
            "Coordinates: ",
            citizenData.address1 +
              " " +
              citizenData.city +
              " " +
              citizenData.state +
              " " +
              citizenData.zip
          );

          await citizenProfileService.updateCitizenProfile("users", userId, {
            latitude: coords.lat,
            longitude: coords.lon,
          });
        } catch (err) {
          setShowPopup(true);
          // alert("No latitude and longitude found for this address");
        }
      } else {
        console.log("Existing lat and long");
        setCoordinates({
          lat: citizenData.latitude,
          lon: citizenData.longitude,
        });
      }
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        {/* You can replace this with your own icon or image */}
        <div style={{ fontSize: "48px", marginBottom: "20px" }}>🚀</div>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "normal",
            color: "#1e1919",
            marginBottom: "10px",
          }}
        >
          No coordinates found for this address
        </h2>
        <p style={{ fontSize: "16px", color: "#637381", marginBottom: "20px" }}>
          Update your address and retry.
        </p>
        <button
          onClick={handleClosePopup}
          style={{
            backgroundColor: "#0061fe",
            color: "white",
            border: "none",
            padding: "10px 20px",
            fontSize: "14px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Got it!
        </button>
      </div>
    );
  };

  return (
    <div className="relative h-[calc(100vh-64px)] w-full">
      <MapsComponent
        height="95%"
        width="99%"
        centerPosition={{
          latitude: parseFloat(coordinates?.lat ?? 34.06062),
          longitude: parseFloat(coordinates?.lon ?? -118.330491),
        }}
        zoomSettings={{
          maxZoom: 19,
          zoomFactor: 19,
          enable: true,
          toolbarSettings: {
            buttonSettings: {
              toolbarItems: ["Zoom", "ZoomIn", "ZoomOut", "Pan", "Reset"],
            },
          },
        }}
      >
        <Inject services={[Marker, Zoom, MapsTooltip]} />
        <LayersDirective>
          <LayerDirective urlTemplate="https://tile.openstreetmap.org/level/tileX/tileY.png">
            <MarkersDirective>
              <MarkerDirective
                visible={true}
                height={25}
                width={"auto"}
                fill={"red"}
                dataSource={[
                  {
                    latitude: parseFloat(coordinates?.lat ?? 34.06062),
                    longitude: parseFloat(coordinates?.lon ?? -118.330491),
                    name: "My Address",
                  },
                ]}
                tooltipSettings={{
                  visible: true,
                  valuePath: "name",
                }}
              ></MarkerDirective>
            </MarkersDirective>
          </LayerDirective>
        </LayersDirective>
      </MapsComponent>

      <div className="absolute bottom-4 left-4 right-4 z-10">
        <div className="inline-flex flex-wrap items-center justify-start gap-2 rounded-md bg-white p-2 shadow-md">
          <b className="mr-4 text-sm uppercase">LEGEND</b>
          <LegendButton icon={<CentersIcon />} text="Certified Centers" />
          <LegendButton
            icon={<PartnershipIcon />}
            text="Non-Profit Coalition Partners"
          />
          <LegendButton icon={<FireIcon />} text="Fire" bgColor="#BF0000" />
          <LegendButton icon={<FloodIcon />} text="Flood" bgColor="#1945B4" />
          <LegendButton
            icon={<EarthquakeIcon />}
            text="Earthquake"
            bgColor="#C94D26"
          />
          <LegendButton
            icon={<HurricaneIcon />}
            text="Hurricane"
            bgColor="#6222D9"
          />
          <LegendButton
            icon={<TornadoIcon />}
            text="Tornado"
            bgColor="#808080"
          />
        </div>
      </div>
      <DialogComponent
        width="400px"
        isModal={true}
        visible={showPopup}
        close={handleClosePopup}
        header={null}
        content={dialogContent}
        showCloseIcon={true}
        closeOnEscape={true}
        target={document.body}
        cssClass="welcome-dialog"
      />
    </div>
  );
};

const LegendButton = ({ icon, text, bgColor }) => (
  <button className="text-darkslategray hover:bg-gray-100 flex items-center gap-2 rounded-md border border-gainsboro-200 bg-white px-3 py-1 text-sm font-semibold">
    {bgColor ? (
      <div
        className="flex h-5 w-5 items-center justify-center rounded-full"
        style={{ backgroundColor: bgColor }}
      >
        {icon}
      </div>
    ) : (
      icon
    )}
    <span>{text}</span>
  </button>
);
export default Map;
