import React, { useState, useRef, useEffect } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import {
  getDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import dataService from "../../service/data/DataService";

const Partnerships = () => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [centers, setCenters] = useState([]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });

        console.log("Center ID:", userDoc.data().center_id);
        const resp = await dataService.getPartnerForApporval(
          userDoc.data().center_id
        );

        setGridData(resp);
        console.log("Resp:", resp);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const sortSettings = {
    allowSorting: true,
  };

  const filterSettings = {
    type: "Menu",
  };
  const [isGridVisible, setIsGridVisible] = useState(true);
  const toggleGrid = (event) => {
    event.preventDefault();
    setIsGridVisible(!isGridVisible);
  };
  // Toggle is_approved field
  const handleApproveClick = async (id, isApproved) => {
    try {
      const newApprovalStatus = !isApproved;

      // Update the document with the new approval status
      await updateDoc(doc(db, "partner-for-approval", id), {
        is_approved: newApprovalStatus,
      });

      console.log(
        `Application ${
          newApprovalStatus ? "approved" : "disapproved"
        } for center:`,
        id
      );

      // Update the local state or grid data if needed
      setGridData((prevGridData) =>
        prevGridData.map((item) =>
          item.id === id ? { ...item, is_approved: newApprovalStatus } : item
        )
      );
    } catch (error) {
      console.error("Error toggling approval status:", error);
    }
  };

  // Toggle is_certified field
  const handleCertifyClick = async (id, isCertified) => {
    try {
      const newCertifiedStatus = !isCertified;

      // Update the document with the new certification status
      await updateDoc(doc(db, "partner-for-approval", id), {
        is_certified: newCertifiedStatus,
      });

      console.log(
        `Application ${
          newCertifiedStatus ? "certified" : "uncertified"
        } for center:`,
        id
      );

      // Update the local state or grid data if needed
      setGridData((prevGridData) =>
        prevGridData.map((item) =>
          item.id === id ? { ...item, is_certified: newCertifiedStatus } : item
        )
      );
    } catch (error) {
      console.error("Error toggling certification status:", error);
    }
  };

  // Render the buttons in the status column
  const statusTemplate = (rowData) => {
    return (
      <div>
        <button
          onClick={() => handleApproveClick(rowData.id, rowData.is_approved)}
          className={`e-btn e-flat ${
            rowData.is_approved
              ? "bg-green-500 text-white"
              : "bg-red-500 text-white"
          }`}
        >
          {rowData.is_approved ? "Approved" : "Approve"}
        </button>
        <button
          onClick={() => handleCertifyClick(rowData.id, rowData.is_certified)}
          className={`e-btn e-flat ${
            rowData.is_certified
              ? "bg-green-500 text-white"
              : "bg-red-500 text-white"
          }`}
        >
          {rowData.is_certified ? "Certified" : "Certify"}
        </button>
      </div>
    );
  };

  return (
    <div>
      <section className="box-border flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch px-0 pb-[5px] pt-0 text-left font-raleway text-xs text-dodgerblue">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
              Non Profit Partnerships
            </h2>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleGrid}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isGridVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>

        {isGridVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              dataSource={gridData}
              allowSorting={true}
              sortSettings={sortSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
              toolbar={["Search"]}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="name_of_org"
                  headerText="Name of Organization"
                  width="100"
                />
                <ColumnDirective
                  field="address"
                  headerText="Address"
                  width="100"
                />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="100"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="100"
                />
                <ColumnDirective
                  headerText="Status"
                  width="100"
                  template={statusTemplate}
                />
              </ColumnsDirective>
              <Inject services={[Page, Edit, Toolbar, Sort, Filter, Search]} />
            </GridComponent>
          </div>
        )}
      </section>
    </div>
  );
};

export default Partnerships;
