import React, { useCallback, useRef, useState } from "react";
import {
  ScheduleComponent,
  Day,
  Week,
  Month,
  Agenda,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";

const Calendar = () => {
  const schedRef = useRef(null);
  const modalType = useRef(null);
  const newSchedData = useRef({
    id: null,
    title: "",
    description: "",
    date: "",
    start_time: "",
    end_time: "",
    is_all_day: false,
  });

  const [schedDataSource, setSchedDataSource] = useState([
    {
      id: "1",
      title: "Tech Innovators Summit 2024",
      description:
        "Join industry leaders and tech enthusiasts at the Tech Innovators Summit 2024. Discover the latest advancements in AI, blockchain, and IoT through keynote speeches, panel discussions, and hands-on workshops.",
      date: new Date("2024-08-01"),
      start_time: new Date(2024, 7, 1, 10, 0),
      end_time: new Date(2024, 7, 1, 10, 0),
      is_all_day: false,
    },
    {
      id: "2",
      title: "Global Marketing Strategies Conference",
      description:
        "Explore cutting-edge marketing strategies at this global conference. Learn from top marketing experts about the latest trends in digital marketing, social media, and consumer behavior to elevate your brand’s presence.",
      date: new Date("2024-08-02"),
      start_time: new Date(2024, 7, 2, 10, 0),
      end_time: new Date(2024, 7, 2, 10, 0),
      is_all_day: false,
    },
    {
      id: "3",
      title: "Sustainable Business Expo",
      description:
        "Connect with like-minded professionals at the Sustainable Business Expo. This event focuses on eco-friendly business practices, featuring exhibitors, seminars, and networking opportunities to promote sustainability in various industries.",
      date: new Date("2024-08-05"),
      start_time: new Date(2024, 7, 5, 10, 0),
      end_time: new Date(2024, 7, 5, 10, 0),
      is_all_day: false,
    },
    {
      id: "4",
      title: "Entrepreneurship Bootcamp",
      description:
        "Kickstart your entrepreneurial journey at the Entrepreneurship Bootcamp. This intensive program offers practical advice, mentorship, and resources to help aspiring entrepreneurs turn their ideas into successful businesses.",
      date: new Date("2024-08-08"),
      start_time: new Date(2024, 7, 8, 10, 0),
      end_time: new Date(2024, 7, 8, 10, 0),
      is_all_day: false,
    },
    {
      id: "5",
      title: "Financial Planning Workshop",
      description:
        "Enhance your financial acumen at the Financial Planning Workshop. Gain insights from financial experts on investment strategies, retirement planning, and wealth management to secure your financial future.",
      date: new Date("2024-08-09"),
      start_time: new Date(2024, 7, 9, 10, 0),
      end_time: new Date(2024, 7, 9, 10, 0),
      is_all_day: false,
    },
  ]);

  const onCloseQi = (isDelay) => {
    if (isDelay) {
      setTimeout(() => {
        schedRef.current.closeQuickInfoPopup();
      }, 1000);
    } else {
      schedRef.current.closeQuickInfoPopup();
    }
  };

  const onSchedDataToDefault = () => {
    newSchedData.current.id = null;
    newSchedData.current.title = "";
    newSchedData.current.description = "";
    newSchedData.current.date = "";
    newSchedData.current.start_time = "";
    newSchedData.current.end_time = "";
    newSchedData.current.is_all_day = false;
  };

  const onFormatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const onChangeProps = (key) => (e) => {
    if (key === "start_time" || key === "end_time") {
      const time = new Date(e.target.value);
      time.setDate(new Date(newSchedData.current.date).getDate());
      time.setFullYear(new Date(newSchedData.current.date).getFullYear());
      time.setMonth(new Date(newSchedData.current.date).getMonth());
      newSchedData.current[key] = time;
      return;
    }
    newSchedData.current[key] = e.target.value;
  };

  const schedContent = useCallback(() => {
    return (
      <div
        className={`flex flex-col space-y-2 ${
          modalType.current === "edit" && "mr-3 mt-5"
        }`}
      >
        <div>
          <TextBoxComponent
            placeholder="Title"
            floatLabelType="Auto"
            cssClass="e-outline"
            maxLength="255"
            value={newSchedData.current.title}
            onChange={onChangeProps("title")}
          />
        </div>
        <div className="flex space-x-2">
          <TimePickerComponent
            cssClass="e-outline"
            placeholder="Start Time"
            floatLabelType="Auto"
            value={newSchedData.current.start_time}
            onChange={onChangeProps("start_time")}
          />
          <TimePickerComponent
            cssClass="e-outline"
            placeholder="End Time"
            floatLabelType="Auto"
            value={newSchedData.current.end_time}
            onChange={onChangeProps("end_time")}
          />
        </div>
        <div className="flex flex-col">
          <TextAreaComponent
            placeholder="Description"
            floatLabelType="Auto"
            cssClass="e-outline"
            maxLength="255"
            className="h-28 max-h-40"
            value={newSchedData.current.description}
            onChange={onChangeProps("description")}
          />
        </div>
        {modalType.current === "edit" && (
          <div className="mb-5 flex w-full justify-between space-x-2">
            <button
              onClick={() => {
                setSchedDataSource((prev) => {
                  prev = prev.filter((p) => p.id !== newSchedData.current.id);
                  return prev;
                });
                onCloseQi(true);
              }}
              className="cursor-pointer rounded-md border border-solid border-red-500 bg-white px-3 py-2 text-red-500"
            >
              Delete
            </button>
            <div className="flex space-x-2">
              <button
                onClick={() => onCloseQi(false)}
                className="cursor-pointer rounded-md border border-solid border-[#348BFF] bg-white px-3 py-2 text-[#348BFF]"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setSchedDataSource((prev) => {
                    const updated = prev.map((d) =>
                      d.id === newSchedData.current.id
                        ? {
                            ...newSchedData.current,
                          }
                        : d
                    );
                    return updated;
                  });
                  onCloseQi(true);
                }}
                className="cursor-pointer rounded-md bg-[#348BFF] px-3 py-2 text-white"
              >
                Update
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }, []);

  const schedFooter = useCallback(
    () => (
      <div className="mb-5 flex w-full justify-end space-x-2 pr-[13px]">
        <button
          onClick={() => onCloseQi(false)}
          className="cursor-pointer rounded-md border border-solid border-[#348BFF] bg-white px-3 py-2 text-[#348BFF]"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            setSchedDataSource((prev) => [
              ...prev,
              { ...newSchedData.current, id: Date.now().toString() },
            ]);

            onCloseQi(true);
          }}
          className="cursor-pointer rounded-md bg-[#348BFF] px-3 py-2 text-white"
        >
          Save
        </button>
      </div>
    ),
    []
  );

  return (
    <div className="relative flex h-full min-h-[94vh] flex-col space-y-2 py-5 pl-3 pr-5 font-raleway">
      <ScheduleComponent
        ref={schedRef}
        height="90vh"
        currentView="Month"
        eventSettings={{
          dataSource: schedDataSource,
          fields: {
            id: { name: "id" },
            subject: { name: "title" },
            startTime: { name: "start_time" },
            endTime: { name: "end_time" },
            description: { name: "description" },
            isAllDay: { name: "is_all_day" },
          },
        }}
        enableAllDayScroll
        quickInfoTemplates={{
          header: () => {
            return (
              <div className="bg-[#348BFF] p-3">
                <h2 className="font-bold text-white">
                  {modalType.current === "edit"
                    ? "Edit Event"
                    : "Add New Event"}
                </h2>
              </div>
            );
          },
          content: schedContent,
          footer: schedFooter,
        }}
        cellClick={(e) => {
          modalType.current = "add";
          onSchedDataToDefault();
          newSchedData.current.date = onFormatDate(e.startTime);
        }}
        eventClick={({ event }) => {
          modalType.current = "edit";
          newSchedData.current.id = event.id;
          newSchedData.current.title = event.title;
          newSchedData.current.description = event.description;
          newSchedData.current.date = event.date;
          newSchedData.current.start_time = event.start_time;
          newSchedData.current.end_time = event.end_time;
          newSchedData.current.is_all_day = event.is_all_day;
        }}
      >
        <ViewsDirective>
          <ViewDirective option="Day" />
          <ViewDirective option="Week" />
          <ViewDirective option="Month" />
          <ViewDirective option="Agenda" />
        </ViewsDirective>
        <Inject services={[Day, Week, Month, Agenda]} />
      </ScheduleComponent>
    </div>
  );
};

export default Calendar;
