import axios from 'axios';
import environment from './environment';

const getToken = async () => {
  try {
    const response = await axios.post(`${environment.apiUrl}/get_token`, {
      api_key: environment.apiKey,
    });

    if (response.status === 200) {
      const token = response.data.token; 
      return token;
    } else {
      console.error('Failed to get token:', response.status);
    }
  } catch (error) {
    console.error('Error getting token from backend:', error);
  }
};

export default getToken;
