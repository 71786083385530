import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { useAuth } from "../components/AuthContext";
import Login1 from "./Login1";
import Login2 from "./Login2";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin1, setIsLogin1] = useState(true);
  const navigate = useNavigate();
  const { login, setRoleBasedRoute } = useAuth();

  const getUserByEmail = async (email) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching documents.");
        return null;
      }

      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });

      return userData.length > 0 ? userData[0] : null;
    } catch (error) {
      console.error("Error getting user by email:", error);
      return null;
    }
  };

  const handleLogin = async (event) => {

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = await getUserByEmail(email);
      setRoleBasedRoute(user.role);
      login(userCredential.user.accessToken);
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleGoogleLogin = async (event) => {
    event.preventDefault();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    try {
      const result = await signInWithPopup(auth, provider);
      console.log(result);
      if (!checkIfEmailExists(result.user.email)) {
        const userData = {
          first_name: result.user.displayName.split(" ")[0],
          last_name: result.user.displayName.split(" ").slice(-1).join(" "),
          email: result.user.email,
          mobile_number: result.user.phoneNumber || "",
          status: "active",
          user_role: "citizen",
        };

        await setDoc(doc(db, "users", result.user.uid), userData);
      }
      const user = await getUserByEmail(email);
      setRoleBasedRoute(user.role);
      login(result.user.accessToken);
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    } catch (err) {
      alert(err.message);
      console.error("Error during Google Sign-in:", err);
    }
  };

  const checkIfEmailExists = async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty; // Returns true if email exists
  };

  const onContinue = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && emailPattern.test(email)) {
      setIsLogin1(false);
    } else {
      alert("Please enter a valid email address.");
      setIsLogin1(true);
    }
  };

  return (
    <div>
      {isLogin1 ? (
        <Login1
          email={email}
          onEmailChange={setEmail}
          onContinue={onContinue}
          onLoginWithGoogle={handleGoogleLogin}
        />
      ) : (
        <Login2
          password={password}
          onPasswordChange={setPassword}
          onLogin={handleLogin}
        />
      )}
    </div>
  );
};

export default Login;
