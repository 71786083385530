import React from "react";

const PartnerHistory = () => {
  return (
    <div>
      <h1>PartnerHistory</h1>
    </div>
  );
};

export default PartnerHistory;
