import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { registerLicense, Internationalization } from "@syncfusion/ej2-base";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { householdMembers, pets } from "./datasource";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import citizenProfileService from "../service/citizen/CitizenProfile";
import { USStates } from "./datasource";

const sortSettings = {
  allowSorting: true,
};

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  mode: "Dialog",
  showDeleteConfirmDialog: true,
  dialog: { cssClass: "custom-dialog" },
};

const toolbarOptions = ["Add", "Edit", "Delete"];
const filterSettings = {
  type: "Menu",
};

const intl = new Internationalization();

const formatDate = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp); // Assuming timestamp is already a string
    return date.toLocaleDateString("en-US"); // Format as MM/DD/YYYY
  }
  return "";
};

const formattedData = householdMembers.map((item) => ({
  ...item,
  dateOfBirth: formatDate(item.dateOfBirth),
}));

const datepickerparams = {
  params: {
    format: "MM/dd/yyyy",
    showClearButton: false,
    showTodayButton: false,
  },
};

const CitizenProfile = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempFormData, setTempFormData] = useState({});
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [householdMembers, setHouseholdMembers] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const errorTimeoutRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      userId
    );
    if (citizenData) {
      setData(citizenData);
      setTempFormData(citizenData);
      if (citizenData.updatedData_at)
        setLastEditedTime(citizenData.updated_at.toDate());
      console.log("Citizen data: ", citizenData);

      const householdData = citizenData.household || {};
      setHouseholdFormData(householdData);
      setTempHouseholdFormData(householdData);
      setLastHouseholdEditedTime(
        householdData.updated_at ? householdData.updated_at.toDate() : null
      );
      console.log("Household data: ", citizenData);

      // Fetch household members
      const householdMembersData =
        await citizenProfileService.getHouseholdMembers(userId);
      setHouseholdMembers(householdMembersData);
    }

    // Fetch pets
    const petsData = await citizenProfileService.getPets(userId);
    setPets(petsData);
  };

  const togglePersonalInfo = (event) => {
    event.preventDefault();
    setIsPersonalInfoVisible(!isPersonalInfoVisible);
  };

  const startEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(true);
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    const errors = validatePersonalInfo(tempFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      
      // Clear previous timeout if it exists
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
  
      // Set a new timeout to clear errors after 5 seconds
      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);
  
      return;
    }
  
    const currentTime = new Date();
    const updatedData = { ...tempFormData, updated_at: currentTime };
  
    try {
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedData
      );
      setData(updatedData);
      setIsEditMode(false);
      setLastEditedTime(currentTime);
      setValidationErrors({});
      console.log("Edited time: ", currentTime);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const cancelEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTempFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const renderField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = ""
  ) => {
    const requiredFields = ['address1', 'address2', 'city', 'state', 'zip', 'mobile_number', 'secondary_phone', 'alternate_contact'];
    const isRequired = requiredFields.includes(name);
  
    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative text-left font-raleway text-xs leading-[20px]">
          {label}
          {isEditMode && isRequired && <span className="text-red-500 text-lg ml-1">*</span>}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${validationErrors[name] ? "border-red-500" : "border-gray-300"} px-3 py-2`}
        >
          {isEditMode ? (
            isDropdown ? (
              <select
                className="w-full bg-white text-left font-raleway text-xs text-black [border:none] [outline:none]"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {USStates.map((state) => (
                  <option key={state.stateID} value={state.abbreviation}>
                    {state.abbreviation}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left font-raleway text-xs text-black [border:none] [outline:none]"
                type="text"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
                placeholder={placeholder}
              />
            )
          ) : (
            <div
              className={`flex-1 font-raleway text-xs ${
                tempFormData[name] ? "font-semibold text-gray-800" : "text-gray-500"
              } text-left`}
            >
              {tempFormData[name] || placeholder}
            </div>
          )}
        </div>
        {isEditMode && validationErrors[name] && (
          <div className="text-red-500 text-xs mt-1">{validationErrors[name]}</div>
        )}
      </div>
    );
  };
  
  const validatePersonalInfo = (formData) => {
    const errors = {};
    const requiredFields = ['address1', 'address2', 'city', 'state', 'zip', 'mobile_number', 'secondary_phone', 'alternate_contact'];
    
    requiredFields.forEach(field => {
      if (!formData[field] || formData[field].trim() === '') {
        errors[field] = 'This field is required';
      }
    });
  
    return errors;
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isNewlyRegistered = localStorage.getItem('newlyRegistered');
    if (isNewlyRegistered === 'true') {
      setShowPopup(true);
      localStorage.removeItem('newlyRegistered'); // Remove the flag after showing the popup
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* You can replace this with your own icon or image */}
        <div style={{ fontSize: '48px', marginBottom: '20px' }}>🚀</div>
        <h2 style={{ fontSize: '24px', fontWeight: 'normal', color: '#1e1919', marginBottom: '10px' }}>
          Welcome to your new Citizen Profile!
        </h2>
        <p style={{ fontSize: '16px', color: '#637381', marginBottom: '20px' }}>
          Please finish your profile to get the most out of our services.
        </p>
        <button 
          onClick={handleClosePopup}
          style={{
            backgroundColor: '#0061fe',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            fontSize: '14px',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Got it!
        </button>
      </div>
    );
  };
  
  {
    /* Household Information Fields*/
  }
  const [isHouseholdInfoVisible, setIsHouseholdInfoVisible] = useState(true);
  const [householdFormData, setHouseholdFormData] = useState({
    annual_income: "",
    ssn: "",
    owner_or_renter: "",
    insurance: false,
    household_size: "",
    disability: false,
    military_vet: false,
  });
  const [tempHouseholdFormData, setTempHouseholdFormData] = useState({
    ...householdFormData,
  });
  const [lastHouseholdEditedTime, setLastHouseholdEditedTime] = useState(null);

  const [isHouseholdEditMode, setIsHouseholdEditMode] = useState(false);

  const toggleHouseholdInfo = (event) => {
    event.preventDefault();
    setIsHouseholdInfoVisible(!isHouseholdInfoVisible);
  };

  const startHouseholdEditing = (event) => {
    event.preventDefault();
    setIsHouseholdEditMode(true);
  };

  const validateHouseholdInfo = (formData) => {
    const errors = {};
    const requiredFields = ['disability', 'military_vet', 'owner_or_renter', 'insurance', 'household_size'];
    
    requiredFields.forEach(field => {
      if (formData[field] === undefined || formData[field] === '' || formData[field] === null) {
        errors[field] = 'This field is required';
      }
    });
  
    return errors;
  };

  const saveHouseholdChanges = async (event) => {
    event.preventDefault();
    const errors = validateHouseholdInfo(tempHouseholdFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      
      // Clear previous timeout if it exists
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
  
      // Set a new timeout to clear errors after 5 seconds
      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);
  
      return;
    }
  
    const currentTime = new Date();
    const updatedHouseholdData = {
      ...tempHouseholdFormData,
      updated_at: currentTime,
    };
  
    try {
      // Fetch the current profile data
      const currentProfile = await citizenProfileService.getCitizenProfile(
        "users",
        userId
      );
      const updatedProfile = {
        ...currentProfile,
        household: updatedHouseholdData,
      };
  
      // Update the backend with the new household data
      await citizenProfileService.updateCitizenProfile(
        "users",
        userId,
        updatedProfile
      );
  
      // Update the frontend state
      setHouseholdFormData(updatedHouseholdData);
      setIsHouseholdEditMode(false);
      setLastHouseholdEditedTime(currentTime);
      setValidationErrors({});
  
      console.log("Household edited time: ", currentTime);
    } catch (error) {
      console.error("Error updating household profile: ", error);
    }
  };

  const cancelHouseholdEditing = (event) => {
    event.preventDefault();
    setTempHouseholdFormData({ ...householdFormData });
    setIsHouseholdEditMode(false);
  };

  const handleHouseholdInputChange = (event) => {
    const { name, value } = event.target;
    setTempHouseholdFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "disability" || name === "military_vet" || name === "insurance"
          ? value === "Yes"
          : value,
    }));
  };

  const renderHouseholdField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = "",
    isOptional = false
  ) => {
    const isRequired = !isOptional;
  
    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative text-left font-raleway text-xs leading-[20px]">
          {label}
          {isHouseholdEditMode && (
            isRequired ? 
              <span className="text-red-500 text-lg ml-1">*</span> : 
              <span className="text-gray-400 text-xs ml-1">(Optional)</span>
          )}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isHouseholdEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${validationErrors[name] ? "border-red-500" : "border-gray-300"} px-3 py-2`}
        >
          {isHouseholdEditMode ? (
            isDropdown ? (
              <select
                className="w-full bg-white text-left font-raleway text-xs text-black [border:none] [outline:none]"
                name={name}
                value={name === "insurance" ? (tempHouseholdFormData[name] ? "Yes" : "No") : tempHouseholdFormData[name] || ""}
                onChange={handleHouseholdInputChange}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {name === "owner_or_renter" ? (
                  <>
                    <option value="Owner">Owner</option>
                    <option value="Renter">Renter</option>
                  </>
                ) : (
                  <>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </>
                )}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left font-raleway text-xs text-black [border:none] [outline:none]"
                placeholder={placeholder}
                name={name}
                value={tempHouseholdFormData[name] || ""}
                onChange={handleHouseholdInputChange}
              />
            )
          ) : (
            <div
              className={`flex-1 font-raleway text-xs ${
                householdFormData[name] !== undefined && householdFormData[name] !== ""
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
            >
              {name === "insurance"
                ? (householdFormData[name] ? "Yes" : "No")
                : householdFormData[name] || placeholder}
            </div>
          )}
        </div>
        {isHouseholdEditMode && validationErrors[name] && (
          <div className="text-red-500 text-xs mt-1">{validationErrors[name]}</div>
        )}
      </div>
    );
  };

  {
    /* Household Members */
  }
  const [isHouseholdMembersVisible, setIsHouseholdMembersVisible] =
    useState(true);
  const [lastHouseholdMembersEditedTime, setLastHouseholdMembersEditedTime] =
    useState(null);

  const toggleHouseholdMembers = (event) => {
    event.preventDefault();
    setIsHouseholdMembersVisible(!isHouseholdMembersVisible);
  };

  const gridRef = useRef(null);

  const handleAddClick = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const createConfirmDialog = (title, content, onConfirm) => {
    const dialog = new DialogComponent({
      header: title,
      content: content,
      buttons: [
        {
          click: () => {
            onConfirm();
            closeAndDestroyDialog(dialog);
          },
          buttonModel: { content: 'Yes', isPrimary: true }
        },
        {
          click: () => {
            closeAndDestroyDialog(dialog);
          },
          buttonModel: { content: 'No' }
        }
      ],
      width: '300px',
      isModal: true,
      visible: false,
      cssClass: 'delete-confirmation-dialog',
      close: () => closeAndDestroyDialog(dialog)
    });
    dialog.appendTo('#dialog-container');
    
    // Apply custom styles to the dialog header
    dialog.created = () => {
      if (dialog.element) {
        const headerElement = dialog.element.querySelector('.e-dlg-header-content');
        if (headerElement) {
          headerElement.style.backgroundColor = '#348BFF';
          headerElement.style.color = '#FFFFFF';
          headerElement.style.padding = '10px';
        }
      }
    };
    
    dialog.show();
  };

  const closeAndDestroyDialog = (dialog) => {
    dialog.hide();
    setTimeout(() => {
      dialog.destroy();
      const dialogElement = document.querySelector('.delete-confirmation-dialog');
      if (dialogElement) {
        dialogElement.remove();
      }
    }, 300); // Wait for the hide animation to complete
  };

  const actionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure householdMemberID and status are set
        const newMemberData = {
          ...args.data,
          householdMemberID: args.data.householdMemberID || generateUniqueID(),
          is_deleted: false,
        };
        console.log("Adding new household member:", newMemberData);
        await citizenProfileService.addHouseholdMember(userId, newMemberData);
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedMemberData = {
          ...args.data,
          is_deleted: false,
        };
        console.log("Updating household member:", updatedMemberData);
        await citizenProfileService.updateHouseholdMember(
          userId,
          updatedMemberData.id,
          updatedMemberData
        );
      }
      setLastHouseholdMembersEditedTime(new Date());
      // Refresh the grid data
      const updatedMembers = await citizenProfileService.getHouseholdMembers(userId);
      setHouseholdMembers(updatedMembers);
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedMemberData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updateHouseholdMember(
        userId,
        updatedMemberData.id,
        updatedMemberData
      );
      setLastHouseholdMembersEditedTime(new Date());
      // Refresh the grid data
      const updatedMembers = await citizenProfileService.getHouseholdMembers(userId);
      setHouseholdMembers(updatedMembers);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
    if (args.dialog) {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit"
          ? "Edit Household Member"
          : "Add New Household Member";

      // Set the header styles
      if (dialog.element) {
        const headerContent = dialog.element.querySelector(
          ".e-dlg-header-content"
        );
        const header = dialog.element.querySelector(".e-dlg-header");
        if (headerContent) {
          headerContent.style.backgroundColor = "#348BFF";
          headerContent.style.color = "#FFFFFF";
        }
        if (header) {
          header.style.color = "#FFFFFF";
        }

        // Style the save and cancel buttons
        setTimeout(() => {
          const saveButton = dialog.element.querySelector(".e-primary");
          const cancelButton = dialog.element.querySelector(
            ".e-btn:not(.e-primary)"
          );

          if (saveButton) {
            saveButton.style.backgroundColor = "#FFFFFF";
            saveButton.style.color = "#348BFF";
            saveButton.style.border = "none";
          }

          if (cancelButton) {
            cancelButton.style.backgroundColor = "#FFFFFF";
            cancelButton.style.color = "#348BFF";
            cancelButton.style.border = "1px solid #348BFF";
          }
        }, 0);
      }
    }
  }
};



// Filter household members to only include those with status true
const filteredHouseholdMembers = Array.isArray(householdMembers) 
  ? householdMembers.filter(member => !member.is_deleted) 
  : [];

  const deleteHouseholdMember = async (data) => {
    try {
      await citizenProfileService.deleteHouseholdMember(userId, data.id);
      setLastHouseholdMembersEditedTime(new Date());
      // Refresh the grid data
      const updatedMembers = await citizenProfileService.getHouseholdMembers(userId);
      setHouseholdMembers(updatedMembers);
    } catch (error) {
      console.error("Error deleting household member:", error);
    }
  };

  {
    /*Pets*/
  }
  const [pets, setPets] = useState([]);
  const [isPetsVisible, setIsPetsVisible] = useState(true);
  const [lastPetsEditedTime, setLastPetsEditedTime] = useState(null);
  const petsGridRef = useRef(null);

  const togglePets = () => {
    setIsPetsVisible(!isPetsVisible);
  };

  const handleAddPet = () => {
    if (petsGridRef.current) {
      petsGridRef.current.addRecord();
    }
  };

  const petsActionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure petId and status are set
        const newPetData = {
          ...args.data,
          petId: generateUniqueID(),
          is_deleted: false,
        };
        console.log("Adding new pet:", newPetData);
        await citizenProfileService.addPet(userId, newPetData);
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedPetData = {
          ...args.data,
          is_deleted: false,
        };
        console.log("Updating pet:", updatedPetData);
        // Use the id field for updating, as it corresponds to the Firestore document ID
        await citizenProfileService.updatePet(
          userId,
          updatedPetData.id,
          updatedPetData
        );
      }
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(userId);
      setPets(updatedPets);
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedPetData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updatePet(
        userId,
        updatedPetData.id, // Use id instead of petId
        updatedPetData
      );
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(userId);
      setPets(updatedPets);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Pet"
            : "Add New Pet";
  
        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }
  
          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );
  
            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }
  
            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };
  
  // Filter pets to only include those with status true
  const filteredPets = Array.isArray(pets) 
  ? pets.filter(pet => !pet.is_deleted) 
  : [];

  const deletePet = async (data) => {
    try {
      await citizenProfileService.deletePet(userId, data.petID);
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(userId);
      setPets(updatedPets);
    } catch (error) {
      console.error("Error deleting pet:", error);
    }
  };

  return (
    <form
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left font-raleway text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          My Recovery Plan
        </a>
        <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
          /
        </div>
        <a className="relative inline-block min-w-[77px] text-left font-raleway text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Citizen Profile
        </a>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
              PERSONAL INFORMATION
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {isEditMode ? (
              <>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                  onClick={saveChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                  onClick={cancelEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={startEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
              onClick={togglePersonalInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${isPersonalInfoVisible ? "rotate-0" : "rotate-180"
                  }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPersonalInfoVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            {renderField(
              "Address 1",
              "address1",
              ""
            )}
            {renderField(
              "Address 2",
              "address2",
              ""
            )}
            <div className="flex flex-row gap-4">
              {renderField(
                "City",
                "city",
                "",
                false,
                "flex-1"
              )}
              {renderField(
                "Select a state",
                "state",
                "Select a state",
                true,
                "flex-1"
              )}
              {renderField("Zip", "zip", "e.g., 62704", false, "flex-1")}
            </div>
            <div className="flex flex-row gap-4">
              {renderField(
                "Phone Number / Email",
                "mobile_number",
                "",
                false,
                "flex-1"
              )}
              {renderField(
                "Secondary Phone Number",
                "secondary_phone",
                "",
                false,
                "flex-1"
              )}
              {renderField(
                "Alternate Contact",
                "alternate_contact",
                "",
                false,
                "flex-1"
              )}
            </div>
          </div>
        )}
      </section>

      {/* Household Information */}
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
              HOUSEHOLD INFORMATION
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastHouseholdEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {isHouseholdEditMode ? (
              <>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                  onClick={saveHouseholdChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                  onClick={cancelHouseholdEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={startHouseholdEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleHouseholdInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${isHouseholdInfoVisible ? "rotate-0" : "rotate-180"
                  }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdInfoVisible && (
          <div className="mt-2 flex flex-col gap-4 self-stretch">
            <div className="flex flex-row gap-4">
              {renderHouseholdField(
                "Annual Household Income",
                "annual_income",
                "",
                false,
                "flex-1",
                true // Mark as optional
              )}
              
              <div className="flex flex-1 flex-row gap-4">
                <div className="flex-1">
                  <p className="text-gray-600 mb-1 pb-3 pt-1 font-raleway text-xs">
                    Does anyone have a disability?
                    {isHouseholdEditMode && <span className="text-red-500 text-lg ml-1">*</span>}
                  </p>
                  <div className="flex gap-4 ">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="disability"
                        value="Yes"
                        checked={tempHouseholdFormData.disability === true}
                        onChange={handleHouseholdInputChange}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${!isHouseholdEditMode ? "" : "cursor-pointer"}`}
                      />
                      <span className="text-xs">Yes</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="disability"
                        value="No"
                        checked={tempHouseholdFormData.disability === false}
                        onChange={handleHouseholdInputChange}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${!isHouseholdEditMode ? "" : "cursor-pointer"}`}
                      />
                      <span className="text-xs">No</span>
                    </label>
                  </div>
                  {isHouseholdEditMode && validationErrors.disability && (
                    <div className="text-red-500 text-xs mt-1">{validationErrors.disability}</div>
                  )}
                </div>
                <div className="flex-1">
                  <p className="text-gray-600 mb-1 pb-3 pt-1 font-raleway text-xs">
                    Active Military / Vet in the household
                    {isHouseholdEditMode && <span className="text-red-500 text-lg ml-1">*</span>}
                  </p>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="military_vet"
                        value="Yes"
                        checked={tempHouseholdFormData.military_vet === true}
                        onChange={handleHouseholdInputChange}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${!isHouseholdEditMode ? "" : "cursor-pointer"}`}
                      />
                      <span className="text-xs">Yes</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="military_vet"
                        value="No"
                        checked={tempHouseholdFormData.military_vet === false}
                        onChange={handleHouseholdInputChange}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${!isHouseholdEditMode ? "" : "cursor-pointer"}`}
                      />
                      <span className="text-xs">No</span>
                    </label>
                  </div>
                  {isHouseholdEditMode && validationErrors.military_vet && (
                    <div className="text-red-500 text-xs mt-1">{validationErrors.military_vet}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              {renderHouseholdField(
                "Owner or Renter?",
                "owner_or_renter",
                "Select Owner or Renter",
                true,
                "flex-1"
              )}
              {renderHouseholdField(
                "Do you have insurance?",
                "insurance",
                "Select Yes or No",
                true,
                "flex-1"
              )}
              {renderHouseholdField(
                "Household size",
                "household_size",
                "",
                false,
                "flex-1"
              )}
            </div>
          </div>
        )}
      </section>

      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
              HOUSEHOLD MEMBERS
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited:{" "}
              {formatLastEditedTime(lastHouseholdMembersEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleAddClick}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>

            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleHouseholdMembers}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${isHouseholdMembersVisible ? "rotate-0" : "rotate-180"
                  }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdMembersVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={gridRef}
              dataSource={filteredHouseholdMembers}
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionComplete={actionComplete}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="householdMemberID"
                  headerText="householdMemberID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="firstName"
                  headerText="First Name"
                  width="100"
                />
                <ColumnDirective
                  field="lastName"
                  headerText="Last Name"
                  width="100"
                />
                <ColumnDirective
                  field="dateOfBirth"
                  headerText="Date of Birth"
                  width="100"
                  editType="datepickeredit"
                  edit={datepickerparams}
                />
                <ColumnDirective
                  field="relationship"
                  headerText="Relationship"
                  width="100"
                />
                <ColumnDirective
                  field="disability"
                  headerText="Disability"
                  width="100"
                />
                <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject services={[Page, Edit, Sort, Filter, CommandColumn]} />
            </GridComponent>
          </div>
        )}
      </section>

      {/* Pets Section */}
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4 pt-4">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
              PETS
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited: {formatLastEditedTime(lastPetsEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleAddPet}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={togglePets}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${isPetsVisible ? "rotate-0" : "rotate-180"
                  }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPetsVisible && (
          <div className="flex flex-col gap-4 self-stretch">
           <GridComponent
  ref={petsGridRef}
  dataSource={filteredPets}
  keyField="petId"
  allowSorting={true}
  sortSettings={sortSettings}
  editSettings={editing}
  filterSettings={filterSettings}
  allowFiltering={true}
  actionComplete={petsActionComplete}
>
  <ColumnsDirective>
    <ColumnDirective
      field="petId"
      headerText="Pet ID"
      isPrimaryKey={true}
      visible={false}
    />
    <ColumnDirective
      field="petName"
      headerText="Pet Name"
      width="100"
    />
    <ColumnDirective
      field="petColor"
      headerText="Pet Color"
      width="100"
    />
    <ColumnDirective
      field="petBreed"
      headerText="Pet Breed"
      width="100"
    />
    <ColumnDirective
      field="petSize"
      headerText="Pet Size"
      width="100"
    />
                <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject services={[Page, Edit, Sort, Filter, CommandColumn]} />
            </GridComponent>
          </div>
        )}
      </section>
      <DialogComponent
        width="400px"
        isModal={true}
        visible={showPopup}
        close={handleClosePopup}
        header={null}
        content={dialogContent}
        showCloseIcon={true}
        closeOnEscape={true}
        target={document.body}
        cssClass="welcome-dialog"
      />
    </form>
  );
};

CitizenProfile.propTypes = {
  className: PropTypes.string,
};

export default CitizenProfile;
