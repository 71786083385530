import React, { useState } from "react";
import Phases from "../../../icons/CertificateVideo/Phases.png";
import Roles from "../../../icons/CertificateVideo/Roles.png";
import LedRecovery from "../../../icons/CertificateVideo/LedRecovery.png";
import CertifiedCitizen from "../../../icons/CertificateVideo/CertifiedCitizen.png";

const CertificationVideosCenter = ({
  openPhaseModal,
  openRolesModal,
  openCommunityRecoveryModal,
  openCertifiedCitizenModal,
  Modal,
  isPhaseModalOpen,
  closePhaseModal,
  isRolesModalOpen,
  closeRolesModal,
  isCommunityRecoveryModalOpen,
  closeCommunityRecoveryModal,
  isCertifiedCitizenModalOpen,
  closeCertifiedCitizenModal
}) => {
    const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full text-left text-sm text-darkslateblue font-raleway">
           <div 
        className="self-stretch flex items-center cursor-pointer" 
        onClick={toggleDropdown}
      >
        <svg 
          className={`w-6 h-6 mr-2 transform transition-transform ${isOpen ? 'rotate-90' : ''}`} 
          fill="currentColor" 
          viewBox="0 0 20 20"
        >
          <path d="M6 6L14 10L6 14V6Z" />
        </svg>
        <b className="self-stretch relative uppercase flex-grow pt-1">
          Blue Sky Center Course Videos
        </b>
      </div>
      {isOpen && (
            <div className="self-stretch rounded-tl-none rounded-tr-mini rounded-b-mini bg-white flex flex-row items-start justify-start pt-3 px-[42px] pb-[27px] box-border gap-[51px] max-w-full text-darkslategray mq450:gap-[25px] mq1125:pl-[21px] mq1125:pr-[21px] mq1125:box-border mq1300:flex-wrap">
              <div className="w-[563px] flex flex-col items-start justify-start gap-[14px] min-w-[563px] max-w-full mq800:min-w-full mq1300:flex-1">
                <b className="self-stretch relative">Center Introductory Videos</b>
                <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[17px_13px] min-h-[451px] text-smi text-darkslateblue">
                  <div className="h-[217px] w-[271px] flex flex-col items-start justify-start gap-[10px]">
                    <div className="relative w-full h-full cursor-pointer" onClick={openPhaseModal}>
                      <img
                        className="self-stretch flex-1 relative max-w-full overflow-hidden rounded-lg max-h-full"
                        alt="Phases of Disaster thumbnail"
                        src={Phases}
                      />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                        </svg>
                      </div>
                    </div>
                    <b className="self-stretch relative">Phases of Disaster</b>
                  </div>

                  <Modal
                    isOpen={isPhaseModalOpen}
                    onClose={closePhaseModal}
                    videoSrc="https://www.youtube.com/embed/Sj4eXgQu3vo?autoplay=1"
                  />

                  <div className="w-[272px] flex flex-col items-start justify-start gap-[10px]">
                  <div className="self-stretch h-48 relative cursor-pointer" onClick={openRolesModal}>
                    <img
                      className="absolute top-[0px] left-[0px] w-full h-full object-contain rounded-lg"
                      alt="National Roles and Responsibilities thumbnail"
                      src={Roles}
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                      </svg>
                    </div>
                  </div>
                  <b className="relative">
                    National Roles and Responsibility's in Re...
                  </b>
                </div>

            <Modal
              isOpen={isRolesModalOpen}
              onClose={closeRolesModal}
              videoSrc="https://www.youtube.com/embed/zrbklf_1uOU?autoplay=1"
            />

                              <div className="h-[217px] w-[273px] flex flex-col items-start justify-start gap-[10px]">
              <div className="relative w-full h-full cursor-pointer" onClick={openCommunityRecoveryModal}>
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full rounded-lg"
                  alt="Community Led Recovery thumbnail"
                  src={LedRecovery}
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                  </svg>
                </div>
              </div>
              <b className="self-stretch relative">Community Led Recovery</b>
            </div>

                <Modal
                  isOpen={isCommunityRecoveryModalOpen}
                  onClose={closeCommunityRecoveryModal}
                  videoSrc="https://www.youtube.com/embed/81t4quqwp5Q?autoplay=1"
                />


                <div className="h-[217px] w-[272px] flex flex-col items-start justify-start gap-[10px]">
                  <div className="relative w-full h-full cursor-pointer" onClick={openCertifiedCitizenModal}>
                    <img
                      className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full rounded-lg"
                      alt="Certified Citizen thumbnail"
                      src={CertifiedCitizen}
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                      </svg>
                    </div>
                  </div>
                  <b className="self-stretch relative">Citizen</b>
                </div>

                <Modal
                  isOpen={isCertifiedCitizenModalOpen}
                  onClose={closeCertifiedCitizenModal}
                  videoSrc="https://www.youtube.com/embed/t_Qlyfm6beY?autoplay=1"
                />
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[38px] min-w-[606px] max-w-full mq800:gap-[19px] mq800:min-w-full">
                <b className="self-stretch relative">
                  Supplemental Support Articles
                </b>
                <div className="self-stretch flex flex-col items-start justify-start gap-[23px] max-w-full">
                  <div className="relative font-semibold">
                    Support Article Center
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-full text-gray1-100">
                    <div className="flex-1 relative inline-block max-w-full">
                      <a
                        className="text-[inherit]"
                        href="https://www.redcross.org/content/dam/redcross/get-help/pdfs/tornado/EN_Tornado-Safety-Checklist.pdf"
                        target="_blank"
                      >
                        The recovery process for disaster victims can be long and complex. Government disaster aid is distributed through multiple agencies, each with their own p...
                      </a>
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full">
                  <div className="relative font-semibold">Why Blue Sky CENTER</div>
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-full text-gray1-100">
                    <div className="flex-1 relative inline-block max-w-full">
                      
                    Becoming a Blue Sky Center represents an invaluable opportunity to enhance community resilience in the face of disasters.
                    </div>
                  </div>
                </div>
              </div>
            </div>
             )}
    </section>
  );
};

export default CertificationVideosCenter;