import React, { useEffect, useState } from "react"; // Added useEffect and useState
import PropTypes from "prop-types";
import DrcCenterService from "../service/citizen/DrcCenterService"; // Adjusted path
import { auth } from "../firebase"; // Corrected path

const DrcCenter = ({ className = "" }) => {
  const [data, setData] = useState(null); // State to hold data
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors

  useEffect(() => {
    const fetchData = async () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid; // Get the logged-in user's UID
        console.log("Current User:", auth.currentUser); // Debugging statement

        try {
          // Step 1: Fetch the user document using the UID
          const userDoc = await DrcCenterService.getCitizenProfileByCenterId(userId); // Fetch user data
          console.log("Fetched user data:", userDoc); // Debugging statement

          if (userDoc) {
            const centerId = userDoc.center_id; // Extract center_id from user document
            console.log("Center ID:", centerId); // Debugging statement

            if (!centerId) {
              setError("Center ID is not defined."); // Set error if centerId is undefined
              setLoading(false);
              return;
            }

            // Step 2: Fetch the center document using the center_id
            const centerData = await DrcCenterService.getCitizenProfileByCenterId(centerId); // Fetch center data
            console.log("Fetched center data:", centerData); // Debugging statement

            if (centerData) {
              setData(centerData); // Set the center data
            } else {
              setError("No profile found for the given center_id."); // Set error message if no profile found
            }
          } else {
            setError("No user document found."); // Set error if user document is not found
          }
        } catch (err) {
          console.error("Error fetching data:", err);
          setError("Error fetching data."); // Set error message for any fetch errors
        }
      } else {
        setError("User is not logged in."); // Set error message if user is not logged in
      }
      setLoading(false); // Set loading to false after fetching
    };
    fetchData();
  }, []); // Fetch data on component mount

  const renderField = (label, value) => {
    return (
      <div className="flex flex-col items-start justify-start gap-1">
        <div className="text-gray-600 relative text-left font-raleway text-xs leading-[20px]">
          {label}
        </div>
        <div className={`self-stretch rounded-md bg-gray-200 box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid border-gray-300 px-3 py-2`}>
          <div className={`flex-1 font-raleway text-xs ${value ? "font-semibold text-gray-800" : "text-gray-500"} text-left`}>
            {value || "N/A"}
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="font-raleway text-lg text-gray-700">Loading...</div>
      </div>
    ); // Show loading state
  }

  return (
    <div className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}>
        <div className="flex flex-wrap items-center justify-between gap-4">
          <div className="flex items-center gap-2 font-raleway text-xs text-darkslateblue">
            <a className="font-medium">Local Community</a>
            <span className="text-gray-400 text-base font-semibold">/</span>
            <span className="font-medium">Center Information</span>
          </div>
        </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
          <b className="text-md relative leading-[21px] font-raleway">CENTER INFORMATION</b>
            <p className="relative text-sm font-raleway mt-2 font-medium leading-[21px]">If you find yourself in a location without a certified Community, I encourage you to contact your local emergency management authorities to learn more about the DRC recovery awareness program and take steps towards becoming a certified community.</p>          
          </div>
        </header>
        <div className="flex flex-col gap-4 self-stretch mr-3">
          {error ? ( // Check for error and display message
            <div className="text-red-500">{error}</div>
          ) : (
            <>
              {data && renderField("Name of Hub", data.name_of_hub)}
              {data && renderField("Contact Person", `${data.first_name} ${data.last_name}`)}
              {data && renderField("Address", data.address)}
              <div className="flex flex-row self-stretch gap-4">
                <div className="flex-1">
                  {data && renderField("Email", data.email)}
                </div>
                <div className="flex-1">
                  {data && renderField("Website", data.website)}
                </div>
              </div>
              <div className="flex flex-row self-stretch gap-4">
                <div className="flex-1">
                  {data && renderField("Social Media", data.soc_med)}
                </div>
                <div className="flex-1">
                  {data && renderField("Other Information Provided", data.other_info)}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

DrcCenter.propTypes = {
  className: PropTypes.string,
};

export default DrcCenter;