import React, { useState } from 'react';


const DictionaryItem = ({ term, definition }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="border-b border-gray-200 py-2">
        <div 
          className="flex items-center cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className={`transform transition-transform duration-200 mr-2 ${isOpen ? 'rotate-90' : ''}`}>
            ▶
          </span>
          <span className="font-semibold">{term}</span>
        </div>
        {isOpen && (
          <div className="mt-2 ml-6 text-sm">
            {definition}
          </div>
        )}
      </div>
    );
  };

  const QuestionItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="border-b border-gray-200 py-2">
        <div 
          className="flex items-center cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className={`transform transition-transform duration-200 mr-2 ${isOpen ? 'rotate-90' : ''}`}>
            ▶
          </span>
          <span className="font-semibold">{question}</span>
        </div>
        {isOpen && (
          <div className="mt-2 ml-6 text-sm">
            {answer}
          </div>
        )}
      </div>
    );
  };
const QuickInfo = () => {
  return <div className="w-full relative flex flex-row items-start justify-start leading-[normal] tracking-[normal]">
  <main className="flex-1 bg-white flex flex-col items-start justify-start pt-[18px] px-[25px] pb-[61px] box-border gap-[5.4px] max-w-full text-left text-sm text-darkslateblue font-raleway mq725:pb-[26px] mq725:box-border mq1050:pt-5 mq1050:pb-10 mq1050:box-border">
    <header className="self-stretch rounded-tl-none rounded-tr-8xs rounded-b-8xs flex flex-col items-start justify-start gap-1.5 text-left text-sm text-darkslateblue font-raleway">
      <b className="relative uppercase whitespace-nowrap">
        National voad resources
      </b>
      <div className="self-stretch rounded-tl-none rounded-tr-mini rounded-b-mini bg-white border-white border-[1px] border-solid flex flex-col items-start justify-start py-[18px] px-4 gap-1.5 text-darkslategray">
        <a
          className="self-stretch h-[37px] relative [text-decoration:underline] leading-[0%] text-[inherit] flex items-center shrink-0 whitespace-nowrap"
          href="https://www.nvoad.org/wp-content/uploads/longtermrecoveryguide-final2012.pdf"
          target="_blank"
        >
          <span>
            <ol className="m-0 font-inherit text-inherit pl-[19px]">
              <li>National VOAD Long Term Recovery Guide</li>
            </ol>
          </span>
        </a>
        <a
          className="self-stretch h-[37px] relative [text-decoration:underline] leading-[0%] text-[inherit] flex items-center shrink-0 whitespace-nowrap"
          href="https://www.nvoad.org/wp-content/uploads/dcmguidelines-final.pdf"
          target="_blank"
        >
          <span>
            <ol className="m-0 font-inherit text-inherit pl-[19px]">
              <li>National VOAD Case Management Guidelines</li>
            </ol>
          </span>
        </a>
        <a
          className="self-stretch h-[37px] relative [text-decoration:underline] leading-[0%] text-[inherit] flex items-center shrink-0 whitespace-nowrap"
          href="https://www.nvoad.org/wp-content/uploads/dscg_quick_reference.pdf"
          target="_blank"
        >
          <span>
            <ol className="m-0 font-inherit text-inherit pl-[19px]">
              <li>
                Quick Reference to the National VOAD Disaster Spiritual Care
                Guidelines
              </li>
            </ol>
          </span>
        </a>
      </div>
    </header>
    <section className="self-stretch flex flex-col items-start justify-start gap-1.5 text-left text-sm text-darkslateblue font-raleway">
      <b className="relative uppercase">Long-Term Recovery Group</b>
      <div className="self-stretch rounded-tl-none rounded-tr-8xs rounded-b-8xs flex flex-col items-start justify-start py-0 px-0">
        <div className="self-stretch rounded-tl-none rounded-tr-mini rounded-b-mini bg-white border-white border-[1px] border-solid flex flex-col items-start justify-start py-[18px] px-4">
          <a
  href="https://www.co.lincoln.or.us/DocumentCenter/View/2793/FAQ---Long-Term-Recovery-Group-PDF"
  target="_blank"
  rel="noopener noreferrer"
            className="underline"
          >
            Long Term Recovery Group (LTRG) Frequently Asked Questions
          </a>
        </div>
      </div>
    </section>

    <b className="relative uppercase">Blue Sky Dictionary</b>
<section className="self-stretch rounded-tl-none rounded-tr-mini rounded-b-mini bg-white border-white border-[1px] border-solid flex flex-col items-start justify-start py-[23px] px-4 gap-2.5 text-left text-sm text-darkslategray font-raleway mq450:pt-5 mq450:pb-5 mq450:box-border">
<DictionaryItem term="FEMA" definition="Federal Emergency Management Agency" />
<DictionaryItem term="EM" definition="Emergency Management: State of local emergency management agency" />
<DictionaryItem 
term="LTRG" 
definition="Long Term Recovery Group: Before FEMA leaves, localities are required to establish a Long-Term Recovery Group (LTRG) if one does not already exist. LTRG's are independent of FEMA, American Red Cross or any other federal agency, and exist to help meet the unmet disaster recovery needs of the community and individuals. National VOAD defines a long term recovery group as 'A long term recovery group (LTRG) is a cooperative body that is made up of representatives from faith-based, non-profit, government, business and other organizations working within a community to assist individuals and families as they recover from disaster.'" 
/>
<DictionaryItem 
term="VOAD" 
definition="Volunteer Organizations Active in Disaster: An association of organizations (which can include national, state, and local volunteer organizations) that mitigate and reduce the impact of disasters." 
/>
<DictionaryItem term="NVOAD" definition="National Volunteer Organizations Active in Disaster" />
<DictionaryItem 
term="DCM" 
definition="Disaster Case Management is a time-limited collaboration between a trained case manager and a disaster survivor involving the development of a disaster recovery plan and a mutual effort to meet those disaster-caused unmet needs described in the plan. FEMA Disaster Case Management" 
/>
<DictionaryItem 
term="UN" 
definition="Unmet needs are defined by the need for goods or services by those significantly impacted by disaster that are not covered by personal means, insurance, or existing assistance programs." 
/>
<DictionaryItem 
term="CDBG-DR" 
definition="Community Development Block Grant Disaster Recovery (CDBG-DR) grant funds are appropriated by Congress and allocated by HUD to rebuild disaster-impacted areas and provide crucial seed money to start the long-term recovery process. These flexible grants local governments and States recover from Presidentially declared disasters, especially in low-income areas, subject to availability of supplemental appropriations. Since CDBG-DR assistance may fund a broad range of recovery activities, HUD can help communities and neighborhoods that otherwise might not recover due to limited resources. It could take 2-4 years after the disaster for CDBG-DR funds to arrive in the impacted community. HUD" 
/>
<DictionaryItem 
term="DR" 
definition="Disaster Recovery: The process that occurs after a disaster impacts a community. This is commonly divided into two categories, Immediate Response which occurs in the days and weeks following the disaster and Long-Term Recovery which occurs during the months and years following the disaster." 
/>
<DictionaryItem term="Victim" definition="A citizen who perishes as a result of the disaster." />
<DictionaryItem 
term="Survivor" 
definition="A citizen who survives the disaster but is impacted and needs immediate and/or long-term assistance." 
/>
<DictionaryItem 
term="Blue Sky" 
definition="Non-disaster periods for communities and citizens in which they should develop mitigation and preparedness plans." 
/>
<DictionaryItem 
term="Grey Sky" 
definition="Post-disaster periods for communities and citizens in which they participate in immediate response and long-term recovery." 
/>
</section>
<b className="relative uppercase mb-4">DISASTER RELATED QUESTIONS</b>
<section className="self-stretch rounded-tl-none rounded-tr-mini rounded-b-mini bg-white border-white border-[1px] border-solid flex flex-col items-start justify-start py-[23px] px-4 gap-2.5 text-left text-sm text-darkslategray font-raleway mq725:pt-5 mq725:pb-5 mq725:box-border">
<QuestionItem 
question="What are the stages of Disaster Recovery?" 
answer="Disasters are viewed as recurring events with four phases: Mitigation, Preparedness, Response, and Recovery.

Mitigation: This phase includes actions taken to prevent or reduce the cause, impact, and consequences of disasters. Examples include tying down homes with ground anchors, digging water channels, constructing levees, reinforcing fencing, and buying insurance policies.

Preparedness: This phase includes planning, training, and educational activities for events that cannot be mitigated. Examples include developing disaster preparedness plans, conducting drills and exercises, creating supply lists, and identifying vulnerabilities.

Response: This phase occurs in the immediate aftermath of a disaster. Activities include implementing disaster response plans, conducting search and rescue missions, taking actions to protect yourself and others, and addressing public perceptions about food safety.

Recovery: During this period, restoration efforts occur concurrently with regular operations. Activities include preventing stress-related illnesses, rebuilding damaged structures, and reducing vulnerability to future disasters.

For more information, visit FEMA Training: https://training.fema.gov/emiweb/downloads/is111_unit%204.pdf"
/>
<QuestionItem 
question="What is the difference between a declared and undeclared disaster?" 
answer="A disaster always begins and ends at the local level. The main difference lies in the availability of federal assistance:

Undeclared disasters: No outside assistance is available for individuals. Local partners and non-profit organizations address recovery needs.

Federally declared disasters: Additional resources become available for disaster survivors, businesses, and public entities. Individuals can apply for FEMA assistance.

A major disaster declaration puts into motion long-term federal recovery programs designed to help disaster survivors, businesses, and public entities.

For more information, visit Recover Montgomery County, TX: https://www.mctx.org/departments/departments_q_-_z/recover/declared_vs_non-_declared_disasters.php"
/>
<QuestionItem 
question="What assistance is provided by FEMA?" 
answer="If a disaster is federally declared, FEMA may provide:
- Grants for temporary housing and emergency home repairs
- Assistance for uninsured and underinsured personal property losses
- Grants for medical, dental, and funeral expenses caused by the disaster
- Other serious disaster-related expenses

The U.S. Small Business Administration (SBA) offers low-interest disaster loans to businesses, homeowners, and renters.

Eligibility requirements include being a U.S. citizen, Non-Citizen National, or Qualified Alien, and having disaster-caused needs not covered by insurance or other forms of assistance.

For more details, visit FEMA's FAQ page: https://www.fema.gov/press-release/20210318/fact-sheet-frequently-asked-questions-about-fema-individual-assistance"
/>
<QuestionItem 
question="What assistance is provided by the American Red Cross?" 
answer="The American Red Cross provides various forms of assistance during and after disasters:

During initial relief:
- Safe shelter, food, and relief supplies
- Health, mental health, and spiritual support
- Damage assessment

During recovery:
- Distribution of meals, snacks, and relief items
- Assistance with housing arrangements
- Caseworkers to help create individual recovery plans
- Financial assistance in the immediate aftermath and long-term
- Grants for community-based recovery services

The Red Cross works with partners to help communities prepare for, respond to, and recover from disasters.

For more information, visit:
https://www.redcross.org/about-us/news-and-events/news/2022/what-services-does-the-red-cross-provide-during-a-disaster.html
https://www.redcross.org/about-us/our-work/disaster-relief.html"
/>
<QuestionItem 
question="What is Community Led Recovery?" 
answer="Community-led recovery is an approach that gives communities greater involvement in decisions and processes that contribute to rebuilding their lives. Key aspects include:

- Participation from the community in decision-making
- Consideration of community values, culture, and priorities
- Use of existing community strengths, leadership, and networks
- Flexibility to support different paths to recovery
- Building strong partnerships between communities and recovery partners

Principles of community-led recovery include understanding the context, effective communication, strengthening communities, ensuring inclusivity, recognizing complexity, building capacity, using community-led approaches, and coordinating activities.

For more details, visit the Community Recovery Toolkit: https://www.vic.gov.au/community-recovery-toolkit-0/what-ervs-role-community-recovery"
/>
<QuestionItem 
question="What is a long term recovery group?" 
answer="A Long-Term Recovery Group (LTRG) is a cooperative body made up of representatives from faith-based, non-profit, government, business, and other organizations working within a community to assist individuals and families as they recover from disaster.

LTRGs are independent of FEMA, American Red Cross, or any other federal agency. They exist to help meet the unmet disaster recovery needs of the community and individuals. Each LTRG is unique and reflects local needs, available resources, cultural diversity, leadership style, and community support.

The goal of an LTRG is to unite recovery resources with community needs to ensure that even the most vulnerable in the community recover from disaster."
/>
<QuestionItem 
question="What are VOADs?" 
answer="VOADs (Voluntary Organizations Active in Disaster) are groups of organizations that mitigate and alleviate the impact of disasters by coordinating with each other and government agencies. They foster effective delivery of services to communities affected by disasters through:

- Cooperation
- Communication
- Coordination
- Collaboration

VOADs provide many services during response and recovery phases, including:
- Debris removal
- Construction estimation and repairs
- Family reunification
- Food and shelter assistance
- Pet care
- Disaster casework
- Mental health and psychological first aid
- Volunteer management
- Long-term recovery services
- Training for preparedness and disaster planning

VOADs are activated by state or local emergency management agencies to address the scope of the emergency and the needs of the affected area."
/>
<QuestionItem 
question="What will my insurance cover?" 
answer="Home insurance policies provide an important layer of protection for your property, but the hazard insurance covering your property may not have the coverage you need if your community is hit by a natural disaster. For additional coverage, you may need to pay additional costs or take out a separate policy.

It is crucial to review your policy carefully and make sure you understand exactly what is or is not covered. This will allow you to make a more informed decision about whether additional coverages are right for your situation.

DO NOT ASSUME YOUR HOME AND CONTENTS ARE COVERED. Speak to your agent.

If you are a renter, please speak with your insurance agent about renters insurance."
/>
<QuestionItem 
question="How long does recovery take?" 
answer="The duration of recovery can vary greatly:

- Damage and loss may be immediate, but the impact can last for a long time.
- The effect of the disaster can be immediate and localized but is often widespread and could last for a long period.
- For individuals, recovery can take a few months to several years, depending on the size of the disaster and available resources.
- For communities, recovery can take a few years to a decade.

It's important to note that while the immediate effects might be visible quickly, the full process of recovery, especially for larger disasters, can be a long-term endeavor requiring sustained effort and resources."
/>
</section>
  </main>
</div>;
};

export default QuickInfo;
