import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../components/AuthContext";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import note from "../../src/assets/note.png";
import check from "../../src/assets/check.png";
import umbrella from "../../src/assets/umbrella.png";
import lightning from "../../src/assets/lightning.png";
import fire from "../../src/assets/fire.png";

const ForgotPassword = () => {
  return (
    <div className="flex h-screen w-full justify-center bg-white font-raleway ">
      <div
        className=" roudned-bl-[10px] my-auto flex h-[594px] w-[478px] rounded-tl-[10px] bg-white px-[45px] py-[70px]"
        style={{
          border: "1px solid var(--Text-Locked, #D5D7DB)",
          background: "var(--Pure-White, #FFF)",
          boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="flex flex-col text-center">
          <img
            src={blueSkyLogo}
            className="mx-auto h-[141.942px] w-[134.703px]"
          ></img>
          <p className="mt-[10px] w-[350px] font-bold text-[#348BFF]">
            DARCI, powered by Blue Sky
          </p>
          <p className="my-[30px] text-start text-base font-medium leading-[28px] text-[#3D435A]">
            Enter your email and we'll send you a link to reset your password
          </p>
          <input
            placeholder="Email Address"
            type="text"
            className="h-[40px] border border-[#D5D7DB] p-[10px] focus:outline-none"
          />
          <div className="mt-[30px] flex flex-col gap-[10px]">
            <button className="h-10 w-full rounded-[4px] bg-[#348BFF] p-[10px] text-base font-bold text-white hover:cursor-pointer">
              Submit
            </button>
            <Link
              to="/login"
              className="text-base font-normal tracking-[0.24px] text-[#348BFF] no-underline hover:cursor-pointer"
            >
              {" "}
              Back to login
            </Link>
          </div>
        </div>
      </div>
      <div
        className="relative my-auto ml-[-5px] h-[594px] w-[288.195px] rounded-br-[10px] rounded-tr-[10px] bg-[#348BFF]"
        style={{
          border: "1px solid var(--Text-Locked, #D5D7DB)",
          boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <img src={note} className="absolute right-0 top-[-80px]"></img>
        <img src={check} className="absolute left-[-40px] top-[80px]"></img>
        <img src={umbrella} className="absolute right-0 top-[250px]"></img>
        <img
          src={lightning}
          className="absolute left-[-40px] top-[370px]"
        ></img>
        <img src={fire} className="absolute right-0 top-[470px]"></img>
      </div>
    </div>
  );
};

export default ForgotPassword;
