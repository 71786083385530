import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext';
import Profile from './Profile';
import Password from './Password';
import Pin from './Pin';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const Menu = ({ isOpen, onClose, initialData }) => {
    const [userData, setUserData] = useState(initialData || {});
    const [showProfile, setShowProfile] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const menuRef = useRef(null);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();

        const fetchUserData = async (user) => {
            if (user) {
                try {
                    const userDocRef = doc(db, 'users', user.uid);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        setUserData(userData);
                        setUserRole(userData.user_role);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUserData(user);
            } else {
                setUserData({});
                setUserRole(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };
    
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    useEffect(() => {
        if (isOpen || showProfile || showPassword || showPin) {
            const fetchUserData = async () => {
                const auth = getAuth();
                const db = getFirestore();
                const user = auth.currentUser;
    
                if (user) {
                    try {
                        const userDocRef = doc(db, 'users', user.uid);
                        const userDocSnap = await getDoc(userDocRef);
                        if (userDocSnap.exists()) {
                            setUserData(userDocSnap.data());
                        }
                    } catch (error) {
                        console.error('Error fetching user data:', error);
                    }
                }
            };
    
            fetchUserData();
        }
    }, [isOpen, showProfile, showPassword, showPin]);

    if (!isOpen && !showProfile && !showPassword && !showPin) return null;

    const handleProfileUpdate = (updatedData) => {
        setUserData(updatedData);
    };

    const handleProfileClick = () => {
        onClose(); // Close the Menu popup
        setTimeout(() => setShowProfile(true), 100); // Open the Profile popup after a short delay
    };

    const handlePasswordClick = () => {
        onClose(); // Close the Menu popup
        setTimeout(() => setShowPassword(true), 100); // Open the Password popup after a short delay
    };

    const handlePinClick = () => {
        onClose(); // Close the Menu popup
        setTimeout(() => setShowPin(true), 100); // Open the Pin popup after a short delay
    };

    const logoutClicked = () => {
        logout();
        navigate("/login");
        onClose(); // Close the menu after logging out
    };

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-end items-start pt-12">
                    <div ref={menuRef} className="bg-white w-[400px] rounded-lg shadow-xl mr-2 overflow-hidden">
                        <div>
                            <h2 className="pt-6 pb-6 px-4 text-base font-semibold bg-blue-500 text-white font-raleway">My Profile</h2>
                        </div>
                        <div className="flex items-center justify-center p-4 border-b">
                            {userData.profile_image ? (
                                <img src={userData.profile_image} alt="Profile" className="w-16 h-16 rounded-full mr-4" />
                            ) : (
                                <div className="w-16 h-16 rounded-full mr-4 bg-blue-500 flex items-center justify-center text-white text-2xl font-semibold">
                                    {userData.first_name ? userData.first_name.charAt(0).toUpperCase() : '?'}
                                </div>
                            )}
                            <div className="flex flex-col items-center justify-center">
                                <h3 className="font-semibold text-lg text-black font-raleway pb-2">
                                    {userData.first_name ? `${userData.first_name} ${userData.last_name || ''}` : "User Name"}
                                </h3>
                                <p className="text-sm text-gray-800 font-raleway">{userData.email || "user@example.com"}</p>
                            </div>
                        </div>
                        <nav className="p-4">
                            <ul className="space-y-2">
                                <li>
                                    <button 
                                        onClick={handleProfileClick}
                                        className="block w-full text-left py-2 px-4 text-[20px] font-raleway font-semibold bg-white text-gray-800 hover:bg-gray-200 cursor-pointer rounded"
                                    >
                                        Profile
                                    </button>
                                </li>
                                <hr className="border-t-[1px] border-gray-200 my-2 mx-4"/>
                                <li>
                                    <button 
                                        onClick={handlePasswordClick}
                                        className="block w-full text-left py-2 px-4 text-[20px] font-raleway font-semibold bg-white text-gray-800 hover:bg-gray-200 cursor-pointer rounded"
                                    >
                                        Change Password
                                    </button>
                                </li>
                                <hr className="border-t-[1px] border-gray-200 my-2 mx-4"/>
                                {(userRole === 'citizen' || userRole === 'partner') && (
                                    <div>
                                        <li>
                                            <button 
                                                onClick={handlePinClick}
                                                className="block w-full text-left py-2 px-4 text-[20px] font-raleway font-semibold bg-white text-gray-800 hover:bg-gray-200 cursor-pointer rounded"
                                            >
                                                Change Pin
                                            </button>
                                            
                                        </li>
                                        <hr className="border-t-[1px] border-gray-200 my-2 mx-4"/>
                                    </div>
                                )}
                                
                            </ul>
                            <div className="flex justify-center items-center pt-4">
                                <button 
                                    onClick={logoutClicked}
                                    className="block py-2 px-4 text-[14px] font-raleway font-semibold bg-blue-500 text-white hover:bg-blue-700 rounded-2xl cursor-pointer"
                                >
                                    Sign Out
                                </button>
                            </div>
                        </nav>
                    </div>
                </div>
            )}
            {showProfile && userData && (
                <Profile 
                    isOpen={showProfile} 
                    onClose={() => setShowProfile(false)} 
                    data={userData} 
                    onUpdate={handleProfileUpdate}
                />
            )}
            {showPassword && (
                <Password isOpen={showPassword} onClose={() => setShowPassword(false)} />
            )}
            {(userRole === 'citizen' || userRole === 'partner') && showPin && (
                <Pin isOpen={showPin} onClose={() => setShowPin(false)} />
            )}
        </>
    );
};

export default Menu;