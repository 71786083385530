import React, { useState } from "react";
import {
  AccumulationChartComponent,
  AccumulationDataLabel,
  AccumulationLegend,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTooltip,
  Inject,
  PieSeries,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  StackingColumnSeries,
  Category,
  Legend,
  Tooltip,
  DataLabel,
  Highlight,
  AccumulationSelection,
  AccumulationAnnotation,
  ChartAnnotation,
  AccumulationAnnotationDirective,
  AccumulationAnnotationsDirective,
  ColumnSeries,
  BarSeries,
} from "@syncfusion/ej2-react-charts";

const Doughnut = ({ title, total, totalorder }) => {
  const [chartData, setChartData] = useState([
    { x: "Chrome", y: 50, text: "50%" },
    { x: "Safari", y: 20, text: "20%" },
    { x: "QQ", y: 30, text: "30%" },
  ]);
  return (
    <div className="flex h-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="text-[32px] text-[#165BAA]">
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total)}
        </p>
        <p>{totalorder} Orders</p>
      </div>
      <div className="flex-1 pt-2">
        <AccumulationChartComponent
          id={title}
          legendSettings={{
            visible: true,
            toggleVisibility: false,
            position: "Right",
            height: "50%",
            width: "20%",
            textWrap: "Wrap",
            maximumLabelWidth: 66,
          }}
          enableSmartLabels={true}
          enableAnimation={false}
          selectionMode={"Point"}
          center={{ x: "50%", y: "50%" }}
          enableBorderOnMouseMove={false}
          tooltip={{
            enable: true,
            header: "",
          }}
          height="230px"
        >
          <Inject
            services={[
              AccumulationLegend,
              PieSeries,
              AccumulationDataLabel,
              AccumulationTooltip,
              AccumulationSelection,
              ChartAnnotation,
              AccumulationAnnotation,
            ]}
          />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y"
              explode={false}
              explodeOffset="10%"
              explodeIndex={0}
              startAngle={30}
              innerRadius="43%"
              dataLabel={{
                visible: true,
                position: "Inside",
                name: "text",
                font: { fontWeight: "600", color: "#ffffff" },
                connectorStyle: { length: "20px", type: "Curve" },
              }}
              radius="80%"
            />
          </AccumulationSeriesCollectionDirective>
          <AccumulationAnnotationsDirective>
            <AccumulationAnnotationDirective
              content={""}
              region="Series"
              x="52%"
              y="50%"
            />
          </AccumulationAnnotationsDirective>
        </AccumulationChartComponent>
      </div>
    </div>
  );
};

const StackingChart = ({ title, total, totalorder }) => {
  const [ilcData, setIlcData] = useState([
    { x: "2013", y: 9628912, y1: 4298390, y2: 2842133, y3: 2006366 },
    { x: "2014", y: 9609326, y1: 4513769, y2: 3016710, y3: 2165566 },
    { x: "2015", y: 7485587, y1: 4543838, y2: 3034081, y3: 2279503 },
    { x: "2016", y: 7793066, y1: 4999266, y2: 2945295, y3: 2359756 },
    { x: "2017", y: 6856880, y1: 5235842, y2: 3302336, y3: 2505741 },
  ]);

  return (
    <div className="flex h-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="text-[32px] text-[#165BAA]">
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total)}
        </p>
        <p>{totalorder} Orders</p>
      </div>
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          style={{ textAlign: "center" }}
          legendSettings={{ enableHighlight: true }}
          primaryXAxis={{
            valueType: "Category",
            labelIntersectAction: "Rotate45",
            majorGridLines: { width: 0 },
            minorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
          primaryYAxis={{
            rangePadding: "None",
            interval: 20,
            majorTickLines: { width: 0 },
            majorGridLines: { width: 1 },
            minorGridLines: { width: 1 },
            minorTickLines: { width: 0 },
            lineStyle: { width: 0 },
          }}
          width={"100%"}
          height="250px"
          chartArea={{ border: { width: 0 } }}
          tooltip={{
            enable: true,
          }}
        >
          <Inject
            services={[
              StackingColumnSeries,
              Legend,
              Tooltip,
              DataLabel,
              Category,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={ilcData}
              xName="x"
              yName="y"
              name="General Motors"
              columnWidth={0.5}
              border={{ width: 1, color: "white" }}
              type="StackingColumn100"
            />{" "}
            <SeriesDirective
              dataSource={ilcData}
              xName="x"
              yName="y1"
              name="Honda"
              columnWidth={0.5}
              border={{ width: 1, color: "white" }}
              type="StackingColumn100"
            />
            <SeriesDirective
              dataSource={ilcData}
              xName="x"
              yName="y2"
              name="Suzuki"
              columnWidth={0.5}
              border={{ width: 1, color: "white" }}
              type="StackingColumn100"
            />
            <SeriesDirective
              dataSource={ilcData}
              xName="x"
              yName="y3"
              name="BMW"
              columnWidth={0.5}
              border={{ width: 1, color: "white" }}
              type="StackingColumn100"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

const ColumnChart = ({ title, total, totalorder }) => {
  const [columnChartData, setColumnChartData] = useState([
    { x: "27", y: 27, toolTipMappingName: "27" },
    { x: "26", y: 26, toolTipMappingName: "26" },
    { x: "8", y: 8, toolTipMappingName: "8" },
    { x: "19", y: 19, toolTipMappingName: "19" },
    { x: "17", y: 17, toolTipMappingName: "17" },
  ]);

  return (
    <div className="flex h-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="text-[32px] text-[#165BAA]">
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total)}
        </p>
        <p>{totalorder} Orders</p>
      </div>
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          style={{ textAlign: "center" }}
          legendSettings={{ enableHighlight: true }}
          primaryXAxis={{
            labelIntersectAction: "None",
            labelRotation: 0,
            valueType: "Category",
            interval: 1,
            majorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
          }}
          primaryYAxis={{
            title: "Medal Count",
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            maximum: 50,
            interval: 10,
          }}
          chartArea={{ border: { width: 0 } }}
          tooltip={{
            enable: true,
            shared: true,
          }}
          width="100%"
          height="230px"
        >
          <Inject
            services={[
              ColumnSeries,
              Legend,
              Tooltip,
              Category,
              DataLabel,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={columnChartData}
              tooltipMappingName="toolTipMappingName"
              xName="x"
              columnSpacing={0.1}
              yName="y"
              name="Gold"
              type="Column"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

const BarChart = ({ title, total, totalorder }) => {
  const [barChartData, setBarChartData] = useState([
    { x: "Japan", y: 1.71 },
    { x: "France", y: 1.82 },
    { x: "India", y: 6.68 },
    { x: "Germany", y: 2.22 },
    { x: "Italy", y: 1.5 },
    { x: "Canada", y: 3.05 },
  ]);
  return (
    <div className="flex h-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="text-[32px] text-[#165BAA]">
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total)}
        </p>
        <p>{totalorder} Orders</p>
      </div>
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          style={{ textAlign: "center" }}
          legendSettings={{ enableHighlight: true }}
          primaryXAxis={{ valueType: "Category", majorGridLines: { width: 0 } }}
          primaryYAxis={{
            labelFormat: "{value}%",
            edgeLabelPlacement: "Shift",
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
          }}
          chartArea={{ border: { width: 0 } }}
          width="100%"
          height="230px"
          tooltip={{ enable: true }}
        >
          <Inject
            services={[
              BarSeries,
              DataLabel,
              Category,
              Legend,
              Tooltip,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={barChartData}
              xName="x"
              yName="y"
              type="Bar"
              columnSpacing={0.1}
              name="GDP"
              width={2}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

const Demographics = () => {
  return (
    <div className="relative flex h-full flex-col space-y-2 py-5 pl-3 pr-5 font-raleway">
      <div className="flex items-center space-x-2">
        <p className="text-[12px]">Recovery Plan</p>
        <p>/</p>
        <p className="text-[12px]">Demographics</p>
      </div>
      <div>
            <h2 className="mb-1 mt-1 font-raleway text-sm font-bold text-blue-500">
              Demographics
            </h2>
          </div>
      <div className="flex flex-col space-y-2 rounded-3xs border-[1px] border-solid border-[#C9C9C9] p-[15px]">
        <div className="grid grid-cols-4 gap-x-2 gap-y-2">
          <Doughnut title="Gender" total={5000} totalorder={50} />
          <ColumnChart title="Age" total={5000} totalorder={50} />
          <StackingChart title="Income Levels" total={5000} totalorder={50} />
          <Doughnut
            title="Insured vs Non-Insured"
            total={5000}
            totalorder={50}
          />
          <Doughnut title="Disability" total={5000} totalorder={50} />
          <Doughnut
            title="Military in Household"
            total={5000}
            totalorder={50}
          />
          <Doughnut
            title="Homeowners vs Renters"
            total={5000}
            totalorder={50}
          />
          <BarChart title="Educational Attained" total={5000} totalorder={50} />
          <BarChart title="Ethnicity" total={5000} totalorder={50} />
        </div>
      </div>
    </div>
  );
};
export default Demographics;
