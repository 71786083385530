import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  actionBegin,
} from "@syncfusion/ej2-react-grids";
import { Query } from "@syncfusion/ej2-data";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import DisasterMgtService from "../../service/citizen/DisasterMgtService";

const sortSettings = {
  allowSorting: true,
};

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
};

const filterSettings = {
  type: "Menu",
};

const DisasterManagement = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [generalData, setGeneralData] = useState([]);
  const [homeRepairData, setHomeRepairData] = useState([]);
  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
        console.log("userid is", currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });
        console.log("  sad", data);

        const general = await DisasterMgtService.fetchRecords(
          userId,
          "general"
        );

        const home = await DisasterMgtService.fetchRecords(
          userId,
          "home_repair"
        );
        setGeneralData(general.filter(item => !item.is_deleted));
        setHomeRepairData(home.filter(item => !item.is_deleted));
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  let elem;
  let uploadObj;
  let strm;
  let selectedRow;

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  // State for General section

  const [isGeneralVisible, setIsGeneralVisible] = useState(true);
  const [lastGeneralEditedTime, setLasGeneralEditedTime] = useState(null);

  const toggleGeneral = (event) => {
    event.preventDefault();
    setIsGeneralVisible(!isGeneralVisible);
  };

  const generalGridRef = useRef(null);

  const handleAddClickGeneral = () => {
    if (generalGridRef.current) {
      generalGridRef.current.addRecord();
    }
  };

  function compressImage(file, maxSizeMB) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxWidth = img.width;
          const maxHeight = img.height;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.floor((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.floor((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.9; // Start with high quality
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
            quality -= 0.1;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          resolve(dataUrl);
        };

        img.onerror = function (error) {
          reject(error);
        };
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  // Upload image to Firebase Storage
  async function uploadImageToStorage(filePath, dataUrl) {
    const storage = getStorage();
    const storageRef = ref(storage, filePath);

    await uploadString(storageRef, dataUrl, "data_url");

    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  }

  function gridTemplate(props) {
    const src = props.photoGeneral; // Get the image path from the data source

    return (
      <div className="flex flex-col items-center">
        {src ? (
          <img
            src={src}
            alt={props.generalID}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <div
            className="flex flex-col items-center justify-center"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              border: "1px solid #ddd",
            }}
          >
            <p>No Image</p>
          </div>
        )}
      </div>
    );
  }

  const template = gridTemplate;
  const imageEditGeneral = {
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
        <div class="flex flex-col justify-center w-full">
            <label htmlFor="access" class="block mb-2 text-xs text-gray-500">Photo Attachment</label>
            <div class="flex flex-col items-center">
                <div id="imagePreview" class="mb-4 flex flex-col items-center justify-center">
                    <!-- Image will be dynamically inserted here -->
                </div>
                <div id="dropArea">
                    <label class="upload-button" id="uploadLabel">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="17 8 12 3 7 8"></polyline>
                            <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                        Upload Image
                    </label>
                    <input type="file" id="defaultUpload" style="display:none;" />
                </div>
            </div>
        </div>
      `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagePreview = elem.querySelector("#imagePreview");

      // If there's an existing image, update the button text to "Reupload Image"
      if (args.rowData.photoGeneral) {
        uploadLabel.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="17 8 12 3 7 8"></polyline>
          <line x1="12" y1="3" x2="12" y2="15"></line>
        </svg>
        Reupload Image
      `;
        strm = args.rowData.photoGeneral;
        displayUploadedImage(args.rowData.photoGeneral, "Current Image");
      } else {
        imagePreview.innerHTML = "";
      }

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        multiple: false,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);

            const filePath = `disaster-mgt/general/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );
            strm = downloadURL;
            console.log("Image uploaded successfully:", downloadURL);

            displayUploadedImage(downloadURL, args.file.name);
          } catch (error) {
            console.error("Error during upload:", error);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function displayUploadedImage(imageSrc, fileName) {
        imagePreview.innerHTML = `
                    <img src="${imageSrc}" alt="Uploaded Image" style="width: 100px; height: 100px; border-radius: 50%; object-fit: cover;" />
                    <p class="text-sm text-blue-500 mt-2">${fileName}</p>
                `;
      }

      // Display the current image if available
      if (args.rowData.photoGeneral) {
        strm = args.rowData.photoGeneral;
        displayUploadedImage(args.rowData.photoGeneral, "Current Image");
      } else {
        imagePreview.innerHTML = "";
      }
    },
  };

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedData[key] = data[key] || "";
      }
    }
    return sanitizedData;
  };

  const actionBeginGeneral = async (args) => {
    if (args.requestType === "save" && args.action === "add") {
      args.data.generalID = generateUniqueId();
      args.data.category = "General";
      args.data.sub_category = args.data.sub_category;
      args.data.is_deleted = false;
  
      const sanitizedData = sanitizeData(args.data);
      await DisasterMgtService.addRecord(userId, sanitizedData, "general");
      console.log("Add:", args.data);
    }
    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }
  
    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.generalID;
          const fileUrl = docData.photoGeneral;
  
          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);
  
            await deleteObject(fileRef);
            console.log("File deleted from storage:", fileUrl);
          }
  
          console.log("Attempting to update document with ID:", documentId);
  
          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(userId, documentId, { is_deleted: true }, "general");
          console.log("Document marked as deleted:", documentId);
        }
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteGeneral = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        console.log("Editing:", selectedRow);
        const documentId = selectedRow.generalID;
  
        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;
  
        await DisasterMgtService.updateRecord(
          userId,
          documentId,
          sanitizedData,
          "general"
        );
  
        console.log("Data saved successfully:", sanitizedData);
      }
      
      // Refresh the grid data
    try {
      // Assuming there's a method called getGeneral. Replace with the correct method name if different.
      const updatedData = await DisasterMgtService.getGeneral(userId);
      setGeneralData(updatedData);
    } catch (error) {
      console.error("Error fetching updated data:", error);
      // Optionally, you can add some user feedback here about the error
    }
  }
};

  //Home Repair

  const [isHomeRepairVisible, setIsHomeRepairVisible] = useState(true);
  const [lastHomeRepairEditedTime, setLastHomeRepairEditedTime] =
    useState(null);

  const toggleHomeRepair = (event) => {
    event.preventDefault();
    setIsHomeRepairVisible(!isHomeRepairVisible);
  };

  const homeRepairGridRef = useRef(null);

  const handleAddClickHomeRepair = () => {
    if (homeRepairGridRef.current) {
      homeRepairGridRef.current.addRecord();
    }
  };

  function gridRepairTemplate(props) {
    const src = props.photo_home_repair; // Get the image path from the data source

    return (
      <div className="flex flex-col items-center">
        {src ? (
          <img
            src={src}
            alt={props.homeRepairID}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <div
            className="flex flex-col items-center justify-center"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              border: "1px solid #ddd",
            }}
          >
            <p>No Image</p>
          </div>
        )}
      </div>
    );
  };

  const homeRepairTemplate = gridRepairTemplate;

  const homeRepairEdit = {
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
                <div class="flex flex-col justify-center w-full">
                    <label htmlFor="access" class="block mb-2 text-xs text-gray-500">Photo Attachment</label>
                    <div class="flex flex-col items-center">
                        <div id="imagePreview" class="mb-4 flex flex-col items-center justify-center">
                            <!-- Image will be dynamically inserted here -->
                        </div>
                        <div id="dropArea">
                            <label class="upload-button" id="uploadLabel">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline points="17 8 12 3 7 8"></polyline>
                                    <line x1="12" y1="3" x2="12" y2="15"></line>
                                </svg>
                                Upload Image
                            </label>
                            <input type="file" id="defaultUpload" style="display:none;" />
                        </div>
                    </div>
                </div>
            `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagePreview = elem.querySelector("#imagePreview");

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        multiple: false,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);

            const filePath = `disaster-mgt/home_repair/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );
            strm = downloadURL;
            console.log("Image uploaded successfully:", downloadURL);

            displayUploadedImage(downloadURL, args.file.name);
          } catch (error) {
            console.error("Error during upload:", error);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function displayUploadedImage(imageSrc, fileName) {
        imagePreview.innerHTML = `
                    <img src="${imageSrc}" alt="Uploaded Image" style="width: 100px; height: 100px; border-radius: 50%; object-fit: cover;" />
                    <p class="text-sm text-blue-500 mt-2">${fileName}</p>
                `;
      }

      // Display the current image if available
      if (args.rowData.photo_home_repair) {
        strm = args.rowData.photo_home_repair;
        displayUploadedImage(args.rowData.photo_home_repair, "Current Image");
      } else {
        imagePreview.innerHTML = "";
      }
    },
  };

  const actionBeginHomeRepair = async (args) => {
    if (args.requestType === "save" && args.action === "add") {
      args.data.homeRepairID = generateUniqueId();
      args.data.category = "Home Repair and Replacement"; // Set category to "Home Repair and Replacement"
      args.data.sub_category = args.data.sub_category; // Add sub_category to data
      args.data.is_deleted = false;

      const sanitizedData = sanitizeData(args.data);
      await DisasterMgtService.addRecord(userId, sanitizedData, "home_repair");
      console.log("Add:", args.data);
    }
    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.homeRepairID;
          const fileUrl = docData.photo_home_repair;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
            console.log("File deleted from storage:", fileUrl);
          }

          console.log("Attempting to delete document with ID:", documentId);

          await DisasterMgtService.updateRecord(userId, documentId, { is_deleted: true }, "home_repair");
          console.log("Document marked as deleted:", documentId);
        }
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const actionCompleteHomeRepair = async (args) => {
    if (args.requestType === "save" && args.action === "edit") {
      const updatedData = homeRepairData.map((item) =>
        item.homeRepairID === selectedRow.homeRepairID ? { ...args.data, is_deleted: false } : item
      );
      console.log("Data saved successfully:", args.data);
      setHomeRepairData(updatedData.filter(item => !item.is_deleted));
      
      // Update the record in the database
      await DisasterMgtService.updateRecord(
        userId,
        selectedRow.homeRepairID,
        { ...args.data, is_deleted: false },
        "home_repair"
      );
    }
  };

  return (
    <div
      className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start py-[15px] ${className}`}
    >
      <div className="w-full">
        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                General
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                Last Edited: {formatLastEditedTime(lastGeneralEditedTime)}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickGeneral}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleGeneral}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${isGeneralVisible ? "rotate-0" : "rotate-180"
                    }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isGeneralVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={generalGridRef}
                dataSource={generalData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginGeneral}
                actionComplete={actionCompleteGeneral}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="generalID"
                    headerText="generalID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="100"
                    visible={false}
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: [
                          "General debris removal",
                          "Tree removal",
                          "Others",
                          "Mold/Mildew remediation"
                        ],
                        fields: { text: "sub_category", value: "sub_category" },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="100"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="100"
                  />
                  <ColumnDirective
                    field="photoGeneral"
                    headerText="Photo Attachment"
                    width="100"
                    template={template}
                    edit={imageEditGeneral}
                  />
                  
                  <ColumnDirective
                    headerText="Actions"
                    width="120"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      {
                        type: "Delete",
                        buttonOption: {
                          content: '<i class="fas fa-trash-alt"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
            </div>
          )}
        </section>

        <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
                Home Repair and Replacement
              </h2>
              <p className="font-poppins text-xs font-medium text-gray-500">
                Last Edited: {formatLastEditedTime(lastHomeRepairEditedTime)}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickHomeRepair}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>

              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleHomeRepair}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${isHomeRepairVisible ? "rotate-0" : "rotate-180"
                    }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </header>
          {isHomeRepairVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={homeRepairGridRef}
                dataSource={homeRepairData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                actionBegin={actionBeginHomeRepair}
                actionComplete={actionCompleteHomeRepair}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="homeRepairID"
                    headerText="homeRepairID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    width="100"
                    visible={false} // Keep this hidden
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="150"
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: [
                          "Building permits", "Design and planning", "Demolition/Gutting", "Carpentry", "Windows", "HVAC", "Plumbing", "Insulation", "Drywall hanging", "Drywall finishing", "Trim", "Doors", "Cabinets", "Flooring", "Ceiling", "Interiors paint", "Exterior siding/Paint", "Water hook up/Well", "Utilities hook up", "Bathrooms", "Access ramp", "Porch", "Deck", "Shed", "Garage", "Others"
                        ],
                        fields: { text: "sub_category", value: "sub_category" },
                        query: new Query(),
                      },  
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description of Damage"
                    width="100"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="100"
                  />
                  <ColumnDirective
                    field="photo_home_repair"
                    headerText="Photo Attachment"
                    width="100"
                    template={homeRepairTemplate}
                    edit={homeRepairEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    width="120"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      {
                        type: "Delete",
                        buttonOption: {
                          content: '<i class="fas fa-trash-alt"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default DisasterManagement;
