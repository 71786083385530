import React, { useState, useRef } from "react";

import PropTypes from "prop-types";
import { useDisasterContext } from "../pages/Disasters/DisasterContext";
import DisasterManagement from "./Disasters/DisasterManagement";
import AssistanceLog from "./Disasters/AssistanceLog";
import RecoveryPlanDisasters from "./Disasters/RecoveryPlan";
import Journals from "./Disasters/Journals";
import ContactsDisaster from "./Disasters/ContactsDisaster";
import ImmediateResponseSurvey from "./Disasters/ImmediateResponseSurvey";

const Disasters = ({ className = "" }) => {
  const [activeTab, setActiveTab] = useState("Immediate Response Survey");
  const { activeDisasterTab, setActiveDisasterTab } = useDisasterContext();
  const [selectedDisaster, setSelectedDisaster] = useState("Arizona Fire");
  const tabs = [
    "Immediate Response Survey",
    "Damage Assessment",
    "Assistance Log",
    "Recovery Plan",
    "Contacts",
    "Journals",
  ];
  const handleTabClick = (tab) => {
    setActiveDisasterTab(tab);
  };

  return (
    <div
      className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start px-2.5 py-[15px] ${className}`}
    >
      <div className="mb-4 flex flex-row items-center justify-start">
        <div className="flex flex-row items-start justify-start gap-1.5 font-raleway text-xs text-darkslateblue">
          <a className="relative font-medium leading-[16px] text-[inherit] [text-decoration:none]">
            Recovery Plan
          </a>
          <div className="relative font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative font-medium leading-[16px] text-[inherit] [text-decoration:none]">
            Disasters
          </a>
          <div className="relative font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <span className="relative font-medium leading-[16px]">
            {activeDisasterTab}
          </span>
        </div>
      </div>

      {/* Disaster selector and tabs */}
      <div className="flex max-w-full flex-row items-center justify-between gap-5 self-stretch mq1600:flex-wrap">
        {/* Disaster selector */}
        <div className="flex items-center gap-2">
          <span className="font-raleway text-sm font-bold text-blue-500">
            DISASTERS
          </span>
          <select
            value={selectedDisaster}
            onChange={(e) => setSelectedDisaster(e.target.value)}
            className="rounded border border-dodgerblue px-2 py-1 text-sm"
          >
            <option value="Arizona Fire">Arizona Fire</option>
            {/* Add more disaster options as needed */}
          </select>
        </div>

        {/* Tabs */}
        <div className="flex flex-wrap gap-2 text-xs">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`rounded-full px-4 py-1 ${
                activeDisasterTab === tab
                  ? "bg-dodgerblue text-white"
                  : "border border-dodgerblue text-dodgerblue hover:bg-blue-100"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className="relative mt-4 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        <p className="m-0 text-sm font-medium">
          Below are the essential tools at your disposal to effectively track
          your disaster recovery journey.
        </p>
        <p className="m-0 text-sm font-medium">
          Utilize the Damage Assessment tool to record losses, document
          assistance received in the Assistance Log, and track your progress
          towards recovery by monitoring your remaining deficit in the Recovery
          Plan.
        </p>
      </div>

      {activeDisasterTab === "Immediate Response Survey" && (
        <ImmediateResponseSurvey />
      )}
      {activeDisasterTab === "Damage Assessment" && <DisasterManagement />}
      {activeDisasterTab === "Assistance Log" && <AssistanceLog />}
      {activeDisasterTab === "Recovery Plan" && <RecoveryPlanDisasters />}
      {activeDisasterTab === "Contacts" && <ContactsDisaster />}
      {activeDisasterTab === "Journals" && <Journals />}
    </div>
  );
};

ImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default Disasters;
