import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown, FaPlus } from 'react-icons/fa';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Toolbar, Filter, Edit, Page, Sort, Inject, actionBegin } from "@syncfusion/ej2-react-grids";
import { UploaderComponent, Uploader } from '@syncfusion/ej2-react-inputs';

import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { db, auth } from "../../firebase";
import {
    collection,
    getDocs,
    getDoc,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
    getStorage,
    ref,
    uploadString,
    getDownloadURL,
    deleteObject,
} from "firebase/storage";
import assistanceLogService from "../../service/citizen/assistanceLogService";
import disasterCategories from '../../disaster_categories.json';

const sortSettings = {
    allowSorting: true,
};

const editing = {
    allowDeleting: true,
    allowAdding: true,
    allowEditing: true,
    showDeleteConfirmDialog: true,
    mode: "Dialog",
};

const filterSettings = {
    type: "Menu",
};

function AssistanceLog({ className = '' }) {
    const [userId, setUserId] = useState(null);
    const [data, setData] = useState([]);
    const [RecoveryAssistanceData, setRecoveryAssistanceData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    let categoryElement, categoryObj, subcategoryElement, subcategoryObj;

    const categoryParams = {
        create: () => {
            categoryElement = document.createElement('input');
            return categoryElement;
        },
        destroy: () => {
            categoryObj && categoryObj.destroy();
        },
        read: () => {
            return categoryObj ? categoryObj.text : null;
        },
        write: (args) => {
            const header = document.createElement('label');
            header.textContent = 'Category';
            header.style.display = 'block';
            header.style.marginBottom = '4px';
            header.style.fontSize = '12px';
            header.style.color = '#666';
            
            categoryElement.parentNode.insertBefore(header, categoryElement);
            
            categoryObj = new DropDownList({
                dataSource: disasterCategories.categories,
                fields: { text: 'categoryName', value: 'categoryId' },
                value: args.rowData.categoryId,
                text: args.rowData.category,
                change: (e) => {
                    const selectedCategory = e.value;
                    setSelectedCategory(selectedCategory);
                    if (subcategoryObj) {
                        subcategoryObj.enabled = true;
                        const tempQuery = new Query().where('categoryId', 'equal', selectedCategory);
                        subcategoryObj.query = tempQuery;
                        subcategoryObj.text = '';
                        subcategoryObj.dataBind();
                    }
                },
                placeholder: 'Select a category',
                floatLabelType: 'Never'
            });
            categoryObj.appendTo(categoryElement);
        }
    };
    
    const subcategoryParams = {
        create: () => {
            subcategoryElement = document.createElement('input');
            return subcategoryElement;
        },
        destroy: () => {
            subcategoryObj && subcategoryObj.destroy();
        },
        read: () => {
            return subcategoryObj ? subcategoryObj.text : null;
        },
        write: (args) => {
            const header = document.createElement('label');
            header.textContent = 'Sub Category';
            header.style.display = 'block';
            header.style.marginBottom = '4px';
            header.style.fontSize = '12px';
            header.style.color = '#666';
            
            subcategoryElement.parentNode.insertBefore(header, subcategoryElement);
            
            subcategoryObj = new DropDownList({
                dataSource: new DataManager(disasterCategories.subcategories),
                fields: { text: 'subcategoryName', value: 'subcategoryId' },
                value: args.rowData.subcategoryId,
                text: args.rowData.subcategory,
                enabled: args.rowData.categoryId !== undefined,
                query: new Query().where('categoryId', 'equal', args.rowData.categoryId || selectedCategory),
                placeholder: 'Select a subcategory',
                floatLabelType: 'Never'
            });
            subcategoryObj.appendTo(subcategoryElement);
        }
    };

    useEffect(() => {
        const auth = getAuth();

        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUserId(currentUser.uid);
                console.log("userid is", currentUser.uid);
            } else {
                setData(null);
                console.log("No user is logged in");
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userId) {
            fetchData();
        }
    }, [userId]);

    const fetchData = async () => {
        try {
            // Fetch user document
            const userDocRef = doc(db, "users", userId);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                setData({ id: userDoc.id, ...userDoc.data() });
                console.log("User data:", data); // Log user data

                const immediateAssistance = await assistanceLogService.fetchRecords(
                    userId,
                    "immediateAssistance"
                );
                const recoveryAssistance = await assistanceLogService.fetchRecords(
                    userId,
                    "recoveryAssistance"
                );

                console.log("Immediate Assistance Data:", immediateAssistance); // Log immediate assistance data
                console.log("Recovery Assistance Data:", recoveryAssistance); // Log recovery assistance data

                setImmediateResponseData(immediateAssistance.filter(item => !item.is_deleted));
                setRecoveryAssistanceData(recoveryAssistance.filter(item => !item.is_deleted));
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    let elem;
    let uploadObj;
    let strm;
    

    const datepickerparams = {
        params: {
            format: 'MM/dd/yyyy',
            showClearButton: false,
            showTodayButton: true
        }
    };

    const formatLastEditedTime = (time) => {
        if (!time) return 'Never';

        const now = new Date();
        const diffInSeconds = Math.floor((now - time) / 1000);

        if (diffInSeconds < 60) return 'Just now';
        if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
        if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
        if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;

        return time.toLocaleDateString();
    };

    //Immediate Response
    const [ImmediateResponseData, setImmediateResponseData] = useState([]);
    const [isImmediateResponseVisible, setIsImmediateResponseVisible] = useState(true);
    const [lastImmediateResponseEditedTime, setLastImmediateResponseEditedTime] = useState(null);

    const toggleImmediateResponse = (event) => {
        event.preventDefault();
        setIsImmediateResponseVisible(!isImmediateResponseVisible);
    };

    const ImmediateResponseGridRef = useRef(null);


    const handleAddClickImmediateResponse = () => {
        if (ImmediateResponseGridRef.current) {
            ImmediateResponseGridRef.current.addRecord();
        }
    };

    function compressImage(file, maxSizeMB) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function (event) {
                const img = new Image();
                img.src = event.target.result;

                img.onload = function () {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    const maxWidth = img.width;
                    const maxHeight = img.height;
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height = Math.floor((height *= maxWidth / width));
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width = Math.floor((width *= maxHeight / height));
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);

                    let quality = 0.9; // Start with high quality
                    let dataUrl = canvas.toDataURL("image/jpeg", quality);

                    while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
                        quality -= 0.1;
                        dataUrl = canvas.toDataURL("image/jpeg", quality);
                    }

                    resolve(dataUrl);
                };

                img.onerror = function (error) {
                    reject(error);
                };
            };

            reader.onerror = function (error) {
                reject(error);
            };
        });
    }

    // Upload image to Firebase Storage
    async function uploadImageToStorage(filePath, dataUrl) {
        const storage = getStorage();
        const storageRef = ref(storage, filePath);

        await uploadString(storageRef, dataUrl, "data_url");

        const downloadURL = await getDownloadURL(storageRef);

        return downloadURL;
    }

    const queryCellInfoImmediateResponse = (args) => {
        if (args.column.field === 'disasterName') {
            let bgColor, textColor;
            switch (args.data.disasterName) {
                case 'Fire':
                    bgColor = '#FFF6E6';
                    textColor = '#FDA502';
                    break;
                case 'Typhoon':
                    bgColor = '#DCF2FF';
                    textColor = '#1565C0';
                    break;
                case 'Flood':
                    bgColor = '#BED8FF';
                    textColor = '#0E47A1';
                    break;
                case 'Earthquake':
                    bgColor = '#F4EDE8';
                    textColor = '#8B4514';
                    break;
                default:
                    bgColor = '';
                    textColor = '';
            }

            if (bgColor) {
                args.cell.innerHTML = `<div style="
               background-color: ${bgColor};
               color: ${textColor};
               padding: 5px 10px;
               border-radius: 15px;
               display: inline-block;
               font-weight: bold;
               text-align: center;
             ">${args.data.disasterName}</div>`;
            }
        }
    };


    // State for RecoveryAssistance section
    const [isRecoveryAssistanceVisible, setIsRecoveryAssistanceVisible] = useState(true);
    const [lastRecoveryAssistanceEditedTime, setLasRecoveryAssistanceEditedTime] = useState(null);
    const [maxRecoveryAssistanceID, setMaxRecoveryAssistanceID] = useState(RecoveryAssistanceData.length > 0 ? Math.max(...RecoveryAssistanceData.map(item => item.RecoveryAssistanceID)) : 0);


    const toggleRecoveryAssistance = (event) => {
        event.preventDefault();
        setIsRecoveryAssistanceVisible(!isRecoveryAssistanceVisible);
    };

    function generateUniqueId(length = 28) {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * characters.length)
            );
        }
        return result;
    }

    const sanitizeData = (data) => {
        const sanitizedData = {};
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                sanitizedData[key] = data[key] || "";
            }
        }
        return sanitizedData;
    };

    

const actionBeginImmediate = async (args) => {
    if (args.requestType === "save") {
        if (args.action === "add") {
            args.data.ImmediateResponseID = generateUniqueId();
            args.data.is_deleted = false; // Set is_deleted to false for new records

            const sanitizedData = sanitizeData(args.data);
            await assistanceLogService.addRecord(userId, sanitizedData, "immediateAssistance");
            console.log("Add:", args.data);
        } else if (args.action === "edit") {
            args.data.is_deleted = false; // Ensure is_deleted is false when editing
            setSelectedRow(args.data); // Use setSelectedRow instead of direct assignment

            const sanitizedData = sanitizeData(args.data);
            await assistanceLogService.updateRecord(
                userId,
                args.data.ImmediateResponseID,
                sanitizedData,
                "immediateAssistance"
            );
            console.log("Edit:", args.data);
        }
    }
    if (args.requestType === "beginEdit") {
        setSelectedRow(args.rowData); // Use setSelectedRow instead of direct assignment
    }

    if (args.requestType === "delete") {
        try {
            for (const docData of args.data) {
                const documentId = docData.ImmediateResponseID;
                const fileUrl = docData.photoGeneral;

                if (fileUrl) {
                    const storage = getStorage();
                    const fileRef = ref(storage, fileUrl);

                    await deleteObject(fileRef);
                    console.log("File deleted from storage:", fileUrl);
                }

                console.log("Attempting to mark document as deleted with ID:", documentId);

                // Instead of deleting, update the is_deleted flag to true
                await assistanceLogService.updateRecord(
                    userId,
                    documentId,
                    { ...docData, is_deleted: true },
                    "immediateAssistance"
                );
                console.log("Document marked as deleted:", documentId);
            }
            args.cancel = true; // Prevent actual deletion from the grid
            await fetchData(); // Refresh the data after marking as deleted
        } catch (error) {
            console.error("Error marking document as deleted:", error);
        }
    }
};



const actionCompleteImmediate = async (args) => {
    if (args.requestType === "save") {
        console.log("Data saved successfully:", selectedRow || args.data);
        await fetchData(); // Refresh the data after save
    }
};


    const recoveryGridRef = useRef(null);

    const handleAddClickRecovery = () => {
        if (recoveryGridRef.current) {
            recoveryGridRef.current.addRecord();
        }
    };

    const recoveryTemplate = (props) => {
        const src = props.photoRecovery; // Get the image path from the data source

        return (
            <div className="image">
                {src ? (
                    <img
                        src={src}
                        alt={props.RecoveryAssistanceID}
                        style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                            objectFit: "cover",
                        }}
                    />
                ) : (
                    <div
                        className="flex flex-col items-center justify-center"
                        style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                            border: "1px solid #ddd",
                        }}
                    >
                        <p>No Image</p>
                    </div>
                )}
            </div>
        );
    };

    const template = recoveryTemplate;
    const imageEditRecovery = {
        create: () => {
            elem = document.createElement("div");
            return elem;
        },
        read: () => {
            return strm;
        },
        destroy: () => {
            if (uploadObj) {
                uploadObj.destroy();
            }
            strm = null;
        },
        write: (args) => {
            const path = {
                removeUrl:
                    "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
                saveUrl:
                    "https://services.syncfusion.com/react/production/api/FileUploader/Save",
            };

            elem.innerHTML = `
          <div class="flex flex-col justify-center w-full">
              <label htmlFor="access" class="block mb-2 text-xs text-gray-500">Photo Attachment</label>
              <div class="flex flex-col items-center">
                  <div id="imagePreview" class="mb-4 flex flex-col items-center justify-center">
                      <!-- Image will be dynamically inserted here -->
                  </div>
                  <div id="dropArea">
                      <label class="upload-button" id="uploadLabel">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                              <polyline points="17 8 12 3 7 8"></polyline>
                              <line x1="12" y1="3" x2="12" y2="15"></line>
                          </svg>
                          Upload Image
                      </label>
                      <input type="file" id="defaultUpload" style="display:none;" />
                  </div>
              </div>
          </div>
      `;

            const inputElement = elem.querySelector("#defaultUpload");
            const uploadLabel = elem.querySelector("#uploadLabel");
            const imagePreview = elem.querySelector("#imagePreview");

            uploadObj = new Uploader({
                asyncSettings: path,
                success: onUploadSuccess,
                failure: onUploadFailure,
                multiple: false,
                allowedExtensions: ".jpeg, .png, .jpg",
                cssClass: "hidden",
            });

            uploadObj.appendTo(inputElement);

            uploadLabel.addEventListener("click", () => {
                inputElement.click();
            });

            inputElement.addEventListener("change", () => {
                if (inputElement.files.length > 0) {
                    uploadObj.upload(inputElement.files);
                }
            });

            async function onUploadSuccess(args) {
                if (args.operation === "upload") {
                    try {
                        const file = args.file.rawFile;
                        const compressedFile = await compressImage(file, 1);

                        const filePath = `disaster-mgt/home_repair/${userId}/${file.name}`;
                        const downloadURL = await uploadImageToStorage(
                            filePath,
                            compressedFile
                        );
                        strm = downloadURL;
                        console.log("Image uploaded successfully:", downloadURL);

                        displayUploadedImage(downloadURL, args.file.name);
                    } catch (error) {
                        console.error("Error during upload:", error);
                    }
                }
            }

            function onUploadFailure(args) {
                console.log("File failed to upload");
            }

            function displayUploadedImage(imageSrc, fileName) {
                imagePreview.innerHTML = `
                    <img src="${imageSrc}" alt="Uploaded Image" style="width: 100px; height: 100px; border-radius: 50%; object-fit: cover;" />
                    <p class="text-sm text-blue-500 mt-2">${fileName}</p>
                `;
            }

            // Display the current image if available
            if (args.rowData.photoRecovery) {
                strm = args.rowData.photoRecovery;
                displayUploadedImage(args.rowData.photoRecovery, "Current Image");
            } else {
                imagePreview.innerHTML = "";
            }
        },
    };
    const [selectedRow, setSelectedRow] = useState(null);
    const actionBeginRecovery = async (args) => {
        if (args.requestType === "save") {
            if (args.action === "add") {
                args.data.RecoveryAssistanceID = generateUniqueId();
                args.data.is_deleted = false;
            }
            
            // Ensure category and subcategory are included in the data
            args.data.categoryId = categoryObj ? categoryObj.value : args.data.categoryId;
            args.data.category = categoryObj ? categoryObj.text : args.data.category;
            args.data.subcategoryId = subcategoryObj ? subcategoryObj.value : args.data.subcategoryId;
            args.data.subcategory = subcategoryObj ? subcategoryObj.text : args.data.subcategory;
    
            const sanitizedData = sanitizeData(args.data);
            
            if (args.action === "add") {
                try {
                    await assistanceLogService.addRecord(userId, sanitizedData, "recoveryAssistance");
                    console.log("Record added successfully");
                    fetchData(); // Refresh the data after adding
                } catch (error) {
                    console.error("Error adding record:", error);
                }
            } else if (args.action === "edit") {
                const documentId = args.data.RecoveryAssistanceID || selectedRow?.RecoveryAssistanceID;
                if (!documentId) {
                    console.error("No RecoveryAssistanceID found for editing");
                    return;
                }
                try {
                    await assistanceLogService.updateRecord(userId, documentId, sanitizedData, "recoveryAssistance");
                    console.log("Record updated successfully");
                    fetchData(); // Refresh the data after updating
                } catch (error) {
                    console.error("Error updating record:", error);
                }
            }
            console.log("Data saved:", args.data);
        }
        if (args.requestType === "beginEdit") {
            setSelectedRow(args.rowData);
        }
        if (args.requestType === "delete") {
            try {
                for (const docData of args.data) {
                    const documentId = docData.RecoveryAssistanceID;
                    const fileUrl = docData.photoRecovery;
    
                    if (fileUrl) {
                        try {
                            const storage = getStorage();
                            const fileRef = ref(storage, fileUrl);
    
                            await deleteObject(fileRef);
                            console.log("File deleted from storage:", fileUrl);
                        } catch (error) {
                            if (error.code === 'storage/object-not-found') {
                                console.log("File not found in storage, skipping deletion:", fileUrl);
                            } else {
                                console.error("Error deleting file from storage:", error);
                            }
                        }
                    }
    
                    console.log("Attempting to mark document as deleted with ID:", documentId);
    
                    await assistanceLogService.updateRecord(
                        userId,
                        documentId,
                        { ...docData, is_deleted: true },
                        "recoveryAssistance"
                    );
                    console.log("Document marked as deleted:", documentId);
                }
                args.cancel = true; // Prevent actual deletion from the grid
                fetchData();
            } catch (error) {
                console.error("Error deleting document:", error);
            }
        }
    };

    const actionCompleteRecovery = async (args) => {
        if (args.requestType === "save") {
            console.log("Data saved successfully:", args.data);
            await fetchData(); // Refresh the data after any save operation
        }
    };

    const queryCellInfoRecoveryAssistance = (args) => {
        if (args.column.field === 'subcategory') {
            let bgColor, textColor;
            switch (args.data.subcategory) {

                default:
                    bgColor = '#BED8FF';
                    textColor = '#0E47A1';
            }

            if (bgColor) {
                args.cell.innerHTML = `<div style="
              background-color: ${bgColor};
              color: ${textColor};
              padding: 5px 10px;
              border-radius: 15px;
              display: inline-block;
              font-weight: bold;
              text-align: center;
            ">${args.data.subcategory}</div>`;
            }
        }
    };

    // Extract unique categories from the data
    

    return (
        <div className={`w-full max-w-[1640px] flex flex-col items-start justify-start py-[15px] box-border ${className}`}>
            <div className="w-full">
                <section className="self-stretch h-auto mt-4 rounded-3xs bg-white border-silver-100 border-[1px] border-solid box-border overflow-hidden p-5">
                    <header className="self-stretch flex flex-row items-center justify-between mb-2">
                        <div>
                            <h2 className="text-sm font-bold font-raleway text-blue-500 mb-1">Immediate Response Assistance Received</h2>
                            <p className="text-xs font-medium font-poppins text-gray-500">
                                Last Edited: {formatLastEditedTime(lastImmediateResponseEditedTime)}
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            <button
                                type="button"
                                className="px-3 py-1.5 bg-blue-500 text-white rounded flex items-center gap-1 text-xs font-semibold cursor-pointer"
                                onClick={handleAddClickImmediateResponse}
                            >
                                <FaPlus className="w-3 h-3" />
                                Add
                            </button>

                            <button
                                type="button"
                                className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer transition-all duration-300 ease-in-out hover:bg-gray-300"
                                onClick={toggleImmediateResponse}
                            >
                                <span className={`transition-transform duration-300 ease-in-out ${isImmediateResponseVisible ? 'rotate-0' : 'rotate-180'}`}>
                                    <FaChevronDown />
                                </span>
                            </button>
                        </div>
                    </header>
                    {isImmediateResponseVisible && (
                        <div className="self-stretch flex flex-col gap-4">
                            <GridComponent
                                ref={ImmediateResponseGridRef}
                                dataSource={ImmediateResponseData}
                                allowSorting={true}
                                sortSettings={sortSettings}
                                editSettings={editing}
                                filterSettings={filterSettings}
                                allowFiltering={true}
                                queryCellInfo={queryCellInfoImmediateResponse}
                                actionBegin={actionBeginImmediate}
                                actionComplete={actionCompleteImmediate}

                            >
                                <ColumnsDirective>
                                    <ColumnDirective
                                        field="ImmediateResponseID"
                                        headerText="ImmediateResponseID"
                                        isPrimaryKey={true}
                                        visible={false}
                                    />

                                    <ColumnDirective field='category' headerText='Category' width='100'
                                        editType='dropdownedit'
                                        edit={{
                                            params: {
                                                dataSource: ["General", "Home Repair and Replacement", "Landscaping", "Vehicle", "Contents"],
                                                fields: { text: "category", value: "category" },
                                                query: new Query(),
                                            }
                                        }} />
                                    <ColumnDirective field='disasterName' headerText='Disaster' width='100'
                                        editType='dropdownedit'
                                        edit={{
                                            params: {
                                                dataSource: ["Fire", "Typhoon", "Flood", "Earthquake"],
                                                fields: { text: "disasterName", value: "disasterName" },
                                                query: new Query(),
                                            }
                                        }} />
                                    <ColumnDirective field='assistanceType' headerText='Type of Assistance Received' width='100' />
                                    <ColumnDirective field='assistanceSource' headerText='Source of Assistance Received' width='100' />
                                    <ColumnDirective field='organization' headerText='Organization' width='100' />
                                    <ColumnDirective field="disasterDate" headerText="Date" width="100" format='MM/dd/yyyy' editType='datepickeredit' edit={datepickerparams} />
                                    <ColumnDirective
                                        headerText="Actions"
                                        width="120"
                                        commands={[
                                            {
                                                type: "Edit",
                                                buttonOption: {
                                                    content: '<i class="fas fa-edit"></i>',
                                                    cssClass: "e-outline custom-button",
                                                },
                                            },
                                            {
                                                type: "Delete",
                                                buttonOption: {
                                                    content: '<i class="fas fa-trash-alt"></i>',
                                                    cssClass: "e-outline custom-button",
                                                },
                                            },
                                        ]}
                                    />
                                </ColumnsDirective>
                                <Inject services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]} />
                            </GridComponent>
                        </div>
                    )}
                </section>

                <section className="self-stretch h-auto mt-4 rounded-3xs bg-white border-silver-100 border-[1px] border-solid box-border overflow-hidden p-5">
                    <header className="self-stretch flex flex-row items-center justify-between mb-2">
                        <div>
                            <h2 className="text-sm font-bold font-raleway text-blue-500 mb-1">Recovery Assistance Received</h2>
                            <p className="text-xs font-medium font-poppins text-gray-500">
                                Last Edited: {formatLastEditedTime(lastRecoveryAssistanceEditedTime)}
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            <button
                                type="button"
                                className="px-3 py-1.5 bg-blue-500 text-white rounded flex items-center gap-1 text-xs font-semibold cursor-pointer"
                                onClick={handleAddClickRecovery}
                            >
                                <FaPlus className="w-3 h-3" />
                                Add
                            </button>
                            <button
                                type="button"
                                className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer transition-all duration-300 ease-in-out hover:bg-gray-300"
                                onClick={toggleRecoveryAssistance}
                            >
                                <span className={`transition-transform duration-300 ease-in-out ${isRecoveryAssistanceVisible ? 'rotate-0' : 'rotate-180'}`}>
                                    <FaChevronDown />
                                </span>
                            </button>
                        </div>
                    </header>
                    {isRecoveryAssistanceVisible && (
                        <div className="self-stretch flex flex-col gap-4">
                            <GridComponent
                                ref={recoveryGridRef}
                                dataSource={RecoveryAssistanceData}
                                allowSorting={true}
                                sortSettings={sortSettings}
                                editSettings={editing}
                                filterSettings={filterSettings}
                                allowFiltering={true}
                                queryCellInfo={queryCellInfoRecoveryAssistance}
                                actionBegin={actionBeginRecovery}
                                actionComplete={actionCompleteRecovery}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective
                                        field="RecoveryAssistanceID"
                                        headerText="RecoveryAssistanceID"
                                        isPrimaryKey={true}
                                        visible={false}
                                    />
                                    <ColumnDirective
                                        field='category'
                                        headerText='Category'
                                        width='100'
                                        editType='dropdownedit'
                                        edit={categoryParams}
                                    />
                                    <ColumnDirective
                                        field='subcategory'
                                        headerText='Sub Category'
                                        width='150'
                                        editType='dropdownedit'
                                        edit={subcategoryParams} // Ensure this is called correctly
                                    />
                                    <ColumnDirective field='damage' headerText='Description of Damage' width='100' />
                                    <ColumnDirective field='estimate' headerText='Estimate to Repair' width='100' />
                                    <ColumnDirective field='organization' headerText='Organization' width='100' />
                                    <ColumnDirective field='photoRecovery' headerText='Photo Attachment' width='100' template={template} edit={imageEditRecovery} />
                                    <ColumnDirective
                                        headerText="Actions"
                                        width="120"
                                        commands={[
                                            {
                                                type: "Edit",
                                                buttonOption: {
                                                    content: '<i class="fas fa-edit"></i>',
                                                    cssClass: "e-outline custom-button",
                                                },
                                            },
                                            {
                                                type: "Delete",
                                                buttonOption: {
                                                    content: '<i class="fas fa-trash-alt"></i>',
                                                    cssClass: "e-outline custom-button",
                                                },
                                            },
                                        ]}
                                    />
                                </ColumnsDirective>
                                <Inject services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]} />
                            </GridComponent>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
};

export default AssistanceLog;
