import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Edit,
  Filter,
  GridComponent,
  Page,
  Sort,
  Inject,
  Search,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import dataService from "../../service/data/DataService";

const filterSettings = {
  type: "Menu",
};

const sortSettings = {
  allowSorting: true,
};

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  showDeleteConfirmDialog: true,
  allowDeleting: true,
  allowEditOnDblClick: false,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};

const searchModalNavs = [
  {
    index: 0,
    label: "Citizen Search",
  },
  {
    index: 1,
    label: "Contacts",
  },
  {
    index: 2,
    label: "Disaster Recovery Partners Search",
  },
  {
    index: 3,
    label: "Local Non-Profit Coalition Search",
  },
];

const CitizenSearch = () => {
  const gridRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [toggleSideModal, setToggleSideModal] = useState(true);
  const [searchModalCurrNav, setSearchModalCurrNav] = useState(0);

  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const mapCitizenData = (citizens) => {
    return citizens.map((citizen) => ({
      name: `${citizen.first_name} ${citizen.last_name}`,
      address: `${citizen.address1}, ${citizen.address2}, ${citizen.city}, ${citizen.state}, ${citizen.zip}`,
      mobile_number: citizen.mobile_number,
      email: citizen.email,
    }));
  };

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });
        if (userDoc.data().center_id) {
          const citizens = await dataService.getUsersByCenterIdAndRole(
            userDoc.data().center_id,
            "citizen"
          );
          const gridData = mapCitizenData(citizens);
          setGridData(gridData);
        }
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const onToggleGrid = () => {
    setToggleSideModal((prev) => !prev);
  };

  const onAddRecord = () => {
    gridRef.current.addRecord();
  };

  const onActionBegin = useCallback(
    (args) => {
      if (args.requestType === "save" && args.action === "add") {
        args.cancel = true;
        const newRecord = {
          ...args.data,
          id: (parseInt(dataSource.at(-1).id) + 1).toString(),
        };

        if (!!args.data.id) {
          const getIndexOfData = dataSource.findIndex(
            (d) => d.id === args.rowData.id
          );
          if (getIndexOfData < 0) return;
          setTimeout(() => {
            setDataSource((prev) => {
              prev.splice(getIndexOfData, 1, {
                ...newRecord,
                id: args.rowData.id,
              });
              return (prev = [...prev]);
            });
            args.cancel = false;
            gridRef.current.closeEdit();
            return args;
          }, 1000);
          return args;
        }

        // mimic the api call
        setTimeout(() => {
          args.cancel = false;
          setDataSource((prev) => {
            return (prev = [...prev, newRecord]);
          });
          gridRef.current.closeEdit();
          return args;
        }, 1000);
      }
      return args;
    },
    [dataSource]
  );

  const onActiveNav = useCallback((index) => {
    setSearchModalCurrNav(() => index);
  }, []);

  const onSearchDatabase = useCallback(
    (search) => {
      setSearchResults(() =>
        !search
          ? []
          : dataSource.filter((d) =>
              d.name.toLowerCase().includes(search.toLowerCase())
            )
      );
    },
    [dataSource]
  );

  const renderSideModal = useMemo(() => {
    return (
      !toggleSideModal && (
        <div
          className="absolute right-0 top-0 z-10 flex h-full w-full flex-col items-end border border-solid font-raleway"
          onClick={() => {
            setToggleSideModal(() => true);
          }}
        >
          <div
            className="mt-14 h-full w-[40%] space-y-[10px] bg-white p-5 shadow-2xl"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mb-5 flex items-center space-x-[10px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  opacity="0.4"
                  d="M14.6756 0.5H5.33333C1.92889 0.5 0 2.42889 0 5.83333V15.1667C0 18.5711 1.92889 20.5 5.33333 20.5H14.6756C18.08 20.5 20 18.5711 20 15.1667V5.83333C20 2.42889 18.08 0.5 14.6756 0.5Z"
                  fill="#348BFF"
                />
                <path
                  d="M5.36866 7.8689C4.91533 7.8689 4.54199 8.24223 4.54199 8.70445V15.5756C4.54199 16.0289 4.91533 16.4022 5.36866 16.4022C5.83088 16.4022 6.20421 16.0289 6.20421 15.5756V8.70445C6.20421 8.24223 5.83088 7.8689 5.36866 7.8689Z"
                  fill="#348BFF"
                />
                <path
                  d="M10.0352 4.58887C9.58183 4.58887 9.2085 4.9622 9.2085 5.42442V15.5755C9.2085 16.0289 9.58183 16.4022 10.0352 16.4022C10.4974 16.4022 10.8707 16.0289 10.8707 15.5755V5.42442C10.8707 4.9622 10.4974 4.58887 10.0352 4.58887Z"
                  fill="#348BFF"
                />
                <path
                  d="M14.6398 11.4956C14.1775 11.4956 13.8042 11.8689 13.8042 12.3312V15.5756C13.8042 16.0289 14.1775 16.4023 14.6309 16.4023C15.0931 16.4023 15.4664 16.0289 15.4664 15.5756V12.3312C15.4664 11.8689 15.0931 11.4956 14.6398 11.4956Z"
                  fill="#348BFF"
                />
              </svg>
              <p className="text-[12px] font-semibold text-[#348BFF]">
                Database
              </p>
            </div>
            <div className="flex justify-between">
              {searchModalNavs.map((nav, i) => {
                return (
                  <button
                    key={i}
                    className={`cursor-pointer bg-white px-[16px] py-[6px] text-[#348BFF] ${
                      searchModalCurrNav === nav.index &&
                      "border-b-[3px] border-solid border-[#348BFF] "
                    }`}
                    onClick={() => onActiveNav(nav.index)}
                  >
                    {nav.label}
                  </button>
                );
              })}
            </div>
            <div className="flex h-[35px] items-center overflow-hidden rounded-md border border-solid border-gray-500">
              <input
                placeholder="Search"
                className="h-full flex-1 px-3 focus:outline-none"
                onChange={(e) => onSearchDatabase(e.target.value)}
              />
              <div className="px-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                >
                  <g id="Search">
                    <ellipse
                      id="Ellipse_746"
                      cx="9.59922"
                      cy="10.1532"
                      rx="8.59922"
                      ry="8.65324"
                      stroke="#DEE2E6"
                      stroke-width="2"
                    />
                    <path
                      id="Path_34202"
                      opacity="0.4"
                      d="M19.6745 21.4553C19.3405 21.4445 19.0228 21.307 18.7853 21.0705L16.7488 18.6902C16.3122 18.2909 16.2765 17.6123 16.6688 17.1689C16.8524 16.9831 17.102 16.8787 17.3624 16.8787C17.6228 16.8787 17.8725 16.9831 18.0561 17.1689L20.6172 19.2181C20.9861 19.5957 21.0999 20.1563 20.9078 20.6492C20.7157 21.1422 20.2535 21.4754 19.7279 21.5L19.6745 21.4553Z"
                      fill="#DEE2E6"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              {!!searchResults.length &&
                searchResults.map((ds) => {
                  return (
                    <div key={ds.id}>
                      <p className="font-semibold text-[#444444]">{ds.name}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )
    );
  }, [
    toggleSideModal,
    onActiveNav,
    searchModalCurrNav,
    searchResults,
    onSearchDatabase,
  ]);

  return (
    <>
      <div className="relative flex h-full min-h-[94vh] flex-col space-y-2 py-5 pl-3 pr-5 font-raleway">
        <div className="flex items-center space-x-2 border border-red-500">
          <p className="text-[12px]">Database</p>
          <p>/</p>
          <p className="text-[12px]">Citizen</p>
        </div>
        <div className="flex flex-col space-y-5 rounded-3xs border-[1px] border-solid border-[#C9C9C9] p-[20px]">
          <div className="flex items-center justify-between">
            <p>Citizen</p>
            <div className="flex items-center space-x-2">
              {/* <button
                className="cursor-pointer rounded-e-sm px-[10px] py-[5px]"
                onClick={onToggleGrid}
              >
                Search
              </button>
              <button
                className={`flex cursor-pointer items-center space-x-2 rounded-[6px] bg-[#348BFF] px-[10px] py-[5px] text-white ${
                  !toggleSideModal && "opacity-[0.5]"
                }`}
                onClick={onAddRecord}
                disabled={!toggleSideModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g id="Add User">
                    <path
                      id="Fill 1"
                      opacity="0.4"
                      d="M13.0652 5.39923H12.2632V4.61507C12.2632 4.28029 11.9947 4.00732 11.6639 4.00732C11.3337 4.00732 11.0646 4.28029 11.0646 4.61507V5.39923H10.2639C9.93304 5.39923 9.66455 5.6722 9.66455 6.00698C9.66455 6.34176 9.93304 6.61472 10.2639 6.61472H11.0646V7.39958C11.0646 7.73436 11.3337 8.00732 11.6639 8.00732C11.9947 8.00732 12.2632 7.73436 12.2632 7.39958V6.61472H13.0652C13.3954 6.61472 13.6646 6.34176 13.6646 6.00698C13.6646 5.6722 13.3954 5.39923 13.0652 5.39923Z"
                      fill="white"
                    />
                    <path
                      id="Fill 4"
                      d="M5.33105 9.01782C2.63387 9.01782 0.331055 9.44904 0.331055 11.1719C0.331055 12.8941 2.61986 13.3408 5.33105 13.3408C8.02757 13.3408 10.3311 12.9096 10.3311 11.1868C10.3311 9.46391 8.04225 9.01782 5.33105 9.01782Z"
                      fill="white"
                    />
                    <path
                      id="Fill 7"
                      opacity="0.4"
                      d="M5.33088 7.37688C7.16726 7.37688 8.6393 5.88587 8.6393 4.02581C8.6393 2.16576 7.16726 0.674072 5.33088 0.674072C3.4945 0.674072 2.02246 2.16576 2.02246 4.02581C2.02246 5.88587 3.4945 7.37688 5.33088 7.37688Z"
                      fill="white"
                    />
                  </g>
                </svg>
                <p className="mt-[2px]">Add</p>
              </button> */}
            </div>
          </div>
          <div>
            <GridComponent
              ref={gridRef}
              dataSource={gridData}
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionBegin={onActionBegin}
              toolbar={["Search"]}
            >
              <ColumnsDirective>
                <ColumnDirective field="id" isPrimaryKey visible={false} />
                <ColumnDirective field="name" headerText="Name" />
                <ColumnDirective field="address" headerText="Address" />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                />
                <ColumnDirective field="email" headerText="Email Address" />
                {/* <ColumnDirective
                  headerText="Manage Records"
                  width="160"
                  commands={commands}
                /> */}
              </ColumnsDirective>
              <Inject services={[Page, Sort, Filter, Search]} />
            </GridComponent>
          </div>
        </div>
      </div>
      {renderSideModal}
    </>
  );
};

export default CitizenSearch;
