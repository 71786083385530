import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import { updateDoc, doc, getDocs, collection, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import note from "../../src/assets/note.png";
import check from "../../src/assets/check.png";
import umbrella from "../../src/assets/umbrella.png";
import lightning from "../../src/assets/lightning.png";
import fire from "../../src/assets/fire.png";
import sendOtpEmail from "../service/api/mailer";

const Register = () => {
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isInvalidCode, setIsInvalidCode] = useState(false)
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const onInput = (index, event) => {
    const inputElement = event.target;
    const value = inputElement.value;
    const key = event.nativeEvent.inputType;

    if (key === "deleteContentBackward" && value === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }

    if (/^[0-9]$/.test(value) || value === "") {
      const newValues = [...inputValues];
      newValues[index] = value;
      setInputValues(newValues);

      // Automatically focus on the next input field
      if (value.length === 1 && index < inputValues.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const onContinue = async () => {
    try {
      const user = await getUserByEmail(auth.currentUser.email)
      const input = inputValues.join("");

      if (user.otp == input) {
        const userRef = doc(db, 'users', user.id);

        await updateDoc(userRef, {
            status: "active"
        });

        setTimeout(() => {
          navigate("/home");
        }, 1000)
      } else {
        alert("Not match!");
        setIsInvalidCode(true);
      }
    } catch(err) {
      console.error(err);
      alert(err.message);
    }
  };

  const getUserByEmail = async (email) => {
    try {
      const q = query(collection(db, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        console.log('No matching documents.');
        return null;
      } 
  
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });
  
      return userData.length > 0 ? userData[0] : null;
  
    } catch (error) {
      console.error('Error getting user by email:', error);
      return null;
    }
  };

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const resendCode = async () => {
    try {
      const user = await getUserByEmail(auth.currentUser.email);
      const otp = generateOtp();
      const userRef = doc(db, 'users', user.id);

      await updateDoc(userRef, {
          otp: otp.toString()
      });

      await sendOtpEmail(auth.currentUser.email, otp);

      alert("Email sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  }

  return (
    <div className="flex h-screen w-full justify-center bg-white font-raleway">
      <div
        className="my-auto flex h-[90vh] w-[603px] rounded-bl-[10px] rounded-tl-[10px] bg-white px-[45px] py-[70px]"
        style={{
          border: "1px solid var(--Text-Locked, #D5D7DB)",
          background: "var(--Pure-White, #FFF)",
          boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="mt-[40px] flex h-full w-[483px] flex-col text-center text-[#4C5470]">
          <img
            src={blueSkyLogo}
            className="mx-auto h-[141.942px] w-[134.703px]"
          ></img>
          <p className="mt-[10px] font-bold text-[#348BFF]">
            DISASTER AWARE & READY COMMUNITIES
          </p>
          <p className="my-[10px] text-[20px] font-bold text-[#3D435A]">
            Create Account
          </p>
          <p className="pb-[10px] text-xs font-bold text-[#4C5470]">
            Step 2 of 2
          </p>
          <div className="flex flex-col gap-[20px] p-[30px]">
            <div className="flex flex-col text-start">
              <p>Enter Your Confirmation Code</p>
              <p className="font-bold text-[#2563EB]">{auth.currentUser.email}</p>
            </div>
            <div className="flex flex-col text-start">
              <p>Please enter the subscription code sent to your email.</p>
              <div className="mx-auto mt-4 flex  w-full justify-between">
                {inputValues.map((value, index) => (
                  <input
                    key={index}
                    type="password"
                    maxLength="1"
                    className={`lockbox-input h-12 w-12 rounded-lg border text-center `}
                    pattern="[0-9]"
                    value={value}
                    onChange={(event) => onInput(index, event)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              <div className="mt-[10px] flex justify-between text-start">
                <p className="font-medium text-[#991B1B]">
                  {isInvalidCode? "You have entered an invalid code!" : ""}
                </p>
                <button onClick={resendCode} className="font-medium text-[#2563EB] bg-white">Resend Code</button>
              </div>
            </div>
          </div>
          <div className="mt-[20px] flex flex-col gap-[10px]">
            <button 
              className="h-10 w-full rounded-[4px] bg-[#348BFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
              onClick={onContinue}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <div
        className="relative my-auto ml-[-5px] h-[90vh] w-[288.195px] rounded-br-[10px] rounded-tr-[10px] bg-[#348BFF]"
        style={{
          border: "1px solid var(--Text-Locked, #D5D7DB)",
          boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <img src={note} className="absolute right-0 top-[80px]"></img>
        <img src={check} className="absolute left-[-40px] top-[230px]"></img>
        <img src={umbrella} className="absolute right-0 top-[380px]"></img>
        <img
          src={lightning}
          className="absolute left-[-40px] top-[470px]"
        ></img>
        <img src={fire} className="absolute right-0 top-[600px]"></img>
      </div>
    </div>
  );
};

Register.propTypes = {
  className: PropTypes.string,
};

export default Register;
