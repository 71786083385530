import React, { useState } from "react";
import weatherRadioIcon from '../../icons/Preparedness/weatherRadio.png';
import weatherRadioIcon2 from '../../icons/Preparedness/weatherRadio2.png';
import ready from '../../icons/Preparedness/ready.png';



  
const Preparedness = () => {
  
  const PreparednessVideos = [
    {
      VideoUrl: 'https://www.youtube.com/watch?v=hhQqujnxmxk',
      title: 'Emergency Preparedness',
      author: 'Pacific Gas & Electric'
    },
    {
      VideoUrl: 'https://www.youtube.com/watch?v=0zCl5MuiOu4',
      title: 'Important Things To Know Before a Disaster',
      author: 'FEMA'
    },
    {
      VideoUrl: 'https://www.youtube.com/watch?v=juB48LbB58s',
      title: 'Preparing Makes Sense for Pet Owners',
      author: 'FEMA'
    },
    {
      VideoUrl: 'https://www.youtube.com/watch?v=aUbSF_S20bE',
      title: 'Make Sure Your Pets Are Ready For Disasters',
      author: 'FEMA'
    }
  ];
  
  // Add these state variables at the beginning of your component
  const [preparednessModals, setPreparednessModals] = useState(PreparednessVideos.map(() => false));
  
  const openPreparednessModal = (index) => {
    const newModals = [...preparednessModals];
    newModals[index] = true;
    setPreparednessModals(newModals);
  };
  
  const closePreparednessModal = (index) => {
    const newModals = [...preparednessModals];
    newModals[index] = false;
    setPreparednessModals(newModals);
  };
  
  const TornadoVideos = [
    {
      VideoUrl: 'https://www.youtube.com/watch?v=KvLNySr4Iw4',
      title: 'Get Weather Ready - Before a Tornado',
      author: 'usoceangov'
    },
    {
      VideoUrl: 'https://www.youtube.com/watch?v=_5TiTfuvotc',
      title: 'Get Weather Ready: During a Tornado',
      author: 'National Weather Service (NWS)'
    },
    {
      VideoUrl: 'https://www.youtube.com/watch?v=UQ94ESZulA8',
      title: 'Get Weather Ready: After a Tornado',
      author: 'National Weather Service (NWS)'
    },
    {
      VideoUrl: 'https://www.youtube.com/watch?v=lUIsx0LdO5k',
      title: 'Surviving the Tornado',
      author: 'The Weather Channel'
    },
  ];
  
  // Add these state variables at the beginning of your component
  const [tornadoModals, setTornadoModals] = useState(TornadoVideos.map(() => false));
  
  const openTornadoModal = (index) => {
    const newModals = [...tornadoModals];
    newModals[index] = true;
    setTornadoModals(newModals);
  };
  
  const closeTornadoModal = (index) => {
    const newModals = [...tornadoModals];
    newModals[index] = false;
    setTornadoModals(newModals);
  };
  
  
  const Modal = ({ isOpen, onClose, videoSrc }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-black rounded-lg shadow-lg overflow-hidden" style={{ width: '560px' }}>
          <div className="flex justify-between items-center bg-gray-800 text-white p-2">
            <span className="font-bold">Video</span>
            <button
              className="w-8 h-8 flex items-center justify-center text-gray-300 hover:text-white focus:outline-none border border-gray-300 rounded-full transition-colors duration-200 ease-in-out hover:border-white bg-black"
              onClick={onClose}
            >
              <span className="text-3xl leading-none">X</span>
            </button>
          </div>
          <div className="p-4">
            <iframe
              width="100%"
              height="315"
              src={videoSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  
  return <div className="w-full relative flex flex-col items-start justify-start gap-2.5 pb-10 leading-[normal] tracking-[normal] text-left text-sm text-darkslategray-100 font-raleway">
  <header className="w-[1124.3px] flex flex-col items-start justify-start py-0 pl-0 pr-5 box-border gap-2.5 max-w-full text-left text-sm text-darkslategray-100 font-raleway">
    <div className="w-[998px] flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border max-w-full text-darkslateblue">
      <b className="flex-1 relative uppercase inline-block max-w-full">
        Weather Radios
      </b>
    </div>
    <div className="w-[998px] flex flex-row items-start justify-start pt-0 px-0 pb-[23px] box-border max-w-full">
      <b className="flex-1 relative inline-block max-w-full">Articles</b>
    </div>
   <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-4 max-w-full">
<div className="flex-1 min-w-[300px] flex flex-col items-start justify-start gap-2.5 max-w-full">
<div className="w-full flex flex-row items-center justify-start gap-2.5 max-w-full">
  <img
    className="h-[75px] w-[75px] relative object-cover flex-shrink-0"
    loading="lazy"
    alt="Weather Radio Icon"
    src={weatherRadioIcon} 
  />
  <div className="flex-1 flex flex-col items-start justify-start gap-[7px] min-w-[200px]">
    <a
      className="relative font-semibold text-[inherit] [text-decoration:none]"
      href="https://www.weather.gov/nwr/"
      target="_blank"
      rel="noopener noreferrer"
    >
      NOAA Weather Radio Info
    </a>
    <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
      <a
        className="h-auto flex-1 relative text-[inherit] flex items-center [text-decoration:none] max-w-full"
        href="https://www.redcross.org/content/dam/redcross/get-help/pdfs/tornado/EN_Tornado-Safety-Checklist.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Prepare for Disasters Not if, but when: Every U.S. state and
        territory is at risk of disasters...
      </a>
    </div>
  </div>
</div>
<b className="self-stretch relative uppercase text-darkslateblue">
  General Disaster Info
</b>
</div>
<div className="flex-1 min-w-[300px] flex flex-row items-start justify-start gap-2.5 max-w-full">
<img
  className="h-[75px] w-[75px] relative object-contain flex-shrink-0"
  loading="lazy"
  alt="Weather Radio Icon 2"
  src={weatherRadioIcon2}
/>
<div className="flex-1 flex flex-col items-start justify-start pt-[7px] px-0 pb-0 box-border min-w-[200px]">
  <div className="self-stretch flex flex-col items-start justify-start gap-[11px] max-w-full">
    <a
      className="self-stretch relative font-semibold text-[inherit] [text-decoration:none]"
      href="https://celebrating200years.noaa.gov/transformations/weather/radio.html"
      target="_blank"
    >
      NOAA Weather Radios-All Hazards: Saving Lives Across the Country
    </a>
    <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
      <div className="flex-1 relative inline-block max-w-full">
        A tornado is a tube of spinning air that
      </div>
    </div>
  </div>
</div>
</div>
</div>
    <b className="relative inline-block text-darkslategray-200 min-w-[52px]">
      Articles
    </b>
  </header>
  <div className="w-[570px] flex flex-col items-start justify-start py-0 pl-0 pr-5 box-border gap-2.5 max-w-full text-darkslategray-200">
    <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[21.6px] max-w-full">
      <div className="h-[75px] w-[75px] relative">
        <div className="absolute top-[75px] left-[75px] rounded-mini bg-silver-200 w-full h-full [transform:_rotate(180deg)] [transform-origin:0_0]" />
        <img
          className="absolute top-[0px] left-[0px] rounded-mini w-full h-full object-cover z-[1]"
          loading="lazy"
          alt=""
          src={ready}
        />
      </div>
      <div className="flex-1 flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border min-w-[294px] max-w-full">
        <div className="self-stretch flex flex-col items-start justify-start gap-3 max-w-full">
          <a
            className="w-[212.5px] relative font-semibold text-[inherit] flex items-center [text-decoration:none]"
            href="https://www.ready.gov/sites/default/files/2021-11/are-you-ready-guide.pdf"
            target="_blank"
          >
            Are You Ready?
          </a>
          <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
            <div className="flex-1 relative inline-block max-w-full">
              Prepare for Disasters Not if, but when: Every U.S. state and
              territory is at risk of disasters...
            </div>
          </div>
        </div>
      </div>
    </div>
    <b className="relative inline-block min-w-[46px]">Videos</b>
  </div>
  <section className="self-stretch flex flex-row flex-wrap items-start justify-start pb-6 gap-x-5 gap-y-[18.7px] text-left text-smi text-darkslategray-200 font-raleway">
{PreparednessVideos.map((video, index) => (
<div key={index} className="h-[225px] w-[272px] flex flex-col items-start justify-start">
  <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[5px]">
    <div className="relative w-full h-full cursor-pointer" onClick={() => openPreparednessModal(index)}>
      <img
        className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
        alt={`${video.title} thumbnail`}
        src={`https://img.youtube.com/vi/${video.VideoUrl.split('v=')[1]}/0.jpg`}
      />
      <div className="absolute inset-0 flex items-center justify-center">
        <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
        </svg>
      </div>
    </div>
    <div className="flex flex-col items-start justify-start py-0 pl-0 pr-5 gap-px">
      <b className="relative">{video.title}</b>
      <div className="relative text-3xs font-light">
        {video.author}
      </div>
    </div>
  </div>
  <Modal
    isOpen={preparednessModals[index]}
    onClose={() => closePreparednessModal(index)}
    videoSrc={`https://www.youtube.com/embed/${video.VideoUrl.split('v=')[1]}?autoplay=1`}
  />
</div>
))}
</section>
  <b className="self-stretch relative uppercase text-darkslateblue">
    Tornado
  </b>
  <b className="self-stretch relative">Articles</b>
  <footer className="w-[1076px] rounded-[20px] flex flex-row flex-wrap items-center justify-center py-1.5 px-[73px] box-border gap-x-[21px] gap-y-[17.6px] min-h-[399px] max-w-full text-left text-sm text-darkslateblue font-raleway lg:pl-9 lg:pr-9 lg:box-border">
    <div className="w-[453px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[212.5px] relative font-semibold flex items-center">
        Tornado | Signs and Clues
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <a
          className="flex-1 relative text-[inherit] inline-block [text-decoration:none] max-w-full"
          href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html"
          target="_blank"
        >
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </a>
      </div>
    </div>
    <div className="w-[453px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[408.2px] relative font-semibold flex items-center max-w-full">
        Prepare! Don't let Tornadoes Take You by Surprise
      </div>
      <div className="w-[409.2px] flex flex-row items-start justify-start py-0 px-px box-border max-w-full text-gray1-100">
        <a
          className="w-[452.4px] relative text-[inherit] flex items-center shrink-0 [text-decoration:none] max-w-[112%]"
          href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html"
          target="_blank"
        >
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </a>
      </div>
    </div>
    <div className="w-[453px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[318.8px] relative font-semibold flex items-center">
        What Should You Do Before a Tornado?
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <a
          className="flex-1 relative text-[inherit] inline-block [text-decoration:none] max-w-full"
          href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html"
          target="_blank"
        >
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </a>
      </div>
    </div>
    <div className="w-[453px] flex flex-col items-start justify-start gap-[15px] max-w-full">
      <div className="self-stretch relative font-semibold">
        Find Shelter Locations from High Winds in the Places Where You Spend
        a Lot of Time
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <div className="flex-1 relative inline-block max-w-full">
          <a
            className="text-[inherit]"
            href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html"
            target="_blank"
          >
            Damaged Lorem ipsum dolor sit amet. Ad voluptas.
          </a>
        </div>
      </div>
    </div>
    <div className="w-[453px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[320.9px] relative font-semibold flex items-center max-w-full">
        What Should You Do During a Tornado?
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <div className="flex-1 relative inline-block max-w-full">
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </div>
      </div>
    </div>
    <div className="w-[454px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[294.3px] relative font-semibold flex items-center">
        What to do During a Tornado
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <a
          className="flex-1 relative text-[inherit] inline-block [text-decoration:none] max-w-full"
          href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html"
          target="_blank"
        >
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </a>
      </div>
    </div>
    <div className="w-[454px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[294.3px] relative font-semibold flex items-center">
        Tornado Preparedness Checklist
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <a
          className="flex-1 relative text-[inherit] inline-block [text-decoration:none] max-w-full"
          href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html"
          target="_blank"
        >
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </a>
      </div>
    </div>
    <div className="w-[454px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[294.3px] relative font-semibold flex items-center">
        Understand Tornado Alerts
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <a
          className="flex-1 relative text-[inherit] inline-block [text-decoration:none] max-w-full"
          href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html"
          target="_blank"
        >
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </a>
      </div>
    </div>
    <div className="w-[454px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[294.3px] relative font-semibold flex items-center">
        Preparing for a Tornado
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <a
          className="flex-1 relative text-[inherit] inline-block [text-decoration:none] max-w-full"
          href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html"
          target="_blank"
        >
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </a>
      </div>
    </div>
    <div className="w-[454px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[294.3px] relative font-semibold flex items-center">
        Stay Safe During a Tornado
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <a
          className="flex-1 relative text-[inherit] inline-block [text-decoration:none] max-w-full"
          href="https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html"
          target="_blank"
        >
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </a>
      </div>
    </div>
  </footer>
  <b className="self-stretch relative">Videos</b>
  <section className="self-stretch flex flex-row flex-wrap items-start justify-start gap-x-5 gap-y-[18.7px] text-left text-smi text-darkslategray-200 font-raleway">
    {TornadoVideos.map((video, index) => (
<div key={index} className="h-[225px] w-[272px] flex flex-col items-start justify-start">
<div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[5px]">
  <div className="relative w-full h-full cursor-pointer" onClick={() => openTornadoModal(index)}>
    <img
      className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
      alt={`${video.title} thumbnail`}
      src={`https://img.youtube.com/vi/${video.VideoUrl.split('v=')[1]}/0.jpg`}
    />
    <div className="absolute inset-0 flex items-center justify-center">
      <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
      </svg>
    </div>
  </div>
  <div className="flex flex-col items-start justify-start py-0 pl-0 pr-5 gap-px">
    <b className="relative">{video.title}</b>
    <div className="relative text-3xs font-light">
      {video.author}
    </div>
  </div>
</div>
<Modal
  isOpen={tornadoModals[index]}
  onClose={() => closeTornadoModal(index)}
  videoSrc={`https://www.youtube.com/embed/${video.VideoUrl.split('v=')[1]}?autoplay=1`}
/>
</div>
))}
  </section>
</div>;
};

export default Preparedness;
