import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  actionBegin,
} from "@syncfusion/ej2-react-grids";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, auth } from "../../firebase";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import emgMgtService from "../../service/center/EmgMgtService";

const EmergencyMgtDocuments = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [imgFileSize, setImgFileSize] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });
        console.log(data);

        const docs = await emgMgtService.fetchDocuments(userId, "documents");
        setEmergencyDocumentsData(docs);
        console.log("Data: ", docs);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const sortSettings = {
    allowSorting: true,
  };

  const editing = {
    allowDeleting: true,
    allowAdding: true,
    allowEditing: true,
    showDeleteConfirmDialog: true,
    mode: "Dialog",
  };

  const filterSettings = {
    type: "Menu",
  };
  let elem;
  let uploadObj;
  let strm;
  let selectedRow;

  const datepickerparams = {
    params: {
      format: "MM/dd/yyyy",
      showClearButton: false,
      showTodayButton: false,
    },
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const [emergencyDocumentsData, setEmergencyDocumentsData] = useState([]);
  const [isEmergencyDocumentsVisible, setIsEmergencyDocumentsVisible] =
    useState(true);
  const [
    lastEmergencyDocumentsEditedTime,
    setLastEmergencyDocumentsEditedTime,
  ] = useState(null);
  const toggleEmergencyDocuments = (event) => {
    event.preventDefault();
    setIsEmergencyDocumentsVisible(!isEmergencyDocumentsVisible);
  };

  const emergencyDocumentsGridRef = useRef(null);

  function compressImage(file, maxSizeMB) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxWidth = img.width;
          const maxHeight = img.height;
          let width = img.width;
          let height = img.height;

          // Resize the image maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.floor((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.floor((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.9; // Start with high quality
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          // Compress the image by reducing quality until it's below maxSizeMB
          while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
            quality -= 0.1;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          resolve(dataUrl);
        };

        img.onerror = function (error) {
          reject(error);
        };
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  // Upload image to Firebase Storage
  async function uploadImageToStorage(filePath, dataUrl) {
    const storage = getStorage();
    const storageRef = ref(storage, filePath);

    // Upload the base64 string directly
    await uploadString(storageRef, dataUrl, "data_url");

    // Get the download URL
    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  }

  const handleAddClickEmergencyDocuments = () => {
    if (emergencyDocumentsGridRef.current) {
      emergencyDocumentsGridRef.current.addRecord();
    }
  };

  function gridTemplate(props) {
    const src = props.photoEmergencyDocuments; // Get the image path from the data source

    return (
      <div className="flex flex-col items-center">
        {src ? (
          <img
            src={src}
            alt={props.documentsID}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <div
            className="flex flex-col items-center justify-center"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              border: "1px solid #ddd",
            }}
          >
            <p>No Image</p>
          </div>
        )}
      </div>
    );
  }

  const [uploadedFileSizes, setUploadedFileSizes] = useState({});

  const template = gridTemplate;
  const imageEditEmergencyDocuments = {
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
                  <div class="flex flex-col justify-center w-full">
                      <label htmlFor="access" class="block mb-2 text-xs text-gray-500">Photo Attachment</label>
                      <div class="flex flex-col items-center">
                          <div id="imagePreview" class="mb-4 flex flex-col items-center justify-center">
                              <!-- Image will be dynamically inserted here -->
                          </div>
                          <div id="dropArea">
                              <label class="upload-button" id="uploadLabel">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                      <polyline points="17 8 12 3 7 8"></polyline>
                                      <line x1="12" y1="3" x2="12" y2="15"></line>
                                  </svg>
                                  Upload Image
                              </label>
                              <input type="file" id="defaultUpload" style="display:none;" />
                          </div>
                      </div>
                  </div>
              `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagePreview = elem.querySelector("#imagePreview");

      // If there's an existing image, update the button text to "Reupload Image"
      if (args.rowData.photoEmergencyDocuments) {
        uploadLabel.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="17 8 12 3 7 8"></polyline>
          <line x1="12" y1="3" x2="12" y2="15"></line>
        </svg>
        Reupload Image
      `;
        strm = args.rowData.photoEmergencyDocuments;
        displayUploadedImage(
          args.rowData.photoEmergencyDocuments,
          "Current Image"
        );
      } else {
        imagePreview.innerHTML = "";
      }

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        multiple: false,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);
            const fileSize = file.size;
            setImgFileSize(fileSize);
            setUploadedFileSizes((prev) => ({
              ...prev,
              [selectedRow?.documentsID]: fileSize,
            }));

            const filePath = `emg-mgt-docs/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );
            strm = downloadURL;
            console.log("Image uploaded successfully:", downloadURL);

            displayUploadedImage(downloadURL, args.file.name);
          } catch (error) {
            console.error("Error during upload:", error);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function displayUploadedImage(imageSrc, fileName) {
        imagePreview.innerHTML = `
                      <img src="${imageSrc}" alt="Uploaded Image" style="width: 100px; height: 100px; border-radius: 50%; object-fit: cover;" />
                      <p class="text-sm text-blue-500 mt-2">${fileName}</p>
                  `;
      }

      // Display the current image if available
      if (args.rowData.photoEmergencyDocuments) {
        strm = args.rowData.photoEmergencyDocuments;
        displayUploadedImage(
          args.rowData.photoEmergencyDocuments,
          "Current Image"
        );
      } else {
        imagePreview.innerHTML = "";
      }
    },
  };

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedData[key] = data[key] || "";
      }
    }
    return sanitizedData;
  };

  const actionBeginEmergencyDocuments = async (args) => {
    if (
      emergencyDocumentsGridRef.current &&
      (args.requestType === "beginEdit" || args.requestType === "add")
    ) {
      const cols = emergencyDocumentsGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "modifiedDate" || col.field === "fileSize") {
          col.visible = false;
        }
      }
    }
    if (emergencyDocumentsGridRef.current && args.requestType === "save") {
      const cols = emergencyDocumentsGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "modifiedDate" || col.field === "fileSize") {
          col.visible = true;
        }
      }

      const currentDate = new Date();
      const formattedDate = `${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${currentDate
        .getDate()
        .toString()
        .padStart(2, "0")}-${currentDate.getFullYear()}`;
      args.data.modifiedDate = formattedDate;

      const rowId = args.data.documentsID;
      if (uploadedFileSizes[rowId]) {
        args.data.fileSize = formatFileSize(uploadedFileSizes[rowId]);
        setUploadedFileSizes((prev) => {
          const newSizes = { ...prev };
          delete newSizes[rowId];
          return newSizes;
        });
      } else if (args.action === "edit") {
        args.data.fileSize = formatFileSize(imgFileSize);
      } else if (args.action === "add" && !args.data.photoEmergencyDocuments) {
        args.data.fileSize = "N/A";
      }

      args.data.deleted = false;
    }
    if (args.requestType === "save" && args.action === "add") {
      args.data.documentsID = generateUniqueId();

      const sanitizedData = sanitizeData(args.data);
      await emgMgtService.addDocument(userId, sanitizedData);
      console.log("Grid: ", emergencyDocumentsData);
    }
    if (args.requestType === "beginEdit") {
      selectedRow = { ...args.rowData };
      console.log("Id", args.rowData.documentsID);
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.documentsID;
          
          console.log("Attempting to mark document as deleted with ID:", documentId);
  
          // Instead of deleting, update the document to set deleted: true
          await emgMgtService.updateDocument(userId, documentId, { deleted: true }, "documents");
          console.log("Document marked as deleted:", documentId);
  
          // If you still want to delete the file from storage, keep this block
          const fileUrl = docData.photoEmergencyDocuments;
          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);
            await deleteObject(fileRef);
            console.log("File deleted from storage:", fileUrl);
          }
        }
      } catch (error) {
        console.error("Error marking document as deleted:", error);
      }
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0 || bytes === undefined || bytes === null) return "N/A";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const actionCompleteEmergencyDocuments = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.documentsID;
  
        const sanitizedData = sanitizeData(args.data);
        sanitizedData.deleted = false; // Ensure deleted is false when editing
  
        await emgMgtService.updateDocument(
          userId,
          documentId,
          sanitizedData,
          "documents"
        );
  
        setEmergencyDocumentsData((prevData) =>
          prevData.map((item) =>
            item.documentsID === args.data.documentsID ? { ...args.data, deleted: false } : item
          )
        );
      } else if (args.action === "add") {
        setEmergencyDocumentsData((prevData) => [...prevData, { ...args.data, deleted: false }]);
      }
      console.log("Data saved successfully:", emergencyDocumentsData);
      setLastEmergencyDocumentsEditedTime(new Date());
    } else if (args.requestType === "delete") {
      setEmergencyDocumentsData((prevData) =>
        prevData.map((item) => 
          item.documentsID === args.data[0].documentsID ? { ...item, deleted: true } : item
        )
      );
      console.log("Data marked as deleted:", args.data[0]);
      setLastEmergencyDocumentsEditedTime(new Date());
    }
  };

  return (
    <div
      className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start px-2.5 py-[15px] ${className}`}
    >
      <div className="mb-4 flex flex-row items-center justify-start">
        <div className="flex flex-row items-start justify-start gap-1.5 font-raleway text-xs text-darkslateblue">
          <a className="relative font-medium leading-[16px] text-[inherit] [text-decoration:none]">
            Recovery Plan
          </a>
          <div className="relative font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative font-medium leading-[16px] text-[inherit] [text-decoration:none]">
            Emergency Management Documents
          </a>
        </div>
      </div>

      <section className="mt-4 box-border h-auto self-stretch overflow-hidden rounded-3xs border-[1px] border-solid border-silver-100 bg-white p-5">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
              Uploaded Files
            </h2>
            <p className="font-poppins text-xs font-medium text-gray-500">
              Last Edited:{" "}
              {formatLastEditedTime(lastEmergencyDocumentsEditedTime)}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
              onClick={handleAddClickEmergencyDocuments}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>

            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleEmergencyDocuments}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isEmergencyDocumentsVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isEmergencyDocumentsVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={emergencyDocumentsGridRef}
              dataSource={emergencyDocumentsData.filter(doc => doc.deleted !== true)}
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionBegin={actionBeginEmergencyDocuments}
              actionComplete={actionCompleteEmergencyDocuments}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="documentsID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="photoEmergencyDocuments"
                  headerText="Image"
                  width="100"
                  template={template}
                  edit={imageEditEmergencyDocuments}
                />
                <ColumnDirective field="owner" headerText="Owner" width="100" />
                <ColumnDirective
                  field="modifiedDate"
                  headerText="Last Modified"
                  width="100"
                  format="MM-dd-yyyy"
                  visible={true}
                />
                <ColumnDirective
                  field="fileSize"
                  headerText="Size"
                  width="100"
                  visible={true}
                />

                <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
              />
            </GridComponent>
          </div>
        )}
      </section>
    </div>
  );
};

export default EmergencyMgtDocuments;
