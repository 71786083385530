import React, { useState, useRef, useEffect } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { countries, USStates } from '../datasource';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Profile = ({ isOpen, onClose, data, onUpdate }) => {
  const [formData, setFormData] = useState(data);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (data) {
      setFormData(data);
      setPreviewURL(data.profile_image || null);
    }
  }, [data]);

  if (!isOpen || !formData) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const resizeImage = (file, maxWidth = 300, maxHeight = 300, quality = 0.7) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              }));
            },
            'image/jpeg',
            quality
          );
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewURL(URL.createObjectURL(file));
    }
  };

  const handleEditPhotoClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;

    if (user) {
      try {
        let updatedData = { ...formData };

        if (selectedFile) {
          const resizedFile = await resizeImage(selectedFile);
          const storage = getStorage();
          const storageRef = ref(storage, `profile_images/${user.uid}`);
          await uploadBytes(storageRef, resizedFile);
          const downloadURL = await getDownloadURL(storageRef);
          updatedData.profile_image = downloadURL;
        }

        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, updatedData);
        console.log('Profile updated successfully');
        onUpdate(updatedData);
        onClose();
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    } else {
      console.error('No user is signed in');
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
      <div className='bg-white w-[780px] overflow-hidden rounded-lg'>
        <div className="bg-blue-500 text-white p-4 flex justify-between items-center">
          <h2 className="text-l font-raleway">Profile</h2>
          <button onClick={onClose} className="text-[24px] bg-blue-500 rounded-full text-white cursor-pointer">&times;</button>
        </div>
        <div className="p-6">
          <div className="flex mb-6 justify-between">
            <div className='flex'>
              {previewURL ? (
                <img src={previewURL} alt="Profile" className="w-24 h-24 rounded-full mr-4" />
              ) : (
                <div className="w-24 h-24 rounded-full mr-4 bg-blue-500 flex items-center justify-center text-white text-2xl font-semibold">
                  {formData.first_name ? formData.first_name.charAt(0).toUpperCase() : '?'}
                </div>
              )}
              <div className='flex flex-col justify-end items-end'>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
                <button
                  onClick={handleEditPhotoClick}
                  className="bg-blue-500 text-white px-4 py-2 rounded-full text-sm"
                >
                  Edit Photo
                </button>
              </div>
            </div>
            <div className='flex flex-col items-center justify-center pr-8 border-b'>
              <h3 className="text-[24px] font-bold text-gray-800 mb-1">
                {formData.first_name || ''} {formData.last_name || ''}
              </h3>
              <p className="text-gray-800 mb-2">{formData.email || ''}</p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <TextBoxComponent
                placeholder="First Name"
                floatLabelType="Auto"
                value={formData.first_name}
                name="first_name"
                onChange={handleInputChange}
                cssClass="e-outline"
              />
              <TextBoxComponent
                placeholder="Last Name"
                floatLabelType="Auto"
                value={formData.last_name}
                name="last_name"
                onChange={handleInputChange}
                cssClass="e-outline"
              />
              <TextBoxComponent
                placeholder="Phone Number"
                floatLabelType="Auto"
                value={formData.mobile_number}
                name="mobile_number"
                onChange={handleInputChange}
                cssClass="e-outline"
              />
              <DropDownListComponent
                placeholder="Select a country"
                floatLabelType="Auto"
                dataSource={countries}
                fields={{ text: 'name', value: 'abbreviation' }}
                value={formData.country}
                name="country"
                change={(e) => handleInputChange({ target: { name: 'country', value: e.value } })}
                cssClass="e-outline"
              />
            </div>
            <div className="mb-4">
              <TextBoxComponent
                placeholder="Address 1"
                floatLabelType="Auto"
                value={formData.address1}
                name="address1"
                onChange={handleInputChange}
                cssClass="e-outline"
              />
            </div>
            <div className="mb-4">
              <TextBoxComponent
                placeholder="Address 2"
                floatLabelType="Auto"
                value={formData.address2}
                name="address2"
                onChange={handleInputChange}
                cssClass="e-outline"
              />
            </div>
            <div className="grid grid-cols-4 gap-4 mb-6">
              <TextBoxComponent
                placeholder="City"
                floatLabelType="Auto"
                value={formData.city}
                name="city"
                onChange={handleInputChange}
                cssClass="e-outline"
              />
              <DropDownListComponent
                placeholder="State"
                floatLabelType="Auto"
                dataSource={USStates}
                fields={{ text: 'name', value: 'abbreviation' }}
                value={formData.state}
                name="state"
                change={(e) => handleInputChange({ target: { name: 'state', value: e.value } })}
                cssClass="e-outline"
              />
              <TextBoxComponent
                placeholder="Zip Code"
                floatLabelType="Auto"
                value={formData.zip}
                name="zip"
                onChange={handleInputChange}
                cssClass="e-outline"
              />
              <TextBoxComponent
                placeholder="County"
                floatLabelType="Auto"
                value={formData.county}
                name="county"
                onChange={handleInputChange}
                cssClass="e-outline"
              />
            </div>
            <div className='flex justify-between'>
              <p className="text-sm text-gray-800 pt-2">*Quick Note: Fields with asterisks are required</p>
              <div className="flex justify-end">
                <button type="button" onClick={onClose} className="border border-blue-800 text-blue-800 px-6 py-2 rounded-full mr-4 cursor-pointer">Cancel</button>
                <button type="submit" className="bg-blue-500 text-white px-6 py-2 rounded-full cursor-pointer">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;