import React, { useState, useEffect, useRef } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Edit, Toolbar, CommandColumn, Inject } from "@syncfusion/ej2-react-grids";

import { pastRecoveryPlan as initialData } from "./datasource"; // Rename import
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from '../firebase';  // Import Firebase config
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import "./style.css";

const MyHistory = ({ className = "" }) => {
  const [data, setData] = useState(initialData); // Use a different name for state variable
  const pastRecoveryPlanGridRef = useRef(null);

  const filterSettings = {
    type: "Menu",
  };

  const onQueryCellInfo = (args) => {
    if (args.column.field === 'subCategory') {
      const value = args.data.subCategory;
      args.cell.innerHTML = `
        <div style="
          background-color: #EBE5FC;
          color: #5227CC;
          padding: 5px 10px;
          border-radius: 15px;
          display: inline-block;
          font-weight: bold;
          text-align: center;
        ">${value}</div>
      `;
    }

    if (args.column.field === 'photoAttachment') {
        const value = args.data.photoAttachment;
        const imageUrl = '';
        args.cell.innerHTML = `
          <div style="display: flex; align-items: center;">
            <img src="${imageUrl}" alt="Image" style="
              width: 24px;
              height: 24px;
              margin-right: 5px;
              border-radius: 5%;
            "/>
            <span>${value}</span>
          </div>
        `;
    }
  };

  const handleAddPastRecoveryPlan = () => {
    if (pastRecoveryPlanGridRef.current) {
       pastRecoveryPlanGridRef.current.addRecord();
    }
  };

  return(
    <>
        <div className={`flex max-w-full flex-col gap-5 p-4 ${className}`}>
           <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="flex items-center gap-2 font-raleway text-xs text-darkslateblue">
                <a className="font-medium">My History</a>
                <span className="text-gray-400 text-base font-semibold">/</span>
                <span className="font-medium">Past Recovery Plan</span>
              </div>
           </div>
        </div>
        <div className="w-full relative flex flex-col items-start justify-center leading-[normal] tracking-[normal]">
          <div className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch rounded-3xs border-[1px] border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left font-raleway text-xs text-darkslateblue">
            <section className="self-stretch bg-white flex flex-col items-start justify-start max-w-full">
              <header className="self-stretch flex flex-row items-center justify-between top-[0] z-[99] sticky gap-5 max-w-full text-left text-xs text-dodgerblue font-raleway">
                <div className="w-[159px] flex flex-col items-start justify-start mq450:w-0 mq750:hidden">
                  <a className="[text-decoration:none] relative leading-[21px] font-bold text-[inherit] inline-block min-w-[82px] whitespace-nowrap">
                    Recovery Plan
                  </a>
                  <div className="self-stretch relative leading-[21px] font-medium text-silver-200 whitespace-nowrap mq450:hidden">
                    Last Edited: 4 minutes ago
                  </div>
                </div>
                <div className="w-[762.5px] flex flex-row items-center justify-end py-0 pl-[341px] pr-0 box-border gap-2.5 max-w-full text-white mq450:hidden mq450:pl-5 mq450:box-border mq750:pl-[170px] mq750:box-border">
                  <div className="flex-1 flex flex-col items-start justify-start">
                    <div className="self-stretch rounded-md bg-dodgerblue flex flex-col items-center justify-center py-1.5 px-5">
                      <div className="self-stretch flex flex-row items-center justify-start gap-2">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M11.7023 1.83203C13.3363 1.83203 14.667 3.18552 14.667 4.84839L14.667 8.15579C14.667 9.81526 13.3403 11.1654 11.709 11.1654L4.29832 11.1654C2.66366 11.1654 1.33366 9.81187 1.33366 8.14833L1.33366 4.84228C1.33366 3.18213 2.66033 1.83203 4.29166 1.83203L4.91833 1.83203L11.7023 1.83203Z" fill="white"/>
                            <path d="M8.36396 15.0188L10.3033 13.1216C10.5033 12.9255 10.5033 12.61 10.302 12.4146C10.1006 12.2198 9.77596 12.2204 9.57596 12.4159L8.51396 13.4554L8.51396 12.4587L8.51396 6.86518C8.51396 6.58924 8.28396 6.36523 8.00062 6.36523C7.71662 6.36523 7.48729 6.58924 7.48729 6.86518L7.48729 13.4554L6.42529 12.4159C6.22529 12.2204 5.90062 12.2198 5.69929 12.4146C5.59862 12.5126 5.54796 12.6405 5.54796 12.7691C5.54796 12.8963 5.59862 13.0242 5.69796 13.1216L7.63662 15.0188C7.73329 15.113 7.86396 15.1662 8.00062 15.1662C8.13662 15.1662 8.26729 15.113 8.36396 15.0188" fill="white"/>
                        </svg>
                        <a className="[text-decoration:none] relative font-semibold text-[inherit] whitespace-nowrap">
                          Download Unmet Needs Report
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start">
                    <div className="rounded-md bg-dodgerblue flex flex-col items-center justify-center py-1.5 px-5">
                      <div className="flex flex-row items-center justify-start gap-2">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M14.0667 6.89191H13.2646V6.10774C13.2646 5.77296 12.9962 5.5 12.6653 5.5C12.3352 5.5 12.066 5.77296 12.066 6.10774V6.89191H11.2653C10.9345 6.89191 10.666 7.16487 10.666 7.49965C10.666 7.83443 10.9345 8.1074 11.2653 8.1074H12.066V8.89226C12.066 9.22704 12.3352 9.5 12.6653 9.5C12.9962 9.5 13.2646 9.22704 13.2646 8.89226V8.1074H14.0667C14.3968 8.1074 14.666 7.83443 14.666 7.49965C14.666 7.16487 14.3968 6.89191 14.0667 6.89191Z" fill="white"/>
                            <path d="M6.33301 10.5098C3.63582 10.5098 1.33301 10.941 1.33301 12.6638C1.33301 14.386 3.62181 14.8328 6.33301 14.8328C9.02952 14.8328 11.333 14.4015 11.333 12.6787C11.333 10.9559 9.04421 10.5098 6.33301 10.5098Z" fill="white"/>
                            <path opacity="0.4" d="M6.33284 8.86882C8.16922 8.86882 9.64126 7.37781 9.64126 5.51776C9.64126 3.65771 8.16922 2.16602 6.33284 2.16602C4.49645 2.16602 3.02441 3.65771 3.02441 5.51776C3.02441 7.37781 4.49645 8.86882 6.33284 8.86882Z" fill="white"/>
                        </svg>
                        <a className="[text-decoration:none] relative font-semibold text-[inherit] inline-block min-w-[68px] whitespace-nowrap">
                          Give Access
                        </a>
                      </div>
                    </div>
                  </div>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 16L12 9L19 16" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </header>
              <section className="w-full rounded-3xs pb-5 pr-7 pt-[14px] text-left font-raleway text-xs text-darkslateblue">
                <div>
                  <GridComponent
                    ref={pastRecoveryPlanGridRef}
                    dataSource={data} // Use state variable
                    allowFiltering={true}
                    filterSettings={filterSettings}
                    allowSorting={true}
                    allowPaging={true}
                    pageSettings={{ pageSize: 10 }}
                    allowTextWrap={true}
                    queryCellInfo={onQueryCellInfo}
                    style={{
                      border: 'none'
                    }}
                  >
                    <ColumnsDirective>
                      <ColumnDirective field='categoryID' headerText='Category ID' isPrimaryKey={true} visible={false} />
                      <ColumnDirective field='category' headerText='Category' filter={{ type: 'CheckBox' }}/>
                      <ColumnDirective field='subCategory' headerText='Sub Category' filter={{ type: 'CheckBox' }} />
                      <ColumnDirective field='descOfDamage' headerText='Description of Damage' filter={{ type: 'CheckBox' }} />
                      <ColumnDirective field='photoAttachment' headerText='Photo Attachment' filter={{ type: 'CheckBox' }} />
                      <ColumnDirective field='estimateToRepair' headerText='Estimate To Repair' filter={{ type: 'CheckBox' }} />
                      <ColumnDirective field='donated' headerText='Donated Goods/ Services' filter={{ type: 'CheckBox' }} />
                      <ColumnDirective field='valueOfDonated' headerText='Value of Donated Goods/ Services' filter={{ type: 'CheckBox' }} />
                      <ColumnDirective field='otherSource' headerText='Other Source Of Funding' filter={{ type: 'CheckBox' }} />
                      <ColumnDirective field='amountOfFunding' headerText='Amount Of Funding' filter={{ type: 'CheckBox' }} />
                      <ColumnDirective field='fundingGap' headerText='Funding Gap' filter={{ type: 'CheckBox' }} />
                    </ColumnsDirective>
                    <Inject services={[Edit, Toolbar, Filter, Sort, Page, CommandColumn]} />
                  </GridComponent>
                </div>
              </section>
            </section>
          </div>
         </div>
    </>
  );
};

export default MyHistory;