import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

const emgMgtService = {
  fetchDocuments: async (userId, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "emg-mgt-docs",
        userId,
        collectionName
      );
      const documentsSnapshot = await getDocs(documentsCollectionRef);

      const documentsList = documentsSnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          id: doc.id,
          ...docData,
        };
      });

      return documentsList;
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  },
  addDocument: async (userId, documentData) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "emg-mgt-docs",
        userId,
        "documents"
      );

      await addDoc(documentsCollectionRef, documentData);

      console.log("Document successfully added");
    } catch (error) {
      console.error("Error adding document ", error);
      throw error;
    }
  },

  updateDocument: async (userId, documentId, documentData, type) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "emg-mgt-docs",
        userId,
        type
      );

      const q = query(
        documentsCollectionRef,
        where("documentsID", "==", documentId)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Update each matching document (should be only one in your case)
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await updateDoc(documentRef, documentData);
        });
        console.log("Document successfully updated!");
      } else {
        console.error("No document found with the given ID to update.");
        throw new Error("No document found with the given ID to update.");
      }
    } catch (error) {
      console.error("Error updating document in lockbox: ", error);
      throw error;
    }
  },

  deleteDocument: async (userId, documentId, type) => {
    try {
      // Log documentId to check if it's defined
      console.log("Deleting document with ID:", documentId);

      if (!documentId) {
        throw new Error("documentId is undefined or null");
      }

      // Create a query to find the document with the given documentId
      const documentsCollectionRef = collection(
        db,
        "emg-mgt-docs",
        userId,
        type
      );

      const q = query(
        documentsCollectionRef,
        where("documentsID", "==", documentId)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Delete each matching document (should be only one in your case)
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await deleteDoc(documentRef);
        });
        console.log("Document successfully deleted!");
      } else {
        console.error("No document found with the given ID to delete.");
        throw new Error("No document found with the given ID to delete.");
      }
    } catch (error) {
      console.error("Error deleting document from lockbox: ", error);
      throw error;
    }
  },
};

export default emgMgtService;
