import React, { useState, useEffect, useRef } from "react";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  Toolbar,
  CommandColumn,
  Inject,
} from "@syncfusion/ej2-react-grids";

import { USStates } from "./../datasource";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import partnerProfileService from "../../service/partner/PartnerProfileService";
import "./../style.css";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DropDownListComponent,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { formatDistanceToNow } from "date-fns";

const PartnerProfile = ({ className = "" }) => {
  const [data, setData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [partnerProfile, setPartnerProfile] = useState([]);
  const [partnerMembers, setPartnerMembers] = useState([]);
  const [partnerDisasters, setPartnerDisasters] = useState([]);
  const [disastersinvolvement, setDisastersinvolvement] = useState([]);
  const gridRefMembers = useRef(null);
  const gridRefDisasters = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [lastPartnerEditedTime, setLastPartnerEditedTime] = useState(null);
  const [tempFormData, setTempFormData] = useState({});
  const [membersFormData, setMembersFormData] = useState(null);
  const [tempMembersFormData, setTempMembersFormData] = useState({});
  const [disastersFormData, setDisastersFormData] = useState(null);
  const [tempDisastersFormData, setTempDisastersFormData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [lastMembersEditedTime, setLastMembersEditedTime] = useState(null);
  const [disastersLastEditedTime, setDisastersLastEditedTime] = useState(null);
  const [servicesProvided, setServicesProvided] = useState([]);
  const [willingToPartner, setWillingToPartner] = useState(null);
  const [disasterUpdatedTime, setDisasterUpdatedTime] = useState(null);

  const services = [
    { id: 1, name: "Funding" },
    { id: 2, name: "Debris Removal" },
    { id: 3, name: "Case Management" },
    { id: 5, name: "House Repair" },
    { id: 6, name: "House Rebuilds" },
    { id: 7, name: "Volunteer Labor" },
    { id: 8, name: "Spriritual/Mental Care" },
    { id: 6, name: "Donation of Goods" },
  ];

  const fields = { text: "name", value: "name" };

  const yesNoOptions = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  const changeWilling = (e) => {
    setWillingToPartner(e.itemData.value);
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch partner profile data
      const partnerData = await partnerProfileService.getPartnerProfile(
        "users",
        userId
      );

      if (partnerData) {
        // Set partner profile data
        setData(partnerData);
        setTempFormData(partnerData);
        setDisasterUpdatedTime(new Date(tempFormData.lastUpdatedDisasters));
        console.log("Time ", partnerData.lastUpdatedDisasters);

        // Handle last edited time
        if (partnerData.updated_at) {
          setLastEditedTime(partnerData.updated_at.toDate());
        } else {
          setLastEditedTime(null); // Handle case where updated_at is missing
        }
        console.log("Partner data: ", partnerData);

        // Set members data
        const membersData = partnerData.members || {};
        setMembersFormData(membersData);
        setTempMembersFormData(membersData);

        // Set disasters data
        const disastersData = partnerData.disasters || {};
        setDisastersFormData(disastersData);
        setTempDisastersFormData(disastersData);

        // Handle last partner edited time
        if (membersData.updated_at) {
          setLastPartnerEditedTime(membersData.updated_at.toDate());
        } else {
          setLastPartnerEditedTime(null);
        }
        console.log("Members data: ", membersData);

        // Fetch partner members data
        const partnerMembersData = await partnerProfileService.getMembers(
          userId
        );
        setPartnerMembers(partnerMembersData);

        // Fetch partner disasters data
        const partnerDisastersData = await partnerProfileService.getDisasters(
          userId
        );
        setPartnerDisasters(partnerDisastersData);
        console.log("Disasters data: ", partnerDisastersData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const startEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(true);
    console.log("Edit");
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    const currentTime = new Date();
    const updatedData = {
      ...tempFormData,
      
      updated_at: currentTime,
    };
  
    console.log("Saving changes with data: ", updatedData);
  
    try {
      await partnerProfileService.updatePartnerProfile(
        "users",
        userId,
        updatedData
      );
      setData(updatedData);
      setIsEditMode(false);
      setLastEditedTime(currentTime);
      console.log("Changes saved successfully.");
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const cancelEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(false);
  };

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const handleAddPartnerProfile = () => {
    if (gridRefMembers.current) {
      gridRefMembers.current.addRecord();
    }
  };

  const handleAddDisaster = () => {
    if (gridRefDisasters.current) {
      gridRefDisasters.current.addRecord();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure memberID is set and status is true
        const newMemberData = {
          ...args.data,
          memberId: args.data.memberId || generateUniqueID(),
          is_deleted: false, // Set status to true when adding
        };
        console.log("Adding new member:", newMemberData);
        await partnerProfileService.addMember(userId, newMemberData);
      } else if (args.action === "edit") {
        await partnerProfileService.updateMember(userId, args.data.id, args.data);
      }
      setLastMembersEditedTime(new Date());
      fetchData(); // Refresh the data after adding or editing
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      const updatedData = {
        ...args.data[0],
        is_deleted: true,
      };
      await partnerProfileService.updateMember(userId, updatedData.id, updatedData);
      setLastMembersEditedTime(new Date());
      fetchData(); // Refresh the data after deleting
    }else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit" ? "Edit Member" : "Add New Member";
  
        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }
  
          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );
  
            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }
  
            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  const actionBeginDisaster = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure disasterId is set and status is true
        const newDisasterData = {
          ...args.data,
          disasterId: args.data.disasterId || generateUniqueID(),
          is_deleted: false, // Set status to true when adding
        };
        console.log("Adding new disaster:", newDisasterData);
        await partnerProfileService.addDisasters(userId, newDisasterData);
      } else if (args.action === "edit") {
        await partnerProfileService.updateDisasters(userId, args.data.id, args.data);
      }
      setDisastersLastEditedTime(new Date());
      fetchData(); // Refresh the data after adding or editing
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      const updatedData = {
        ...args.data[0],
        is_deleted: true,
      };
      await partnerProfileService.updateDisasters(userId, updatedData.id, updatedData);
      setDisastersLastEditedTime(new Date());
      fetchData(); // Refresh the data after deleting
    
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      await partnerProfileService.deleteDisaster(userId, args.data[0].id);
      setDisastersLastEditedTime(new Date());
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Disaster Info"
            : "Add Disaster Info";
  
        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }
  
          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );
  
            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }
  
            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  const filterSettings = {
    type: "Menu",
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isNewlyRegistered = localStorage.getItem('newlyRegisteredPartner');
    if (isNewlyRegistered === 'true') {
      setShowPopup(true);
      localStorage.removeItem('newlyRegisteredPartner'); // Remove the flag after showing the popup
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <div style={{ fontSize: '48px', marginBottom: '20px' }}>🏢</div>
        <h2 style={{ fontSize: '24px', fontWeight: 'normal', color: '#1e1919', marginBottom: '10px' }}>
          Welcome to your new Partner Profile!
        </h2>
        <p style={{ fontSize: '16px', color: '#637381', marginBottom: '20px' }}>
          Please complete your profile to maximize our partnership benefits.
        </p>
        <button 
          onClick={handleClosePopup}
          style={{
            backgroundColor: '#0061fe',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            fontSize: '14px',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Got it!
        </button>
      </div>
    );
  };

  return (
    <div
      className={`box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] text-left font-raleway text-xs leading-[normal] tracking-[normal] text-darkslateblue ${className}`}
    >
      <div className={`flex max-w-full flex-col gap-5 p-2 ${className}`}>
        <div className="flex flex-wrap items-center justify-between gap-4">
          <div className="flex items-center gap-2 font-raleway text-xs text-darkslateblue">
            <span className="font-medium">Partner Profile</span>
          </div>
        </div>
      </div>
      <div className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch rounded-3xs border-[1px] border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left font-raleway text-xs text-darkslateblue">
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div
            className={`mq280:flex-wrap mb-2 flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left font-raleway text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
          >
            <div
              className={`mq280:flex-wrap flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left font-raleway text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
            >
              <div className="flex w-[159px] flex-col items-start justify-start">
                <div
                  className={`flex flex-row items-start justify-start text-left font-raleway text-xs leading-[normal] tracking-[normal] text-darkslateblue ${className}`}
                >
                  <b className="relative leading-[21px]">GENERAL INFORMATION</b>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[7px]">
                <div className="flex flex-row items-start justify-start gap-2.5">
                  {isEditMode ? (
                    <>
                      <button
                        type="button"
                        className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                        onClick={saveChanges}
                      >
                        <FaSave className="h-3 w-3" />
                        Save
                      </button>
                      <button
                        type="button"
                        className="flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-3 py-1.5 text-xs font-semibold text-black"
                        onClick={cancelEditing}
                      >
                        <FaTimes className="h-3 w-3" />
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="flex cursor-pointer flex-row items-start justify-start gap-2 rounded-md bg-dodgerblue px-[9px] py-[5px] [border:none]"
                      onClick={startEditing}
                    >
                      <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px">
                        <svg
                          width="13"
                          height="12"
                          viewBox="0 0 13 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M11.8284 10.6355H8.03221C7.66184 10.6355 7.3606 10.9415 7.3606 11.3177C7.3606 11.6947 7.66184 12 8.03221 12H11.8284C12.1988 12 12.5 11.6947 12.5 11.3177C12.5 10.9415 12.1988 10.6355 11.8284 10.6355"
                            fill="white"
                          />
                          <path
                            d="M5.37268 2.60257L8.96992 5.50929C9.0567 5.57881 9.07151 5.70637 9.00379 5.79524L4.73916 11.3521C4.47108 11.6954 4.07601 11.8896 3.65272 11.8968L1.32464 11.9254C1.20048 11.9269 1.09183 11.8409 1.06361 11.7176L0.534503 9.41718C0.442791 8.99436 0.534503 8.5572 0.802585 8.22038L5.08837 2.63697C5.15751 2.54739 5.2852 2.53162 5.37268 2.60257"
                            fill="white"
                          />
                          <path
                            opacity="0.4"
                            d="M10.5804 3.77696L9.88687 4.64267C9.81703 4.73082 9.69145 4.74515 9.60468 4.67492C8.76163 3.99267 6.60287 2.2419 6.00391 1.75673C5.91643 1.68506 5.90444 1.5575 5.97499 1.46863L6.64378 0.638038C7.2505 -0.143109 8.30871 -0.214774 9.16235 0.466042L10.143 1.24719C10.5451 1.56251 10.8132 1.97817 10.9049 2.41533C11.0107 2.8962 10.8978 3.36847 10.5804 3.77696"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <a className="relative inline-block text-left font-raleway text-xs font-semibold text-white [text-decoration:none]">
                        Edit
                      </a>
                    </button>
                  )}
                  <div className="flex flex-col items-start justify-start px-0 pb-0 pt-0.5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 15.5L12 8.5L19 15.5"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex w-[1588px] max-w-full flex-col items-start justify-start gap-0.5 text-left font-raleway text-xs leading-[normal] tracking-[normal] text-gray-500 ${className}`}
          >
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block min-w-[107px] leading-[20px] text-gray1-200">
                <label htmlFor="organizationName">Organization Name</label>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                
                id="organizationName"
                type="text"
                cssClass="e-outline"
                style={{ backgroundColor: isEditMode ? '' : '#f3f4f6'}}
                value={tempFormData.name_of_org || ""}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    name_of_org: e.target.value,
                  })
                }
              />
              <div className="relative hidden w-[50px] font-inter leading-[12px]">
                HelpText
              </div>
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block min-w-[81px] leading-[20px] text-gray1-200">
                <div className="relative inline-block min-w-[107px] leading-[20px] text-gray1-200">
                  <label htmlFor="officeNumber">Office Number</label>
                </div>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                floatLabelType="Auto"
                style={{ backgroundColor: isEditMode ? '' : '#f3f4f6' }}
                id="officeNumber"
                type="text"
                cssClass="e-outline"
                value={tempFormData.mobile_number || ""}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    mobile_number: e.target.value,
                  })
                }
              />
              <div className="relative hidden w-[50px] font-inter leading-[12px]">
                HelpText
              </div>
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block min-w-[81px] leading-[20px] text-gray1-200">
                <div className="relative inline-block min-w-[107px] leading-[20px] text-gray1-200">
                  <label htmlFor="officeNumber">
                    Are you willing to partner with local LTRG's
                  </label>
                </div>
              </div>
              <DropDownListComponent
  readonly={!isEditMode}
  dataSource={yesNoOptions}
  fields={{ text: "text", value: "value" }}
  style={{ backgroundColor: isEditMode ? '' : '#f3f4f6'}}
  floatLabelType="Auto"
  cssClass="e-outline"
  change={(e) => setTempFormData({ ...tempFormData, willingToPartner: e.value })}
  value={tempFormData.willingToPartner || ""}
/>
            </div>

            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block w-full  text-gray1-200">
                <label htmlFor="address1">Services Provided</label>
              </div>
              <MultiSelectComponent
  readonly={!isEditMode}
  dataSource={services}
  fields={fields}
  value={tempFormData.servicesProvided || ""}
  change={(e) => setTempFormData({ ...tempFormData, servicesProvided: e.value })}
  mode="CheckBox"
  selectAllText="Select All"
  unSelectAllText="Unselect All"
  showSelectAll={true}
  cssClass={`e-outline ${isEditMode ? '' : 'disabled-multiselect'}`}
  style={{ fontWeight: isEditMode ? 'normal' : 'bold' }}
>
  <Inject services={[CheckBoxSelection]} />
</MultiSelectComponent>
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div
                className="mb-1 py-2 text-xs font-bold text-black"
                style={{ color: "rgb(25, 56, 97)" }}
              >
                BUSINESS ADDRESS
              </div>
              <div className="relative inline-block min-w-[107px]  text-gray1-200">
                <label htmlFor="address1">Address 1</label>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                floatLabelType="Auto"
                id="address1"
                type="text"
                cssClass="e-outline"
                style={{ backgroundColor: isEditMode ? '' : '#f3f4f6'}}
                value={tempFormData.address1 || ""}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    address1: e.target.value,
                  })
                }
              />
              <div className="relative hidden w-[50px] font-inter leading-[12px]">
                HelpText
              </div>
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block min-w-[55px] leading-[20px] text-gray1-200">
                <label htmlFor="address2">Address 2</label>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                floatLabelType="Auto"
                id="address1"
                type="text"
                cssClass="e-outline"
                value={tempFormData.address2 || ""}
                style={{ backgroundColor: isEditMode ? '' : '#f3f4f6'}}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    address2: e.target.value,
                  })
                }
              />
              <div className="relative hidden w-[50px] font-inter leading-[12px]">
                HelpText
              </div>
            </div>
            <div className="flex max-w-full flex-row flex-wrap items-center justify-start gap-5 self-stretch">
              <div className="mq391:min-w-full flex min-w-[351px] max-w-full flex-1 flex-col items-start justify-start gap-1.5">
                <div className="relative inline-block min-w-[22px] leading-[20px] text-gray1-200">
                  <label htmlFor="city">City</label>
                </div>
                <TextBoxComponent
                  readOnly={!isEditMode}
                  floatLabelType="Auto"
                  className="bg-gray-200"
                  cssClass="e-outline"
                  id="city"
                  type="text"
                  value={tempFormData.city || ""}
                  style={{ backgroundColor: isEditMode ? '' : '#f3f4f6' }}
                  onChange={(e) =>
                    setTempFormData({ ...tempFormData, city: e.target.value })
                  }
                />
                <div className="relative hidden w-[50px] font-inter leading-[12px]">
                  HelpText
                </div>
              </div>
              <div className="mq391:min-w-full flex min-w-[351px] max-w-full flex-1 flex-col items-start justify-start gap-1.5">
                <div className="relative inline-block min-w-[76px] leading-[20px] text-gray1-200">
                  <label htmlFor="state">Select State</label>
                </div>
                <DropDownListComponent
  readonly={!isEditMode}
  floatLabelType="Auto"
  dataSource={USStates}
  fields={{ text: "name", value: "abbreviation" }}
  value={tempFormData.state || ""}
  style={{ backgroundColor: isEditMode ? '' : '#f3f4f6'}}
  name="state"
  change={(e) => setTempFormData({ ...tempFormData, state: e.value })}
  cssClass="e-outline"
/>
                <div className="relative hidden w-[68px] font-inter leading-[12px]">
                  Sample text
                </div>
              </div>
              <div className="mq391:min-w-full flex min-w-[351px] max-w-full flex-1 flex-col items-start justify-start gap-1.5 text-gray1-200">
                <div className="relative inline-block min-w-[18px] leading-[20px]">
                  <label htmlFor="zip">Zip</label>
                </div>
                <TextBoxComponent
                  readOnly={!isEditMode}
                  floatLabelType="Auto"
                  className="bg-gray-200"
                  cssClass="e-outline"
                  id="country"
                  type="text"
                  value={tempFormData.zip || ""}
                  style={{ backgroundColor: isEditMode ? '' : '#f3f4f6'}}
                  onChange={(e) =>
                    setTempFormData({ ...tempFormData, zip: e.target.value })
                  }
                />
                <div className="relative hidden w-[50px] font-inter leading-[12px] text-gray-500">
                  HelpText
                </div>
              </div>
              <div className="mq391:min-w-full flex min-w-[351px] max-w-full flex-1 flex-col items-start justify-start gap-1.5 text-gray1-200">
                <div className="relative inline-block min-w-[40px] leading-[20px]">
                  <label htmlFor="county">County</label>
                </div>
                <TextBoxComponent
                  readOnly={!isEditMode}
                  floatLabelType="Auto"
                  className="bg-gray-200"
                  cssClass="e-outline"
                  id="county"
                  type="text"
                  value={tempFormData.county || ""}
                  style={{ backgroundColor: isEditMode ? '' : '#f3f4f6'}}
                  onChange={(e) =>
                    setTempFormData({
                      ...tempFormData,
                      county: e.target.value,
                    })
                  }
                />
                <div className="relative hidden w-[50px] font-inter leading-[12px] text-gray-500">
                  HelpText
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch rounded-3xs border-[1px] border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left font-raleway text-xs text-darkslateblue">
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div
            className={`mq280:flex-wrap mb-2 flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left font-raleway text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
          >
            <div
              className={`mq280:flex-wrap flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left font-raleway text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
            >
              <div className="flex w-full flex-col items-start justify-start">
                <a className="relative inline-block w-full font-bold leading-[21px] text-[inherit] [text-decoration:none]">
                  Last 3 disasters assisted in (if applicable)
                </a>
                <div className="relative self-stretch font-medium leading-[21px] text-silver-200">
                  {/* {data.lastUpdatedDisasters &&
                    `Last Updated: ${tempDisastersFormData.lastUpdatedDisasters}`}
                  {console.log("ahah ", data.lastUpdatedDisasters)} */}
                </div>
              </div>
              <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[7px]">
                <div className="flex flex-row items-start justify-start gap-2.5">
                  <button
                    type="button"
                    className="flex cursor-pointer flex-row items-start justify-start gap-2 rounded-md bg-dodgerblue px-[9px] py-[5px] [border:none]"
                    onClick={handleAddDisaster}
                  >
                    <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M14.0672 6.39191H13.2651V5.60774C13.2651 5.27296 12.9966 5 12.6658 5C12.3357 5 12.0665 5.27296 12.0665 5.60774V6.39191H11.2658C10.935 6.39191 10.6665 6.66487 10.6665 6.99965C10.6665 7.33443 10.935 7.6074 11.2658 7.6074H12.0665V8.39226C12.0665 8.72704 12.3357 9 12.6658 9C12.9966 9 13.2651 8.72704 13.2651 8.39226V7.6074H14.0672C14.3973 7.6074 14.6665 7.33443 14.6665 6.99965C14.6665 6.66487 14.3973 6.39191 14.0672 6.39191Z"
                          fill="white"
                        />
                        <path
                          d="M6.33325 10.0107C3.63607 10.0107 1.33325 10.442 1.33325 12.1648C1.33325 13.887 3.62205 14.3337 6.33325 14.3337C9.02977 14.3337 11.3333 13.9025 11.3333 12.1797C11.3333 10.4568 9.04445 10.0107 6.33325 10.0107Z"
                          fill="white"
                        />
                        <path
                          opacity="0.4"
                          d="M6.33332 8.3698C8.1697 8.3698 9.64174 6.87879 9.64174 5.01873C9.64174 3.15868 8.1697 1.66699 6.33332 1.66699C4.49694 1.66699 3.0249 3.15868 3.0249 5.01873C3.0249 6.87879 4.49694 8.3698 6.33332 8.3698Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <a className="relative inline-block text-left font-raleway text-xs font-semibold text-white [text-decoration:none]">
                      Add
                    </a>
                  </button>
                  <div className="flex flex-col items-start justify-start px-0 pb-0 pt-0.5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 15.5L12 8.5L19 15.5"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <GridComponent
  dataSource={partnerDisasters.filter(disaster => disaster.is_deleted !== true)}
  editSettings={{
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    showDeleteConfirmDialog: true,
    mode: "Dialog",
  }}
  ref={gridRefDisasters}
  actionBegin={actionBeginDisaster}
  allowFiltering={true}
  filterSettings={filterSettings}
>
  <ColumnsDirective>
    <ColumnDirective
      field="disasterId"
      isPrimaryKey={true}
      isIdentity={true}
      visible={false}
    />
    <ColumnDirective
      field="year"
      format="N0"
      headerText="Year"
      validationRules={{ required: true }}
    />
    <ColumnDirective
      field="month"
      headerText="Month"
      validationRules={{ required: true }}
    />
    <ColumnDirective
      field="location"
      headerText="Location of Disaster"
      validationRules={{ required: true }}
    />
    <ColumnDirective
      field="reference"
      headerText="Reference Contact Person"
      validationRules={{ required: true }}
    />
    <ColumnDirective
      field="phone"
      headerText="Contact Person Phone"
      validationRules={{ required: true }}
    />
    <ColumnDirective
      field="email"
      headerText="Contact Person Email"
      validationRules={{ required: true }}
    />
    <ColumnDirective
      field="services"
      headerText="Services Rendered"
      validationRules={{ required: true }}
    />
    <ColumnDirective
      field="comment"
      headerText="Comment"
      validationRules={{ required: true }}
    />
    <ColumnDirective
      headerText="Actions"
      commands={[
        {
          type: "Edit",
          buttonOption: {
            content: '<i class="fas fa-edit"></i>',
            cssClass: "e-outline custom-button",
          },
        },
        {
          type: "Delete",
          buttonOption: {
            content: '<i class="fas fa-trash-alt"></i>',
            cssClass: "e-outline custom-button",
          },
        },
      ]}
    />
  </ColumnsDirective>
  <Inject services={[Page, Sort, Filter, Edit, CommandColumn]} />
</GridComponent>
          </div>
        </div>
      </div>
      <div className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch rounded-3xs border-[1px] border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left font-raleway text-xs text-darkslateblue">
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div
            className={`mq280:flex-wrap mb-2 flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left font-raleway text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
          >
            <div
              className={`mq280:flex-wrap flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left font-raleway text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
            >
              <div className="flex w-[159px] flex-col items-start justify-start">
                <a className="relative inline-block min-w-[52px] font-bold leading-[21px] text-[inherit] [text-decoration:none]">
                  Members
                </a>
                <div className="relative self-stretch font-medium leading-[21px] text-silver-200">
                  Last Edited: 4 minutes ago
                </div>
              </div>
              <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[7px]">
                <div className="flex flex-row items-start justify-start gap-2.5">
                  <button
                    type="button"
                    className="flex cursor-pointer flex-row items-start justify-start gap-2 rounded-md bg-dodgerblue px-[9px] py-[5px] [border:none]"
                    onClick={handleAddPartnerProfile}
                  >
                    <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M14.0672 6.39191H13.2651V5.60774C13.2651 5.27296 12.9966 5 12.6658 5C12.3357 5 12.0665 5.27296 12.0665 5.60774V6.39191H11.2658C10.935 6.39191 10.6665 6.66487 10.6665 6.99965C10.6665 7.33443 10.935 7.6074 11.2658 7.6074H12.0665V8.39226C12.0665 8.72704 12.3357 9 12.6658 9C12.9966 9 13.2651 8.72704 13.2651 8.39226V7.6074H14.0672C14.3973 7.6074 14.6665 7.33443 14.6665 6.99965C14.6665 6.66487 14.3973 6.39191 14.0672 6.39191Z"
                          fill="white"
                        />
                        <path
                          d="M6.33325 10.0107C3.63607 10.0107 1.33325 10.442 1.33325 12.1648C1.33325 13.887 3.62205 14.3337 6.33325 14.3337C9.02977 14.3337 11.3333 13.9025 11.3333 12.1797C11.3333 10.4568 9.04445 10.0107 6.33325 10.0107Z"
                          fill="white"
                        />
                        <path
                          opacity="0.4"
                          d="M6.33332 8.3698C8.1697 8.3698 9.64174 6.87879 9.64174 5.01873C9.64174 3.15868 8.1697 1.66699 6.33332 1.66699C4.49694 1.66699 3.0249 3.15868 3.0249 5.01873C3.0249 6.87879 4.49694 8.3698 6.33332 8.3698Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <a className="relative inline-block text-left font-raleway text-xs font-semibold text-white [text-decoration:none]">
                      Add
                    </a>
                  </button>
                  <div className="flex flex-col items-start justify-start px-0 pb-0 pt-0.5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 15.5L12 8.5L19 15.5"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
            <GridComponent
              dataSource={partnerMembers.filter(member => member.is_deleted !== true)}
              editSettings={{
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog",
              }}
              ref={gridRefMembers}
              actionBegin={actionBegin}
              allowFiltering={true}
              filterSettings={filterSettings}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="memberId"
                  isPrimaryKey={true}
                  isIdentity={true}
                  visible={false}
                />
                <ColumnDirective
                  field="directorName"
                  headerText="Director Name"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="mobileNumber"
                  headerText="Mobile Number"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="email"
                  headerText="Email"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="entityType"
                  headerText="Type of Entity"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="einNumber"
                  headerText="EIN Number"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="document"
                  headerText="Document"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="serviceArea"
                  headerText="Service Area"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="primaryServiceProvided"
                  headerText="Primary Service Provided"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  headerText="Actions"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject services={[Page, Sort, Filter, Edit, CommandColumn]} />
            </GridComponent>
          </div>
        </div>
        <DialogComponent
        width="400px"
        isModal={true}
        visible={showPopup}
        close={handleClosePopup}
        header={null}
        content={dialogContent}
        showCloseIcon={true}
        closeOnEscape={true}
        target={document.body}
        cssClass="welcome-dialog"
      />
      </div>
    </div>
  );
};

export default PartnerProfile;
