import React from "react";

const CenterPartnerships = () => {
  return (
    <div>
      <h1>CenterPartnerships</h1>
    </div>
  );
};

export default CenterPartnerships;
