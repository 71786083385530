import React, { useState } from 'react';

const CreateGroupChatModal = ({ isOpen, onClose, onCreateGroup, searchUsersByEmail }) => {
  const [groupName, setGroupName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleCreateGroup = () => {
    if (groupName && selectedUsers.length > 0) {
      onCreateGroup(groupName, selectedUsers);
      setGroupName('');
      setSelectedUsers([]);
      onClose();
    } else {
      alert('Please enter a group name and select at least one user.');
    }
  };

  const handleSearch = async () => {
    if (searchTerm) {
      const users = await searchUsersByEmail(searchTerm);
      //setSearchResults(users);
      if (users) {
        addUser(users[0])
      }
    }
  };

  const addUser = (user) => {
    if (user && !selectedUsers.find(selectedUser => selectedUser.id === user.id)) {
      setSelectedUsers([...selectedUsers, user]);
      setSearchResults([]);
      setSearchTerm('');
    } 
  };

  const removeUser = (userId) => {
    setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-xl font-semibold mb-4">Create Group Chat</h2>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="groupName">
            Group Name
          </label>
          <input
            id="groupName"
            type="text"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Add Users by Email</label>
          <div className="flex mb-2">
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter email to search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              onClick={handleSearch}
              className="px-4 py-2 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600"
            >
              Add
            </button>
          </div>
          <div className="flex flex-wrap">
            {selectedUsers.map((user) => (
              <div key={user.id} className="flex items-center bg-blue-100 text-blue-700 rounded-lg px-3 py-1 mb-2 mr-2">
                <span>{user.email}</span>
                <button
                  onClick={() => removeUser(user.id)}
                  className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          {searchResults.length > 0 && (
            <div className="mt-2 border rounded-lg p-2">
              <ul>
                {searchResults.map((user) => (
                  <li
                    key={user.id}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => addUser(user)}
                  >
                    {user.email}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded-lg mr-2 hover:bg-gray-600"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            onClick={handleCreateGroup}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateGroupChatModal;
