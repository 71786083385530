import React, { useState, useEffect } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getAuth, onAuthStateChanged, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const Pin = ({ isOpen, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [showPins, setShowPins] = useState({
    current: false,
    new: false,
    confirm: false
  });
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(null);
  const [hasCurrentPin, setHasCurrentPin] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        // Check if the user has a PIN set
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().pin) {
          setHasCurrentPin(true);
        } else {
          setHasCurrentPin(false);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    if (newPin !== confirmPin) {
      setError("New PIN and confirm PIN don't match.");
      return;
    }
  
    if (newPin.length !== 4 || !/^\d+$/.test(newPin)) {
      setError("PIN must be 4 digits.");
      return;
    }
  
    try {
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (!user) {
        setError("User not logged in.");
        return;
      }
  
      // Reauthenticate the user
      try {
        const credential = EmailAuthProvider.credential(user.email, currentPassword);
        await reauthenticateWithCredential(user, credential);
      } catch (error) {
        console.error("Error reauthenticating:", error);
        setError("Incorrect password. Please try again.");
        return;
      }
  
      // If reauthentication succeeds, proceed with PIN update
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        // Update PIN logic here
        await updateDoc(userDocRef, { pin: newPin });
        console.log('PIN updated successfully');
        onClose();
      } else {
        setError("User data not found.");
      }
    } catch (error) {
      console.error("Error updating PIN:", error);
      setError("An error occurred. Please try again.");
    }
  };

  const togglePinVisibility = (field) => {
    setShowPins(prev => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[480px] rounded-lg shadow-xl">
        <div className="bg-blue-500 text-white p-4 font-raleway font-normal flex justify-between items-center rounded-t-lg">
          <h2 className="text-[24px] font-semibold">Change PIN</h2>
          <button onClick={onClose} className="text-[24px] bg-blue-500 rounded-full text-white cursor-pointer">&times;</button>
        </div>
        <form onSubmit={handleSubmit} className="p-6">
          {error && <div className="mb-4 text-red-500">{error}</div>}
          
          <div className="mb-4 relative">
            <TextBoxComponent
              placeholder="Password"
              type={showPins.current ? 'text' : 'password'}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              cssClass="e-outline"
              floatLabelType="Auto"
            />
            <button
              type="button"
              onClick={() => togglePinVisibility('current')}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer"
            >
              {showPins.current ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
         
          <div className="mb-4 relative">
            <TextBoxComponent
              placeholder="New PIN"
              type={showPins.new ? 'text' : 'password'}
              value={newPin}
              onChange={(e) => setNewPin(e.target.value)}
              cssClass="e-outline"
              floatLabelType="Auto"
              maxLength={4}
            />
            <button
              type="button"
              onClick={() => togglePinVisibility('new')}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer"
            >
              {showPins.new ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="mb-6 relative">
            <TextBoxComponent
              placeholder="Confirm New PIN"
              type={showPins.confirm ? 'text' : 'password'}
              value={confirmPin}
              onChange={(e) => setConfirmPin(e.target.value)}
              cssClass="e-outline"
              floatLabelType="Auto"
              maxLength={4}
            />
            <button
              type="button"
              onClick={() => togglePinVisibility('confirm')}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 cursor-pointer"
            >
              {showPins.confirm ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="flex items-center justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="border border-blue-500 text-blue-500 px-4 py-2 rounded-full hover:bg-blue-50 transition duration-300 cursor-pointer"
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300 cursor-pointer"
            >
              SAVE CHANGES
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Pin;