import React, { useState } from 'react';
import MessageIcon from '../../icons/Communications/message.svg';
import CallIcon from '../../icons/Communications/call.svg';
import VideoCallIcon from '../../icons/Communications/video-call.svg';
import GroupIcon from '../../icons/Communications/group.svg';

const ContactProfilePopup = ({ contact, onClose }) => {
  const initials = contact.name
    .split(' ')
    .map(name => name[0])
    .join('')
    .toUpperCase();



  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg overflow-hidden shadow-lg w-[400px] h-[70vh] flex flex-col">

        <div className="bg-gradient-to-b from-blue-500 to-blue-300 p-6 relative flex items-center">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 bg-transparent text-white cursor-pointer rounded-full p-1.5 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="w-24 h-24 bg-white bg-opacity-30 rounded-full flex items-center justify-center text-4xl font-bold text-white mr-6 shadow-lg">
            {initials}
          </div>
          <div className="flex flex-col text-white">
            <h2 className="text-2xl font-semibold font-raleway">{contact.name}</h2>
            <p className="text-sm font-raleway opacity-90">{contact.email}</p>
            <p className="text-sm font-raleway opacity-90">{contact.phone}</p>
          </div>
        </div>
        <div className="p-6 overflow-y-auto flex-grow scrollbar-thin scrollbar-thumb-gray-400/50 scrollbar-track-transparent hover:scrollbar-thumb-gray-400/70">
          <div className="space-y-6">
           {/* Activity Section */}
            <div>
              <h3 className="text-lg font-semibold font-raleway mb-4">Activity</h3>
              <div className="space-y-2">
                <button className="w-full py-2 text-left rounded cursor-pointer bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-300 flex items-center">
                  <img src={MessageIcon} alt="Message" className="w-5 h-5 ml-2 mr-3" />
                  <span className="font-raleway font-semibold">Send message</span>
                </button>
                <hr className="mt-2 border-t border-gray-300" />
                <button className="w-full pt-2 text-left rounded cursor-pointer bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-300 flex items-center">
                  <img src={CallIcon} alt="Call" className="w-8 h-8 mr-2" />
                  <span className="font-raleway font-semibold">Start call</span>
                </button>
                <hr className="border-t border-gray-300" />
                <button className="w-full py-2 text-left rounded cursor-pointer bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-300 flex items-center">
                  <img src={VideoCallIcon} alt="Video Call" className="w-5 h-5 ml-2 mr-3" />
                  <span className="font-raleway font-semibold">Start video call</span>
                </button>
                <hr className="mt-2 border-t border-gray-300" />
                <button className="w-full py-2 text-left rounded cursor-pointer bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-300 flex items-center">
                  <img src={GroupIcon} alt="Group" className="w-5 h-5 ml-2 mr-3" />
                  <span className="font-raleway font-semibold">Create new group with {contact.name.split(' ')[0]}</span>
                </button>
                <hr className="mt-2 border-t border-gray-300" />
              </div>
            </div>
  
            {/* Info Section */}
            <div>
              <h3 className="text-lg font-semibold font-raleway mb-4">Contact Information</h3>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="mr-2">📧</span>
                    <span className="font-medium">Email</span>
                  </div>
                  {contact.email ? (
                    <span className="text-gray-500 font-raleway">{contact.email}</span>
                  ) : (
                    <span className="text-gray-500 font-raleway">Not available</span>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="mr-2">📱</span>
                    <span className="font-medium">Phone</span>
                  </div>
                  {contact.phone ? (
                    <span className="text-gray-500 font-raleway">{contact.phone}</span>
                  ) : (
                    <span className="text-gray-500 font-raleway">Not available</span>
                  )}
                </div>
                {contact.facebook && (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 48 48">
                        <path fill="#3F51B5" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path>
                        <path fill="#FFF" d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"></path>
                      </svg>
                      <span className="font-medium font-raleway">Facebook</span>
                    </div>
                    <span className="text-gray-500 font-raleway">{contact.facebook}</span>
                  </div>
                )}
                {contact.twitter && (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 30 30">
                        <path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z"></path>
                      </svg>
                      <span className="font-medium font-raleway">Twitter</span>
                    </div>
                    <span className="text-gray-500 font-raleway">{contact.twitter}</span>
                  </div>
                )}
                {contact.instagram && (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 48 48">
                        <radialGradient id="instagram-gradient" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse">
                          <stop offset="0" stop-color="#fd5"></stop>
                          <stop offset=".328" stop-color="#ff543f"></stop>
                          <stop offset=".348" stop-color="#fc5245"></stop>
                          <stop offset=".504" stop-color="#e64771"></stop>
                          <stop offset=".643" stop-color="#d53e91"></stop>
                          <stop offset=".761" stop-color="#cc39a4"></stop>
                          <stop offset=".841" stop-color="#c837ab"></stop>
                        </radialGradient>
                        <path fill="url(#instagram-gradient)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path>
                        <path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path>
                        <circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle>
                        <path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
                      </svg>
                      <span className="font-medium font-raleway">Instagram</span>
                    </div>
                    <span className="text-gray-500 font-raleway">{contact.instagram}</span>
                  </div>
                )}
              </div>
            </div>
  
            {/* Files Section */}
            <div>
              <h3 className="text-lg font-semibold font-raleway mb-4">Files</h3>
              {/* Add file content here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactProfilePopup;