import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

const assistanceLogService = {
  fetchRecords: async (userId, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "assistance-log",
        userId,
        collectionName
      );
      const documentsSnapshot = await getDocs(documentsCollectionRef);

      const documentsList = documentsSnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          id: doc.id,
          ...docData,
        };
      });

      return documentsList;
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  },
  addRecord: async (userId, documentData, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "assistance-log",
        userId,
        collectionName
      );

      const updatedDocumentData = {
        ...documentData,
        userId: userId,
        updated_at: serverTimestamp(),
      };

      await addDoc(documentsCollectionRef, updatedDocumentData);

      console.log("Document successfully added!");
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  },

  updateRecord: async (userId, documentId, documentData, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "assistance-log",
        userId,
        collectionName
      );

      const idField =
        collectionName === "immediateAssistance" ? "ImmediateResponseID" : "RecoveryAssistanceID";

      const q = query(documentsCollectionRef, where(idField, "==", documentId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;

          const updatedDocumentData = {
            ...documentData,
            userId: userId,
            updated_at: serverTimestamp(),
          };

          await updateDoc(documentRef, updatedDocumentData);
        });
        console.log("Document successfully updated!");
      } else {
        console.error("No document found with the given ID to update.");
        throw new Error("No document found with the given ID to update.");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  deleteRecord: async (userId, documentId, collectioniName) => {
    try {
      // Log documentId to check if it's defined
      console.log("Deleting document with ID:", documentId);

      if (!documentId) {
        throw new Error("documentId is undefined or null");
      }

      // Create a query to find the document with the given documentId
      const documentsCollectionRef = collection(
        db,
        "assistance-log",
        userId,
        collectioniName
      );
      const idField =
        collectioniName === "immediateAssistance" ? "ImmediateResponseID" : "RecoveryAssistanceID";

      const q = query(documentsCollectionRef, where(idField, "==", documentId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Delete each matching document (should be only one in your case)
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await deleteDoc(documentRef);
        });
        console.log("Document successfully deleted!");
      } else {
        console.error("No document found with the given ID to delete.");
        throw new Error("No document found with the given ID to delete.");
      }
    } catch (error) {
      console.error("Error deleting document from lockbox: ", error);
      throw error;
    }
  },
};
export default assistanceLogService;
