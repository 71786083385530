import React, { createContext, useState, useContext } from 'react';

const DisasterContext = createContext();

export const DisasterProvider = ({ children }) => {
  const [activeDisasterTab, setActiveDisasterTab] = useState('Immediate Response Survey');

  return (
    <DisasterContext.Provider value={{ activeDisasterTab, setActiveDisasterTab }}>
      {children}
    </DisasterContext.Provider>
  );
};

export const useDisasterContext = () => useContext(DisasterContext);