const LOCATIONIQ_URL = "https://us1.locationiq.com/v1/search.php";
const API_KEY = "pk.03fd3f843af6df55fd03ec254c3a9c7c"; // Your API key

const getCoordinates = async (address1, city, state, zip) => {
  const address = `${address1}, ${city}, ${state}, ${zip}`;
  const params = new URLSearchParams({
    key: API_KEY,
    q: address,
    format: "json",
    limit: "1",
  });

  const url = `${LOCATIONIQ_URL}?${params}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.length > 0) {
      const { lat, lon } = data[0];
      return { lat, lon };
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    throw error;
  }
};

export default getCoordinates;
