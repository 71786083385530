import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../components/AuthContext";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import note from "../../src/assets/note.png";
import check from "../../src/assets/check.png";
import umbrella from "../../src/assets/umbrella.png";
import lightning from "../../src/assets/lightning.png";
import fire from "../../src/assets/fire.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login2 = ({ password, onPasswordChange, onLogin }) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex h-screen w-full justify-center bg-white font-raleway ">
      <div
        className=" my-auto flex h-[594px] w-[478px] rounded-bl-[10px] rounded-br-[10px] rounded-tl-[10px] rounded-tr-[10px] bg-white px-[35px] py-[70px]"
        style={{
          border: "1px solid var(--Text-Locked, #D5D7DB)",
          background: "var(--Pure-White, #FFF)",
          boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="mx-auto my-auto flex flex-col text-center">
          <img
            src={blueSkyLogo}
            className="mx-auto h-[141.942px] w-[134.703px]"
          ></img>
          <p className="mx-auto mt-[10px] w-[350px] font-bold text-[#348BFF]">
            DARCI, powered by Blue Sky
          </p>
          <p className="my-[30px] text-[20px] font-bold text-[#3D435A]">
            Sign In
          </p>
          <div className="relative">
            <input
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] focus:outline-none"
              name="password"
              value={password}
              onChange={(e) => onPasswordChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onLogin();
                }
              }}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {/* <div className="mt-[25px] flex items-center gap-[10px]">
            <input type="checkbox"></input>
            <p className="text-base text-[#D5D7DB]">Remember me</p>
          </div> */}
          <div className="mt-[30px] flex flex-col gap-[10px]">
            <button
              className="h-10 w-full rounded-[4px] bg-[#348BFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
              onClick={onLogin}
            >
              Login
            </button>
            <p className="w-full">
              Not yet registered? Register as:
              {"    "}
              <Link to="/register">Citizen</Link>
              {",  "}
              <Link to="/partnerregister">Partner</Link>
              {",  "}
              <Link to="/centerregister">Center</Link>
            </p>
            {/* <Link
              to="/forgotpassword"
              className="text-[#348BFF] no-underline hover:cursor-pointer"
            >
              Forgot Password
            </Link> */}
          </div>
        </div>
      </div>
      <div
        className="relative my-auto ml-[-5px] h-[594px] w-[288.195px] rounded-br-[10px] rounded-tr-[10px] bg-[#348BFF]"
        style={{
          border: "1px solid var(--Text-Locked, #D5D7DB)",
          boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <img src={note} className="absolute right-0 top-[-80px]"></img>
        <img src={check} className="absolute left-[-40px] top-[80px]"></img>
        <img src={umbrella} className="absolute right-0 top-[250px]"></img>
        <img
          src={lightning}
          className="absolute left-[-20px] top-[370px]"
        ></img>
        <img src={fire} className="absolute right-0 top-[470px]"></img>
      </div>
    </div>
  );
};

export default Login2;
