import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Toolbar,
  Filter,
  Sort,
  Page,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "../style.css";
import dataService from "../../service/data/DataService";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const filterSettings = {
  type: "Menu",
};

const validationRules = { required: true };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const formatLastEditedTime = (time) => {
  if (!time) return "Never";

  const now = new Date();
  const diffInSeconds = Math.floor((now - time) / 1000);

  if (diffInSeconds < 60) return "Just now";
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400)
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  if (diffInSeconds < 604800)
    return `${Math.floor(diffInSeconds / 86400)} days ago`;

  return time.toLocaleDateString();
};

const generateRandomPassword = (length = 8) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const servicesProvided = [
  { value: "Social Services", text: "1" },
  { value: "Recovery Support Leader", text: "2" },
  { value: "Red Cross Center", text: "3" },
  { value: "Emergency Services", text: "4" },
  { value: "Housing Assistance", text: "5" },
  { value: "Human/Animal Shelter", text: "6" },
];

const fields = { value: "value", text: "value" };

const Services = (props) => {
  const currentAccess = (props && props.typeService) || [];

  return (
    <div>
      <label htmlFor="typeService" className="mb-2 block text-xs text-gray-500">
        Services Provided
      </label>
      <MultiSelectComponent
        id="typeService"
        dataSource={servicesProvided}
        fields={fields}
        value={currentAccess}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

const disaster = [
  { value: "Fire", text: "1" },
  { value: "Flood", text: "2" },
  { value: "Earthquake", text: "3" },
];

const fieldsDisaster = { value: "value", text: "value" };

const DisasterTemplate = (props) => {
  const currentAccess = (props && props.disaster) || [];

  return (
    <div>
      <label htmlFor="disaster" className="mb-2 block text-xs text-gray-500">
        Disaster
      </label>
      <MultiSelectComponent
        id="disaster"
        dataSource={disaster}
        fields={fieldsDisaster}
        value={currentAccess}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

const DisasterRecoverySearch = ({ className = "" }) => {
  const [centerDisasterRecovery, setCenterDisasterRecovery] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      if (!userId) {
        console.log("No user ID available, skipping fetch.");
        return; // Skip fetching if userId is not set
      }

      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "users"),
            where("center_id", "==", userId),
            where("type", "==", "disaster-recovery")
          ) // Changed from user_id to center_id
        );
        const drpPartnerData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Fetched data:", drpPartnerData);
        setCenterDisasterRecovery(preprocessData(drpPartnerData));
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        console.log("User ID:", user.uid); // Check if user ID is retrieved
        fetchContacts(); // Fetch contacts after user is authenticated
        const userData = await dataService.getUserProfile("users", user.uid);
        if (userData) {
          setCurrentUserData(userData);
          console.log("Data: ", userData);
        }
      } else {
        setUserId(null);
        setCenterDisasterRecovery([]); // Clear contacts if no user is logged in
      }
    });

    return () => unsubscribe(); // Clean up subscription
  }, [userId]); // Add userId as a dependency


  return (
    <>
      <div className={`flex max-w-full flex-col gap-5 p-4 ${className}`}>
        <div className="flex items-center gap-2 pt-2 font-raleway text-xs text-darkslateblue">
          <a className="font-medium">Database</a>
          <span className="text-base font-semibold text-gray-400">/</span>
          <span className="font-medium">Disaster Recovery Partners Search</span>
        </div>
      </div>
      <header className=" flex w-full flex-row items-center  justify-between self-stretch rounded-3xs pl-5 pr-5 pt-[8px] text-left font-raleway text-xs text-darkslateblue">
        <div>
          <h2 className="mb-1 font-raleway text-sm font-bold uppercase text-blue-500">
            Disaster Recovery Partners
          </h2>
        </div>
      </header>
      <div className="mt-3 w-full rounded-3xs pl-5 pr-5  text-left font-raleway text-xs text-darkslateblue">
        <GridComponent
          dataSource={centerDisasterRecovery.filter(partner => partner.is_deleted !== true)}
          filterSettings={filterSettings}
          allowFiltering={true}
          allowSorting={true}
          ref={gridRef}
          toolbar={["Search"]}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="partnerName"
              headerText="Partner Name"
              width="150"
              textAlign="Left"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="Address"
              headerText="Address"
              width="150"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="address1"
              headerText="Address 1"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="address2"
              headerText="Address 2"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="city"
              headerText="City"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="state"
              headerText="State"
              visible={false}
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: USStates,
                  fields: { text: "abbreviation", value: "abbreviation" },
                  query: new Query(),
                },
              }}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="zip"
              headerText="Zip"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="mobileNumber"
              headerText="Mobile Number"
              width="120"
              textAlign="Left"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              width="150"
              textAlign="Left"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="password"
              headerText="Password"
              width="150"
              textAlign="Left"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="typeService"
              headerText="Type of Service"
              width="120"
              textAlign="Left"
              editTemplate={Services}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="disaster"
              headerText="Disaster"
              width="120"
              textAlign="Left"
              editTemplate={DisasterTemplate}
              validationRules={validationRules}
            />
          </ColumnsDirective>
          <Inject services={[Filter, Sort, Page, CommandColumn]} />
        </GridComponent>
      </div>
    </>
  );
};

export default DisasterRecoverySearch;

