import React from 'react';

const Response = () => {
  return <div className="w-full relative flex flex-col items-start justify-start gap-2.5 leading-[normal] tracking-[normal] text-left text-sm text-dodgerblue font-raleway">
  <div className="flex flex-row items-start justify-start pt-0 pb-2.5 pl-0 pr-5">
    <b className="relative uppercase">General Disaster Info</b>
  </div>
  <div className="w-[1312px] flex flex-row items-start justify-start pt-0 pb-1.5 pl-0 pr-5 box-border max-w-full text-darkslategray-100">
    <b className="relative inline-block min-w-[52px]" style={{ color: '#333333' }}>
      Articles
    </b>
  </div>
  <div className="w-[976.6px] flex flex-row flex-wrap items-start justify-start pt-0 pb-[16.7px] pl-8 pr-5 box-border gap-x-5 sm:gap-x-6 gap-y-[19px] max-w-full">
    <div className="w-[452px] flex flex-col items-start justify-start gap-3 max-w-full">
      <div className="w-[355.2px] relative font-semibold flex items-center max-w-full">
        <a
          className="text-[inherit] [text-decoration:none]"
          href="https://www.cdc.gov/disasters/cleanup/cleanup-debris-after-disaster.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          What to Wear when Cleaning up Debris?
        </a>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-px pr-0 box-border max-w-full text-gray1-100">
        <div className="flex-1 relative inline-block max-w-full">
          Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad
          perspiciatis impedit qui recusandae dolor ut..
        </div>
      </div>
    </div>
  </div>
  <section className="self-stretch flex flex-col items-start justify-start gap-2.5 max-w-full text-left text-sm text-dodgerblue font-raleway">
    <b className="self-stretch relative uppercase">TORNADO</b>
    <b className="relative inline-block min-w-[52px]" style={{ color: '#333333' }}>
      Articles
    </b>
    <div className="self-stretch rounded-xl flex flex-row flex-wrap items-start justify-start pt-5 pb-10 pl-[30px] pr-[187px] box-border gap-x-5 sm:gap-x-6 gap-y-[19px] min-h-[280px] max-w-full lg:pr-[93px] lg:box-border mq450:pr-5 mq450:box-border mq750:pr-[46px] mq750:box-border">
      {[
        { title: "Tornado | Response Planning (Shelter)", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." },
        { title: "Tornado | Alerts and Warnings", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." },
        { title: "Tornado | Communication Plan", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." },
        { title: "Tornado | Vehicle (Do Not Use Overpass/Underpass as Shelter)", content: "..Damaged Lorem ipsum dolor sit amet. Ad voluptas..." },
        { title: "Tornado | Learn and Practice Skills", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." },
        { title: "Tornado | Outdoors (Vehicles vs. Ditch)", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." },
        { title: "Tornado | Vehicle (Do Not Try to Outrun a Tornado in a Vehicle)", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." }
      ].map((item, index) => (
        <div key={index} className="w-[452px] flex flex-col items-start justify-start gap-3 max-w-full">
          <div className="w-[355.2px] relative font-semibold flex items-center max-w-full">
            {item.title}
          </div>
          <div className="self-stretch relative text-gray1-100">
            {item.content}
          </div>
        </div>
      ))}
    </div>
  </section>
  <div className="self-stretch flex flex-col items-start justify-start gap-2.5 text-darkslateblue">
    <b className="self-stretch relative uppercase text-dodgerblue">FLOOD</b>
    <b className="self-stretch relative text-darkslategray-100">Articles</b>
  </div>
  <section className="self-stretch rounded-xl flex flex-row flex-wrap items-start justify-start pt-5 pb-10 pl-[30px] pr-[187px] box-border gap-x-5 sm:gap-x-6 gap-y-[19px] min-h-[280px] max-w-full text-left text-sm text-dodgerblue font-raleway lg:pr-[93px] lg:box-border mq450:pr-5 mq450:box-border mq750:pr-[46px] mq750:box-border">
    {[
      { title: "Flood | Evacuation (Do Not Take Shortcuts)", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." },
      { title: "Flood | Vehicle (Never Try to Outrun a Flood in Vehicle; Seek Higher Ground)", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." },
      { title: "Flood | Evacuation (Move to Higher Ground; Avoid Low Areas)", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." },
      { title: "Flood | Trapped (Move to Higher Floors in Buildings; Call 911 if Trapped)", content: "Damaged Lorem ipsum dolor sit amet. Ad voluptas.", link: "https://www.redcross.org/get-help/how-to-prepare-for-emergencies/types-of-emergencies/earthquake.html" },
      { title: "Flood | Evacuation", content: "Lorem ipsum dolor sit amet. Ad voluptas odit aut modi impedit ad perspiciatis impedit qui recusandae dolor ut.." }
    ].map((item, index) => (
      <div key={index} className="w-[452px] flex flex-col items-start justify-start gap-3 max-w-full">
        <div className="w-[355.2px] relative font-semibold flex items-center max-w-full">
          {item.link ? (
            <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-[inherit] [text-decoration:none]">
              {item.title}
            </a>
          ) : (
            item.title
          )}
        </div>
        <div className="self-stretch relative text-gray1-100">
          {item.content}
        </div>
      </div>
    ))}
  </section>
</div>;

};

export default Response;
