import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import weatherRadioIcon from "../icons/Preparedness/weatherRadio.png";
import weatherRadioIcon2 from "../icons/Preparedness/weatherRadio2.png";
import IntroPic from "../icons/CertificateVideo/Intro.png"
import ready from "../icons/Preparedness/ready.png";
import Phases from "../icons/CertificateVideo/Phases.png";
import Roles from "../icons/CertificateVideo/Roles.png";
import LedRecovery from "../icons/CertificateVideo/LedRecovery.png";
import CertifiedCitizen from "../icons/CertificateVideo/CertifiedCitizen.png";
import QuickInfo from "./Library/QuickInfo";
import Recovery from "./Library/Recovery";
import Response from "./Library/Response";
import Preparedness from "./Library/Preparedness";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import CertificationVideosCenter from "./Library/CertificationVideos/CertificationVideosCenter";
import CertificationVideosPartners from "./Library/CertificationVideos/CertificationVideosPartners";




const CertificationVideos = (className = "") => {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserRole(userDoc.data().user_role);
        }
      }
    };

    fetchUserRole();
  }, []);
  
  const [activeTab, setActiveTab] = useState("DARCI Preparedness Program - PIC");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  
  const [isPhaseModalOpen, setIsPhaseModalOpen] = useState(false);
  

const openPhaseModal = () => setIsPhaseModalOpen(true);
const closePhaseModal = () => setIsPhaseModalOpen(false);

const [isRolesModalOpen, setIsRolesModalOpen] = useState(false);

const openRolesModal = () => setIsRolesModalOpen(true);
const closeRolesModal = () => setIsRolesModalOpen(false);

const [isCommunityRecoveryModalOpen, setIsCommunityRecoveryModalOpen] = useState(false);

const openCommunityRecoveryModal = () => setIsCommunityRecoveryModalOpen(true);
const closeCommunityRecoveryModal = () => setIsCommunityRecoveryModalOpen(false);

const [isCertifiedCitizenModalOpen, setIsCertifiedCitizenModalOpen] = useState(false);

const openCertifiedCitizenModal = () => setIsCertifiedCitizenModalOpen(true);
const closeCertifiedCitizenModal = () => setIsCertifiedCitizenModalOpen(false);

 // Citizen modals
 const [isCitizenPhaseModalOpen, setIsCitizenPhaseModalOpen] = useState(false);
 const [isCitizenRolesModalOpen, setIsCitizenRolesModalOpen] = useState(false);
 const [isCitizenCommunityRecoveryModalOpen, setIsCitizenCommunityRecoveryModalOpen] = useState(false);
 const [isCitizenCertifiedCitizenModalOpen, setIsCitizenCertifiedCitizenModalOpen] = useState(false);

 // Center modals
 const [isCenterPhaseModalOpen, setIsCenterPhaseModalOpen] = useState(false);
 const [isCenterRolesModalOpen, setIsCenterRolesModalOpen] = useState(false);
 const [isCenterCommunityRecoveryModalOpen, setIsCenterCommunityRecoveryModalOpen] = useState(false);
 const [isCenterCertifiedCitizenModalOpen, setIsCenterCertifiedCitizenModalOpen] = useState(false);

 // Partners modals
 const [isPartnerPhaseModalOpen, setIsPartnerPhaseModalOpen] = useState(false);
 const [isPartnerRolesModalOpen, setIsPartnerRolesModalOpen] = useState(false);
 const [isPartnerCommunityRecoveryModalOpen, setIsPartnerCommunityRecoveryModalOpen] = useState(false);
 const [isPartnerCertifiedCitizenModalOpen, setIsPartnerCertifiedCitizenModalOpen] = useState(false);

const Modal = ({ isOpen, onClose, videoSrc }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-black rounded-lg shadow-lg overflow-hidden" style={{ width: '560px' }}>
        <div className="flex justify-between items-center bg-gray-800 text-white p-2">
          <span className="font-bold">Video</span>
          <button
            className="w-8 h-8 flex items-center justify-center text-gray-300 hover:text-white focus:outline-none border border-gray-300 rounded-full transition-colors duration-200 ease-in-out hover:border-white bg-black"
            onClick={onClose}
          >
            <span className="text-3xl leading-none">X</span>
          </button>
        </div>
        <div className="p-4">
          <iframe
            width="100%"
            height="315"
            src={videoSrc}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

  const tabs = [
    "DARCI Preparedness Program - PIC",
    "Quick Info",
    "Preparedness",
    "Response",
    "Recovery"
  ];

  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  
  const renderContent = () => {
    switch (activeTab) {
      case "DARCI Preparedness Program - PIC":
        return (
    <div className={`max-w-full flex flex-col gap-5 p-4 ${className}`}>
      {/* Breadcrumb and Navigation */}
          
      {/* Main Content */}
      <div className="flex flex-col gap-8">
        <section className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full text-left text-sm text-darkslateblue font-raleway">
            <b className="self-stretch relative uppercase">
            INTRODUCTION TO BLUE SKY AND COMMUNITY PREPAREDNESS
            </b>
            <div className="self-stretch rounded-tl-none rounded-tr-mini rounded-b-mini bg-white flex flex-row items-start justify-start py-3 px-[42px] box-border gap-[51px] max-w-full text-darkslategray mq450:gap-[25px] mq1125:pl-[21px] mq1125:pr-[21px] mq1125:box-border mq1300:flex-wrap">
              <div className="w-[563px] flex flex-col items-start justify-start gap-[14px] min-w-[563px] max-w-full mq800:min-w-full mq1300:flex-1">
                <b className="self-stretch relative">
                  Community Introductory Videos
                </b>
                <div className="self-stretch h-[217px] flex flex-row flex-wrap items-start justify-start text-smi text-darkslateblue">
             <div className="self-stretch w-[271px] flex flex-col items-start justify-start gap-[10px]">
              <div className="relative w-full aspect-video cursor-pointer" onClick={openModal}>
                <img
                  className="w-full h-full object-cover rounded-lg"
                  alt="Welcome to Blue Sky video thumbnail"
                  src={IntroPic} // Replace with your actual thumbnail image
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                  </svg>
                </div>
              </div>
              <b className="self-stretch relative">Welcome to Blue Sky</b>
            </div>
            </div>
            <Modal
              isOpen={isModalOpen}
              onClose={closeModal}
              videoSrc="https://www.youtube.com/embed/SrU0Bv1gi3E?autoplay=1"
            />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[38px] min-w-[606px] max-w-full mq800:gap-[19px] mq800:min-w-full">
                <b className="self-stretch relative">
                  Supplemental Support Articles
                </b>
                <div className="flex flex-col items-start justify-start gap-[23px] max-w-full">
                  <div className="relative font-semibold">
                    Support Article Community
                  </div>
                  <div className="flex flex-row items-start justify-start py-0 pr-0 pl-px text-gray1-100">
                    <div className="relative">
                      The increasing frequency and severity of natural disasters,
                      including drought, is placing enormous...
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>

      {/*Citizen Content*/}
      {(userRole === "citizen" || userRole === "center" || userRole === "admin")  && (
        <section className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full text-left text-sm text-darkslateblue font-raleway">
             <div 
        className="self-stretch flex items-center cursor-pointer" 
        onClick={toggleDropdown}
      >
        <svg 
          className={`w-6 h-6 mr-2 transform transition-transform ${isOpen ? 'rotate-90' : ''}`} 
          fill="currentColor" 
          viewBox="0 0 20 20"
        >
          <path d="M6 6L14 10L6 14V6Z" />
        </svg>
        <b className="self-stretch relative uppercase flex-grow pt-1">
          Blue Sky Citizen Course Videos
        </b>
      </div>
      {isOpen && (
            <div className="self-stretch rounded-tl-none rounded-tr-mini rounded-b-mini bg-white flex flex-row items-start justify-start pt-3 px-[42px] pb-[27px] box-border gap-[51px] max-w-full text-darkslategray mq450:gap-[25px] mq1125:pl-[21px] mq1125:pr-[21px] mq1125:box-border mq1300:flex-wrap">
              <div className="w-[563px] flex flex-col items-start justify-start gap-[14px] min-w-[563px] max-w-full mq800:min-w-full mq1300:flex-1">
                <b className="self-stretch relative">Citizen Introductory Videos</b>
                <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[17px_13px] min-h-[451px] text-smi text-darkslateblue">
                  <div className="h-[217px] w-[271px] flex flex-col items-start justify-start gap-[10px]">
                  <div className="relative w-full h-full cursor-pointer" onClick={() => setIsCitizenPhaseModalOpen(true)}>
                      <img
                        className="self-stretch flex-1 relative max-w-full overflow-hidden rounded-lg max-h-full"
                        alt="Phases of Disaster thumbnail"
                        src={Phases}
                      />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                        </svg>
                      </div>
                    </div>
                    <b className="self-stretch relative">Phases of Disaster</b>
                  </div>

                  <Modal
                    isOpen={isCitizenPhaseModalOpen}
                    onClose={() => setIsCitizenPhaseModalOpen(false)}
                    videoSrc="https://www.youtube.com/embed/Sj4eXgQu3vo?autoplay=1"
                  />

                <div className="w-[272px] flex flex-col items-start justify-start gap-[10px]">
                  <div className="self-stretch h-48 relative cursor-pointer" onClick={openRolesModal}>
                    <img
                      className="absolute top-[0px] left-[0px] w-full h-full object-contain rounded-lg"
                      alt="National Roles and Responsibilities thumbnail"
                      src={Roles}
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                      </svg>
                    </div>
                  </div>
                  <b className="relative">
                    National Roles and Responsibility's in Re...
                  </b>
                </div>

            <Modal
              isOpen={isRolesModalOpen}
              onClose={closeRolesModal}
              videoSrc="https://www.youtube.com/embed/zrbklf_1uOU?autoplay=1"
            />

            <div className="h-[217px] w-[273px] flex flex-col items-start justify-start gap-[10px]">
              <div className="relative w-full h-full cursor-pointer" onClick={openCommunityRecoveryModal}>
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full rounded-lg"
                  alt="Community Led Recovery thumbnail"
                  src={LedRecovery}
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                  </svg>
                </div>
              </div>
              <b className="self-stretch relative">Community Led Recovery</b>
            </div>

                <Modal
                  isOpen={isCommunityRecoveryModalOpen}
                  onClose={closeCommunityRecoveryModal}
                  videoSrc="https://www.youtube.com/embed/81t4quqwp5Q?autoplay=1"
                />


                <div className="h-[217px] w-[272px] flex flex-col items-start justify-start gap-[10px]">
                  <div className="relative w-full h-full cursor-pointer" onClick={openCertifiedCitizenModal}>
                    <img
                      className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full rounded-lg"
                      alt="Certified Citizen thumbnail"
                      src={CertifiedCitizen}
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <svg className="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 0a10 10 0 100 20 10 10 0 000-20zm-2 14.5v-9l6 4.5-6 4.5z" />
                      </svg>
                    </div>
                  </div>
                  <b className="self-stretch relative">Citizen</b>
                </div>

                <Modal
                  isOpen={isCertifiedCitizenModalOpen}
                  onClose={closeCertifiedCitizenModal}
                  videoSrc="https://www.youtube.com/embed/t_Qlyfm6beY?autoplay=1"
                />
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[38px] min-w-[606px] max-w-full mq800:gap-[19px] mq800:min-w-full">
                <b className="self-stretch relative">
                  Supplemental Support Articles
                </b>
                <div className="self-stretch flex flex-col items-start justify-start gap-[23px] max-w-full">
                  <div className="relative font-semibold">
                    Support Article Citizen
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-full text-gray1-100">
                    <div className="flex-1 relative inline-block max-w-full">
                      <a
                        className="text-[inherit]"
                        href="https://www.redcross.org/content/dam/redcross/get-help/pdfs/tornado/EN_Tornado-Safety-Checklist.pdf"
                        target="_blank"
                      >
                        The process of recovering from a disaster can be complex for
                        victims. Government aid is often distributed through
                        multiple agencies, each with their own
                      </a>
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full">
                  <div className="relative font-semibold">Why Blue Sky CITIZEN</div>
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-full text-gray1-100">
                    <div className="flex-1 relative inline-block max-w-full">
                      
                      Enrolling as a Blue Sky Citizen presents an optimal
                      opportunity to fortify yourself and your family against
                      potential disasters. Key advantages entail com...
                    </div>
                  </div>
                </div>
              </div>
            </div>
             )}
        </section>
      )}
        {/*Center Content*/}
        <div className="flex flex-col gap-8">
        {(userRole === "center" || userRole === "partner" || userRole === "admin") && (
          <CertificationVideosCenter
            openPhaseModal={() => setIsCenterPhaseModalOpen(true)}
            openRolesModal={() => setIsCenterRolesModalOpen(true)}
            openCommunityRecoveryModal={() => setIsCenterCommunityRecoveryModalOpen(true)}
            openCertifiedCitizenModal={() => setIsCenterCertifiedCitizenModalOpen(true)}
            Modal={Modal}
            isPhaseModalOpen={isCenterPhaseModalOpen}
            closePhaseModal={() => setIsCenterPhaseModalOpen(false)}
            isRolesModalOpen={isCenterRolesModalOpen}
            closeRolesModal={() => setIsCenterRolesModalOpen(false)}
            isCommunityRecoveryModalOpen={isCenterCommunityRecoveryModalOpen}
            closeCommunityRecoveryModal={() => setIsCenterCommunityRecoveryModalOpen(false)}
            isCertifiedCitizenModalOpen={isCenterCertifiedCitizenModalOpen}
            closeCertifiedCitizenModal={() => setIsCenterCertifiedCitizenModalOpen(false)}
          />
          )}
        </div>

        {/*Partners Content*/}
        <div className="flex flex-col gap-8">
        {(userRole === "center" || userRole === "partner" || userRole === "admin") && (
          <CertificationVideosPartners
            openPhaseModal={() => setIsPartnerPhaseModalOpen(true)}
            openRolesModal={() => setIsPartnerRolesModalOpen(true)}
            openCommunityRecoveryModal={() => setIsPartnerCommunityRecoveryModalOpen(true)}
            openCertifiedCitizenModal={() => setIsPartnerCertifiedCitizenModalOpen(true)}
            Modal={Modal}
            isPhaseModalOpen={isPartnerPhaseModalOpen}
            closePhaseModal={() => setIsPartnerPhaseModalOpen(false)}
            isRolesModalOpen={isPartnerRolesModalOpen}
            closeRolesModal={() => setIsPartnerRolesModalOpen(false)}
            isCommunityRecoveryModalOpen={isPartnerCommunityRecoveryModalOpen}
            closeCommunityRecoveryModal={() => setIsPartnerCommunityRecoveryModalOpen(false)}
            isCertifiedCitizenModalOpen={isPartnerCertifiedCitizenModalOpen}
            closeCertifiedCitizenModal={() => setIsPartnerCertifiedCitizenModalOpen(false)}
          />
          )}
        </div>
      </div>
    </div>
    
        );
      case "Quick Info":
        return 
      case "Preparedness":
        return ;
     case "Response":
      return 
      case "Recovery":
  return 
        default:
        return null;
    }
  };

  return (
    
  <div className={`w-full max-w-[1640px] flex flex-col items-start justify-start py-[15px] px-2.5 box-border ${className}`}>
      
    {/* Combined Breadcrumb Navigation and Tabs */}
    <div className="flex flex-row items-center justify-between mb-4 w-full">
        {/* Breadcrumb Navigation on the left */}
        <div className="flex flex-row items-start justify-start gap-1.5 text-xs text-darkslateblue font-raleway">
          <a className="[text-decoration:none] relative leading-[16px] font-medium text-[inherit]">
            Library
          </a>
          <div className="relative text-base leading-[16px] font-semibold font-mulish text-gray-400">/</div>
          <span className="relative leading-[16px] font-medium">{activeTab}</span>
        </div>

        {/* Tabs on the right side of the screen */}
        <div className="flex flex-wrap justify-end gap-2 text-xs ml-auto">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`px-4 py-1 rounded-full ${
                activeTab === tab
                  ? "bg-dodgerblue text-white"
                  : "border border-dodgerblue text-dodgerblue hover:bg-blue-100"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
    </div>


    {/* Main Content */}
    {activeTab === "Blue Sky Certification Videos" && ""}
    {activeTab === "Quick Info" && <QuickInfo />}
    {activeTab === "Preparedness" && <Preparedness />}
    {activeTab === "Response" && <Response />}
    {activeTab === "Recovery" && <Recovery />}
        
      
    {/* Rendered Content */}
    {renderContent()}
  </div>
  );
};

CertificationVideos.propTypes = {
  className: PropTypes.string,
};

export default CertificationVideos;
