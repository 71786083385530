import React, { useState, useRef, useEffect } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import {
  getDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDocs,
  collection,
  where,
  query,
  onSnapshot,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import dataService from "../../service/data/DataService";

const DRCCenterPartnerships = () => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [centers, setCenters] = useState([]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const preprocessData = (data) => {
    return data.map((item) => ({
      ...item,
      name: `${item.first_name} ${item.last_name}`,
      address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip} ${item.county}`,
    }));
  };

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });
        if (userDoc.data().center_id) {
          console.log("Center ID:", userDoc.data().center_id);
          const resp = await dataService.getUsersByCenterIdAndRole(
            userDoc.data().center_id,
            "center"
          );

          setGridData(preprocessData(resp));

          const centers = await dataService.getAllCenters();
          const userCenterIds = userDoc.data().center_id || [];
          const filteredCenters = centers.filter(
            (center) => !userCenterIds.includes(center.center_id)
          );

          setCenters(preprocessData(filteredCenters));
        } else {
          const centers = await dataService.getAllCenters();
          setCenters(preprocessData(centers));
        }
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const sortSettings = {
    allowSorting: true,
  };

  const filterSettings = {
    type: "Menu",
  };
  const [isGridVisible, setIsGridVisible] = useState(true);
  const toggleGrid = (event) => {
    event.preventDefault();
    setIsGridVisible(!isGridVisible);
  };

  const [isGridVisible2, setIsGridVisible2] = useState(true);
  const toggleGrid2 = (event) => {
    event.preventDefault();
    setIsGridVisible2(!isGridVisible2);
  };

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  // Fetch statuses from Firestore when component is mounted
  const [statuses, setStatuses] = useState({}); // Initially empty

  // Fetch statuses from Firestore when the component is mounted
  useEffect(() => {
    const fetchStatusesFromFirestore = () => {
      const unsubscribe = onSnapshot(
        collection(db, "partner-for-approval"),
        (snapshot) => {
          const fetchedStatuses = {};

          snapshot.forEach((doc) => {
            const data = doc.data();
            // Include is_approved in the fetched data
            fetchedStatuses[data.center_id] = {
              partner_id: data.partner_id,
              status: data.status,
              center_id: data.center_id,
              name_of_org: data.name_of_org,
              address: data.address,
              email: data.email,
              mobile_number: data.mobile_number,
              date_of_application: data.date_of_application,
              is_approved: data.is_approved || false, // Fetch approval status
            };

            // If the center is approved, add its center_id to the user's list
            if (data.is_approved) {
              addToUserCenterList({
                center_id: data.center_id,
                partner_id: data.partner_id,
              });
            } else {
              removeCenterFromUserList({
                center_id: data.center_id,
                partner_id: data.partner_id,
              });
            }
          });

          // Update the state with fetched statuses
          setStatuses(fetchedStatuses);
        },
        (error) => {
          console.error("Error fetching statuses from Firestore: ", error);
        }
      );

      // Clean up the listener when the component is unmounted
      return () => unsubscribe();
    };

    fetchStatusesFromFirestore(); // Listen for real-time updates
  }, []);

  // Function to update the user's center_id list
  const addToUserCenterList = async (data) => {
    const centerIdToAdd = data.center_id; // Extract the center_id from the data object

    console.log("Center ID to add:", centerIdToAdd); // Log only the center_id

    if (!data.partner_id) {
      console.error(
        "partner_id is null or undefined. Cannot update user's center list."
      );
      return;
    }

    const userDocRef = doc(db, "users", data.partner_id); // Reference the user's document

    try {
      // Fetch the current user document
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userCenterIds = userDoc.data().center_id || [];

        // Check if the center_id is already in the list
        if (userCenterIds.includes(centerIdToAdd)) {
          console.log(`Center ID ${centerIdToAdd} is already in the list.`);
          return;
        }

        // Add only the center_id to the user's center_id array using arrayUnion
        await updateDoc(userDocRef, {
          center_id: arrayUnion(centerIdToAdd), // Add only the string center_id
        });
        console.log("Center ID added to user's list:", centerIdToAdd);
      }
    } catch (error) {
      console.error("Error adding center ID to user's list:", error);
    }
  };

  const removeCenterFromUserList = async (data) => {
    const centerIdToRemove = data.center_id; // Extract the center_id

    console.log("Center ID to remove:", centerIdToRemove); // Log the center_id to remove

    if (!data.partner_id) {
      console.error(
        "partner_id is null or undefined. Cannot update user's center list."
      );
      return;
    }

    const userDocRef = doc(db, "users", data.partner_id); // Reference the user's document

    try {
      // Remove the center_id from the user's center_id array using arrayRemove
      await updateDoc(userDocRef, {
        center_id: arrayRemove(centerIdToRemove), // Remove the center_id
      });
      console.log("Center ID removed from user's list:", centerIdToRemove);
    } catch (error) {
      console.error("Error removing center ID from user's list:", error);
    }
  };

  // This effect is now responsible only for listening to Firestore writes
  const updateFirestore = async (centerId, centerData) => {
    const { status, center_id } = centerData;

    try {
      // First, check if a document exists for the given center_id
      const q = query(
        collection(db, "partner-for-approval"),
        where("center_id", "==", center_id)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Document exists, get the document ID and update it
        const existingDoc = querySnapshot.docs[0];
        const docId = existingDoc.id;

        if (status === "Applied") {
          // Update the status to "Applied" using the existing document ID
          await updateDoc(doc(db, "partner-for-approval", docId), {
            ...centerData,
            partner_id: data.id, // Make sure to keep data.id as the partner_id
            status: "Applied",
          });
        } else if (status === "Apply") {
          // If status is "Apply", update the document using the existing docId
          await updateDoc(doc(db, "partner-for-approval", docId), {
            status: "Apply",
          });
        }
      } else {
        // If document does not exist, create a new entry with random generated ID
        const newDocId = generateUniqueID(); // Generate a random document ID
        await setDoc(doc(db, "partner-for-approval", newDocId), {
          ...centerData,
          partner_id: data.id, // Store data.id as the partner_id in Firestore
          status: "Applied",
        });
      }
    } catch (error) {
      console.error("Error updating Firestore: ", error);
    }
  };

  const handleStatusToggle = (center) => {
    setStatuses((prevStatuses) => {
      // Access current status, defaulting to "Apply" if it's undefined
      const currentStatus = prevStatuses[center.id]?.status || "Apply";

      // Toggle status between "Apply" and "Applied"
      const newStatus = currentStatus === "Applied" ? "Apply" : "Applied";

      const updatedCenterData = {
        partner_id: prevStatuses[center.id]?.partner_id || generateUniqueID(),
        status: newStatus,
        center_id: center.center_id,
        name_of_org: data.name_of_org,
        address: `${data.address1 || ""} ${data.address2 || ""} ${
          data.city || ""
        } ${data.state || ""} ${data.zip || ""} ${data.county || ""}`,
        email: data.email,
        mobile_number: data.mobile_number,
        date_of_application:
          newStatus === "Applied"
            ? new Date()
            : prevStatuses[center.id]?.date_of_application || null,
      };

      // Update Firestore directly here when toggling the status
      updateFirestore(center.id, updatedCenterData);

      // Return the updated state
      return {
        ...prevStatuses,
        [center.id]: updatedCenterData,
      };
    });
  };

  const statusTemplate = (rowData) => {
    const status = statuses[rowData.id]?.status || "Apply"; // Default to "Apply"
    const isApproved = statuses[rowData.id]?.is_approved; // Check approval status

    return (
      <div>
        {isApproved ? (
          <button className="e-btn e-flat bg-green-500 text-white" disabled>
            Approved
          </button>
        ) : status === "Apply" ? (
          <button
            className="e-btn e-flat e-primary"
            onClick={() => handleStatusToggle(rowData)}
          >
            Apply
          </button>
        ) : (
          <button
            className="e-btn e-flat e-success"
            onClick={() => handleStatusToggle(rowData)}
          >
            Cancel Application
          </button>
        )}
      </div>
    );
  };

  return (
    <div>
      <section className="box-border flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch px-0 pb-[5px] pt-0 text-left font-raleway text-xs text-dodgerblue">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
              DRC Center Partnerships
            </h2>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleGrid}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isGridVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>

        {isGridVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              dataSource={gridData}
              allowSorting={true}
              sortSettings={sortSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
              toolbar={["Search"]}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="name_of_hub"
                  headerText="DRC Center Partners"
                  width="100"
                />
                <ColumnDirective
                  field="address"
                  headerText="Address"
                  width="100"
                />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="100"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="100"
                />
              </ColumnsDirective>
              <Inject services={[Page, Edit, Toolbar, Sort, Filter, Search]} />
            </GridComponent>
          </div>
        )}

        <header className="mb-2 mt-10 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
              Apply for available Centers
            </h2>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleGrid2}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isGridVisible2 ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>

        {isGridVisible2 && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              dataSource={centers}
              allowSorting={true}
              sortSettings={sortSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="name_of_hub"
                  headerText="Name of Center"
                  width="100"
                />
                <ColumnDirective
                  field="address"
                  headerText="Address"
                  width="100"
                />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="100"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="100"
                />
                <ColumnDirective
                  headerText="Status"
                  width="150"
                  template={statusTemplate}
                />
              </ColumnsDirective>

              <Inject services={[Page, Edit, Toolbar, Sort, Filter]} />
            </GridComponent>
          </div>
        )}
      </section>
    </div>
  );
};

export default DRCCenterPartnerships;
