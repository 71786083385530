import axios from 'axios';
import environment from './environment';
import getToken from './token';

const sendOtpEmail = async (toEmail, otp) => {
  try {
    const token = await getToken();
    console.log(token)
    const data = JSON.stringify({
      to_email: toEmail,
      otp: otp,
    });
    
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${environment.apiUrl}/mailer/send_otp`,
      headers: {
        'X-API-Key': environment.apiKey,
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data : data
    };
    
    const response = await axios.request(config)
    
    if (response.status === 200) {
      console.log('OTP sent successfully');
      return response.data;
    } else {
      console.error('Failed to send OTP:', response.status);
      return null;
    }
  } catch (error) {
    console.error('Error sending OTP:', error);
    return null;
  }
};

export default sendOtpEmail;
