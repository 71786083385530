import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Search,
  Edit,
  Toolbar,
  Filter,
  Sort,
  Page,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "../style.css";
import dataService from "../../service/data/DataService";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const editOptions = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};
const filterSettings = {
  type: "Menu",
};

const validationRules = { required: true };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Name: `${item.first_name} ${item.last_name}`,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const formatLastEditedTime = (time) => {
  if (!time) return "Never";

  const now = new Date();
  const diffInSeconds = Math.floor((now - time) / 1000);

  if (diffInSeconds < 60) return "Just now";
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400)
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  if (diffInSeconds < 604800)
    return `${Math.floor(diffInSeconds / 86400)} days ago`;

  return time.toLocaleDateString();
};

const generateRandomPassword = (length = 8) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const servicesProvided = [
  { value: "Social Services", text: "1" },
  { value: "Recovery Support Leader", text: "2" },
  { value: "Red Cross Center", text: "3" },
  { value: "Emergency Services", text: "4" },
  { value: "Housing Assistance", text: "5" },
  { value: "Human/Animal Shelter", text: "6" },
];

const fields = { value: "value", text: "value" };

const Services = (props) => {
  const currentAccess = (props && props.typeService) || [];

  return (
    <div>
      <label htmlFor="typeService" className="mb-2 block text-xs text-gray-500">
        Primary Service Provided
      </label>
      <MultiSelectComponent
        id="typeService"
        dataSource={servicesProvided}
        fields={fields}
        value={currentAccess}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

const PasswordTemplate = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(props.password || "");

  useEffect(() => {
    setPassword(props.password || "");
  }, [props.password]);

  const handleChange = (e) => {
    setPassword(e.value);
    if (props.onChange) {
      props.onChange({ ...e, value: e.value });
    }
  };

  return (
    <div className="relative w-full">
      <TextBoxComponent
        placeholder="Password"
        floatLabelType="Always"
        type={showPassword ? "text" : "password"}
        value={password}
        change={handleChange}
        name="password" // Ensure the name attribute is set for validation
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
  );
};

const CenterLocalNonProfit = ({ className = "" }) => {
  const [centerLocalNPC, setCenterLocalNPC] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        console.log("User ID:", user.uid);
        const contacts = await fetchContacts(user.uid);
        setCenterLocalNPC(contacts);
        const userData = await dataService.getUserProfile("users", user.uid);
        if (userData) {
          setCurrentUserData(userData);
          console.log("Data: ", userData);
        }
      } else {
        setUserId(null);
        setCenterLocalNPC([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleAdd = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const actionBegin = async (args) => {
    if (gridRef.current) {
      try {
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "address1",
                "address2",
                "city",
                "state",
                "zip",
                "email",
                "password",
              ].includes(col.field)
            ) {
              col.visible = true; // Ensure password field is visible during edit/add
            } else if (["Address"].includes(col.field)) {
              col.visible = false;
            }
          }
          if (args.requestType === "add") {
            args.data.password = generateRandomPassword(); // Generate and set random password for new records
          }
        }
        if (args.requestType === "save") {
          setLastEditedTime(new Date());
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
                "password",
              ].includes(col.field)
            ) {
              col.visible = false; // Hide password field after save
            } else if (["Address"].includes(col.field)) {
              col.visible = true;
            }
          }
          // Update Name and Address fields
          const data = args.data;
          data.Address = `${data.address1 || ""} ${data.address2 || ""} ${
            data.city || ""
          } ${data.state || ""} ${data.zip || ""}`;
          // Remove undefined fields

          Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
              delete data[key];
            }
          });

          // Ensure email and password are provided
          if (!data.email || !data.password) {
            console.error("Email and password must be provided.");
            return; // Exit if email or password is missing
          }

          if (args.action === "add") {
            console.log("Adding");
            const userCredential = await createUserWithEmailAndPassword(
              auth,
              data.email,
              data.password
            );
            await signInWithEmailAndPassword(
              auth,
              currentUserData.email,
              currentUserData.password
            );
            const user = userCredential.user; // Define user here
            // Adding new document
            await setDoc(doc(db, "users", user.uid), {
              ...data,
              center_id: userId, // Use logged in user UID as contactID
              last_updated: serverTimestamp(),
              inserted_at: serverTimestamp(),
              user_id: user.uid,
              type: "local-non-profit",
              user_role: "partner",
              is_deleted: false,
            });
            console.log("Done adding");

            // Fetch contacts after successful add
            await fetchContacts(userId);
          } else if (args.action === "edit") {
            if (data.id) {
              const drpDoc = doc(db, "users", data.user_id);
              // Only update password if it has been changed
              if (data.password === args.previousData.password) {
                delete data.password;
              }
              await updateDoc(drpDoc, {
                ...data,
                last_updated: serverTimestamp(),
                is_deleted: false,
              });
            } else {
              console.error("Document ID is missing for update.");
            }

            // Fetch contacts after successful edit
            await fetchContacts(userId);
          }
        }
        if (args.requestType === "delete") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              ["address1", "address2", "city", "state", "zip"].includes(
                col.field
              )
            ) {
              col.visible = false;
            } else if (["Address"].includes(col.field)) {
              col.visible = true;
            }
          }
          const data = args.data[0];
          if (data.id) {
          const drpDoc = doc(db, "users", data.id);
          await updateDoc(drpDoc, {
            is_deleted: true, // Set deleted to true instead of deleting the document
            last_updated: serverTimestamp(),
          });
          } else {
            console.error("Document ID is missing for delete.");
          }

          // Fetch contacts after successful delete
          await fetchContacts(userId);
        }
      } catch (error) {
        console.error("Error during actionBegin:", error);
      }
    }
  };

  return (
    <>
      <div className={`flex max-w-full flex-col gap-5 p-4 ${className}`}>
        <div className="flex items-center gap-2 pt-2 font-raleway text-xs text-darkslateblue">
          <a className="font-medium">Recovery Plan</a>
          <span className="text-base font-semibold text-gray-400">/</span>
          <span className="font-medium">Local Non-profit Coalition</span>
        </div>
      </div>
      <header className=" flex w-full flex-row items-center  justify-between self-stretch rounded-3xs pl-5 pr-5 pt-[8px] text-left font-raleway text-xs text-darkslateblue">
        <div>
          <h2 className="mb-1 font-raleway text-sm font-bold uppercase text-blue-500">
            Local Non-profit Coalition
          </h2>
          <p className="font-poppins text-xs font-medium text-gray-500">
            Last Edited: {formatLastEditedTime(lastEditedTime)}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <button
            type="button"
            className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
            onClick={handleAdd}
          >
            <FaPlus className="h-3 w-3" />
            Add
          </button>
        </div>
      </header>
      <div className="mt-3 w-full rounded-3xs pl-5 pr-5  text-left font-raleway text-xs text-darkslateblue">
        <GridComponent
          dataSource={centerLocalNPC.filter(contact => contact.is_deleted !== true)}
          actionBegin={actionBegin}
          editSettings={editOptions}
          filterSettings={filterSettings}
          allowFiltering={true}
          allowSorting={true}
          ref={gridRef}
          toolbar={["Search"]}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="primaryCounty"
              headerText="Primary County"
              visible={true}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="organization"
              headerText="Organization"
              visible={true}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="typeService"
              headerText="Primary Service Provided"
              editTemplate={Services}
              validationRules={validationRules}
            />
            <ColumnDirective field="Address" headerText="Address" />
            <ColumnDirective
              field="address1"
              headerText="Address 1"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="address2"
              headerText="Address 2"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="city"
              headerText="City"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="state"
              headerText="State"
              visible={false}
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: USStates,
                  fields: { text: "abbreviation", value: "abbreviation" },
                  query: new Query(),
                },
              }}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="zip"
              headerText="Zip"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="mobileNumber"
              headerText="Mobile Number"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="password"
              headerText="Password"
              visible={false}
              validationRules={validationRules}
              editTemplate={PasswordTemplate}
            />
            <ColumnDirective
              headerText="Commands"
              width="120"
              textAlign="Center"
              commands={[
                {
                  type: "Edit",
                  buttonOption: {
                    content: '<i class="fas fa-edit"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
                {
                  type: "Delete",
                  buttonOption: {
                    content: '<i class="fas fa-trash-alt"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
              ]}
            />
          </ColumnsDirective>
          <Inject services={[Edit, Filter, Sort, Page, CommandColumn, Search]} />
        </GridComponent>
      </div>
    </>
  );
};

const fetchContacts = async (userId) => {
  if (!userId) {
    console.log("No user ID available, skipping fetch.");
    return;
  }

  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, "users"),
        where("center_id", "==", userId),
        where("type", "==", "local-non-profit")
      )
    );
    const drpPartnerData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("Fetched data:", drpPartnerData);
    return preprocessData(drpPartnerData);
  } catch (error) {
    console.error("Error fetching contacts:", error);
    return [];
  }
};

export default CenterLocalNonProfit;
