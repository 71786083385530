import React from 'react';

const Journals = ({ className = "" }) => {
  return(
    <div
      className={`w-[1628px] rounded-3xs bg-white border-silver-100 border-[1px] border-solid box-border max-w-full flex flex-col items-start justify-start py-[18px] px-[19px] gap-2.5 leading-[normal] tracking-[normal] text-left text-5xl text-heading-title-color font-raleway mt-2 ${className}`}
    >
      <div className="self-stretch flex flex-row items-center justify-between max-w-full gap-5 text-xs text-dodgerblue lg:flex-wrap">
        <div className="w-[159px] flex flex-col items-start justify-start">
          <a className="[text-decoration:none] relative leading-[21px] font-bold text-[inherit] inline-block min-w-[44px]">
            Journal
          </a>
          <div className="self-stretch relative leading-[21px] font-medium text-silver-200">
            Last Edited: 4 minutes ago
          </div>
        </div>
        <div className="w-[762.5px] flex flex-row items-center justify-end max-w-full">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 15.5L12 8.5L19 15.5" stroke="#152536" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-5 max-w-full">
        <div className="w-[35px] flex flex-col items-start justify-start">
          <a className="[text-decoration:none] self-stretch relative font-bold text-[inherit] mq450:text-lgi">
            17
          </a>
          <b className="w-[34px] relative text-xs flex items-center">MAY</b>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start py-0 pl-0 pr-[198px] box-border gap-[15px] min-w-[996px] max-w-full text-xs lg:min-w-full mq825:pr-[49px] mq825:box-border mq450:pr-5 mq450:box-border mq1450:pr-[99px] mq1450:box-border">
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border gap-5 max-w-full mq1450:flex-wrap">
            <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[43px] whitespace-nowrap">
              8:33 am
            </a>
            <div className="w-[1450px] relative flex items-center shrink-0 max-w-[109%]">{`My House was burned down by the Fire `}</div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border gap-5 max-w-full mq1450:flex-wrap">
            <div className="relative inline-block min-w-[43px] whitespace-nowrap">
              9:42 am
            </div>
            <div className="w-[1450px] relative flex items-center shrink-0 max-w-[109%]">{`The fire was extinguished and we were given first aid `}</div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border gap-5 max-w-full mq1450:flex-wrap">
            <div className="relative inline-block min-w-[44px] whitespace-nowrap">
              5:42 pm
            </div>
            <div className="w-[1450px] relative flex items-center shrink-0 max-w-[109%]">
              Donation came through
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 pl-0 pr-[19px] box-border gap-5 max-w-full">
        <div className="w-[34px] flex flex-col items-start justify-start">
          <b className="relative inline-block min-w-[27px] mq450:text-lgi">
            18
          </b>
          <b className="self-stretch relative text-xs">MAY</b>
        </div>
        <div className="flex-1 flex flex-row flex-wrap items-center justify-start py-0 pl-0 pr-0.5 box-border gap-x-5 gap-y-[18px] min-w-[985px] max-w-full text-xs text-gray-500 lg:min-w-full">
          <div className="relative leading-[20px] text-gray1 inline-block min-w-[45px] whitespace-nowrap">
            7:00 am
          </div>
          <div className="flex-1 rounded-md bg-gray-200 border-gray-300 border-[1px] border-solid box-border flex flex-row items-center justify-start py-2.5 px-4 min-w-[580px] min-h-[38px] max-h-[44px] max-w-full mq825:min-w-full">
            <div className="relative">
              Recording your experiences can be a powerful way to document what
              you’ve been through. Write it here.
            </div>
          </div>
          <div className="w-[50px] relative leading-[12px] font-inter hidden">
            HelpText
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journals;
