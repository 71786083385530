import React, { useState, useRef, useEffect } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Page,
  Sort,
  Filter,
  CommandColumn,
  Search,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { FaPlus } from "react-icons/fa";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import dataService from "../../service/data/DataService";

const filterSettings = {
  type: "Menu",
};

const sortSettings = {
  allowSorting: true,
};

const Citizen = () => {
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  const [citizen, setCitizen] = useState([]);
  const gridRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
        const fetchCitizens = async () => {
          try {
            const citizen = await dataService.getAllCitizens("users");
            setCitizen(citizen);
            const transformedCitizen = citizen.map((c) => ({
              ...c,
              name: `${c.first_name || ""} ${c.last_name || ""}`.trim(),
              address: `${c.address1 || ""} ${c.address2 || ""} ${
                c.city || ""
              } ${c.state || ""} ${c.zip || ""}`.trim(),
            }));

            setCitizen(transformedCitizen);
          } catch (error) {
            console.error("Error fetching centers: ", error);
          }
        };

        fetchCitizens();
      } else {
        setCurrentUserData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    const userData = await dataService.getUserProfile("users", userId);
    if (userData) {
      setCurrentUserData(userData);
      console.log("Data: ", userData);
    }
  };

  return (
    <div>
      <header className="mb-2 mr-4 mt-4 flex flex-row items-center justify-between self-stretch">
        <div>
          <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
            CITIZEN
          </h2>
        </div>
      </header>

      <div className="flex flex-col gap-4 self-stretch">
        <GridComponent
          ref={gridRef}
          dataSource={citizen}
          allowSorting={true}
          sortSettings={sortSettings}
          filterSettings={filterSettings}
          allowFiltering={true}
          // toolbar={["Search"]}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="email"
              headerText="Email"
              width="100"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="password"
              headerText="Password"
              width="100"
              editType="passwordedit"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective field="name" headerText="Name" width="150" />
            <ColumnDirective
              field="first_name"
              headerText="First Name"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="last_name"
              headerText="Last Name"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective field="address" headerText="Address" width="150" />
            <ColumnDirective
              field="address1"
              headerText="Address 1"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="address2"
              headerText="Address 2"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="city"
              headerText="City"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="state"
              headerText="State"
              visible={false}
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: USStates,
                  fields: { text: "abbreviation", value: "abbreviation" },
                  query: new Query(),
                },
              }}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="zip"
              headerText="Zip"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="county"
              headerText="County"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="mobile_number"
              headerText="Mobile Number"
              width="100"
              validationRules={{ required: true }}
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter, Search]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default Citizen;
