import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import note from "../../src/assets/note.png";
import check from "../../src/assets/check.png";
import umbrella from "../../src/assets/umbrella.png";
import lightning from "../../src/assets/lightning.png";
import fire from "../../src/assets/fire.png";
import bxcheck from "../../src/assets/bx-check.svg";
import bxclose from "../../src/assets/bx-close.svg";
import google from "../icons/login/google-icon.svg";
import microsoft from "../icons/login/microsoft-icon.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { USStates } from "./datasource";
import countiesData from "../counties_list.json";
import sendOtpEmail from "../service/api/mailer";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { countries } from "./datasource";

const PartnerRegister = ({ className = "" }) => {
  const [selectedValueNational, setSelectedValueNational] = useState(null);
  const [a501c3, seta501c3] = useState(null);

  const yesNoOptions = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  const handleYesNoChange = (e) => {
    setSelectedValueNational(e.itemData.value);
  };

  const handleYesNoChange01c3 = (e) => {
    seta501c3(e.itemData.value);
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    name_of_org: "",
    part_of_national_agency: false,
    a501c3: false,
    ein: "",
    email: "",
    mobile_number: "",
    password: "",
    country: "US",
    county: "",
  });
  const [confirmPW, setConfirmPW] = useState("");
  const [agree, setAgree] = useState(false);
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [counties, setCounties] = useState([]);
  const [missingFields, setMissingFields] = useState([]);
  const isValidLength = formData.password.length >= 8;
  const hasLowerCase = /[a-z]/.test(formData.password);
  const passwordsMatch = formData.password === confirmPW;
  const [centerIdToAssign, setCenterIdToAssign] = useState("");
  const [centerHubNames, setCenterHubNames] = useState({
    text: "",
    value: "",
  });

  const handleStateChange = (e) => {
    const stateAbbreviation = e.target.value;
    const stateObject = USStates.find(
      (state) => state.abbreviation === stateAbbreviation
    ); // Find the state object
    const stateName = stateObject ? stateObject.name : "";

    setSelectedState(stateAbbreviation);
    setFormData({ ...formData, state: stateAbbreviation }); // Store full state name

    // Filter counties based on selected state
    const filteredCounties = countiesData.filter(
      (county) => county.State === stateName
    );
    setCounties(filteredCounties);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleChange = async (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "a501c3") {
      seta501c3(e.target.value);
    }
  };

  const navigate = useNavigate();

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const fieldLabels = {
    name_of_org: "Name of Organization",
    email: "Email",
    ein: "EIN",
    part_of_national_agency: "National Agency",
    a501c3: "Has a 501c3?",
    first_name: "First Name",
    last_name: "Last Name",
    mobile_number: "Mobile number",
    password: "Password",
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const requiredFields = [
      "name_of_org",
      "email",
      "first_name",
      "last_name",
      "mobile_number",
      "state",
      "county",
      "part_of_national_agency",
      "a501c3",
      "password",
    ];

    // Add EIN to required fields only if a501c3 is "Yes"
    if (formData.a501c3 === "Yes") {
      requiredFields.push("ein");
    }

    const missing = requiredFields.filter((field) => !formData[field]);
    setMissingFields(missing);

    if (missing.length > 0) {
      setTimeout(() => {
        setMissingFields([]);
      }, 3000);
      return;
    }

    try {
      //   if (!agree) {
      //     alert("Check agree!");
      //     return;
      //   }

      if (formData.password !== confirmPW) {
        alert("Password does not match!");
        return;
      }

      const emailExists = await checkIfEmailExists(formData.email);

      if (emailExists) {
        alert("Email already exists. Please use a different email or log in.");
        return;
      }

      // Fetch center_id based on zip code
      //const centerId = await getCenterIdByZip(formData.zip);

      const { email, password, ...otherData } = formData;
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const otp = generateOtp();
      const status = "registered";
      const user_role = "partner";

      await setDoc(doc(db, "users", userCredential.user.uid), {
        email,
        otp,
        status,
        user_role,
        area_serviced: `${formData.county}, ${formData.state}`,
        center_id: centerIdToAssign ? [centerIdToAssign] : [],
        a501c3: formData.a501c3 == "Yes" ? true : false,
        part_of_national_agency:
          formData.part_of_national_agency == "Yes" ? true : false,
        id: userCredential.user.uid,
        ...otherData,
      });

      await sendOtpEmail(email, otp);

      //alert("User registered successfully!");
      const creds = await signInWithEmailAndPassword(auth, email, password);

      login(creds.user.accessToken);

      // After successful registration
      localStorage.setItem("newlyRegisteredPartner", "true");

      setTimeout(() => {
        navigate("/partnerprofile"); // Navigate to PartnerProfile
      }, 1000);
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        alert("This email is already registered.");
      } else if (err.code === "auth/invalid-email") {
        alert("Invalid email format.");
      } else if (err.code === "auth/weak-password") {
        alert("Password is too weak.");
      } else {
        alert(err.message + "ahhdha");
      }
    }
  };

  const handleGoogleSignup = async (e) => {
    e.preventDefault();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    try {
      const result = await signInWithPopup(auth, provider);

      const emailExists = await checkIfEmailExists(result.user.email);
      if (!emailExists) {
        const userData = {
          first_name: result.user.displayName.split(" ")[0],
          last_name: result.user.displayName.split(" ").slice(-1).join(" "),
          email: result.user.email,
          mobile_number: result.user.phoneNumber || "",
          status: "active",
          role: "partner",
        };

        await setDoc(doc(db, "users", result.user.uid), userData);

        alert("User registered successfully!");
        login(result.user.accessToken);
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      } else {
        alert("Email already exists. Logging you in...");
        login(result.user.accessToken);
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      }
    } catch (err) {
      alert(err.message);
      console.error("Error during Google Sign-Up:", err);
    }
  };

  const checkIfEmailExists = async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty; // Returns true if email exists
  };

  const handleCountryChange = (e) => {
    setFormData({
      ...formData,
      country: e.target.value, // Update country in formData
    });
  };

  const handleCountyChange = async (e) => {
    setFormData({
      ...formData,
      county: e.target.value, // Update county in formData
    });

    // Extract string after the comma and trim it
    const county = e.target.value;

    // Create a Firebase query to search in "users" collection
    const usersRef = collection(db, "users");
    const usersQuery = query(
      usersRef,
      where("user_role", "==", "center"),
      where("county", "==", county)
    );

    try {
      const querySnapshot = await getDocs(usersQuery);

      if (!querySnapshot.empty) {
        const users = querySnapshot.docs.map((doc) => doc.data());
        setCenterIdToAssign(users[0].center_id);
        setCenterHubNames(users[0].name_of_hub);
        const nameOfHub = users[0]?.name_of_hub || "";
        setCenterHubNames([{ text: nameOfHub, value: nameOfHub }]);
      } else {
        setCenterHubNames([{ text: "", value: "" }]);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  // New function to get center_id by zip code
  const getCenterIdByZip = async (zipCode) => {
    const usersRef = collection(db, "users");
    const q = query(
      usersRef,
      where("zip", "==", zipCode),
      where("user_role", "==", "center")
    );
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      return snapshot.docs[0].id;
    }

    return "";
  };

  return (
    <div className="flex h-screen w-full justify-center overflow-y-auto bg-white px-6 py-2 font-raleway">
      <div className="my-auto flex h-auto min-h-[90vh] w-full max-w-[850px] overflow-hidden rounded-[10px] bg-white shadow-lg">
        <div
          className="flex-grow flex-col items-center justify-center p-[20px] text-center md:p-[45px]"
          style={{
            border: "1px solid var(--Text-Locked, #D5D7DB)",
            background: "var(--Pure-White, #FFF)",
            boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img
            src={blueSkyLogo}
            className="mx-auto mt-10 h-[100.942px] w-[110.703px]"
          ></img>
          <p className="mt-[5px] text-[20px] font-bold text-[#348BFF]">
            DARCI, powered by Blue Sky
          </p>
          <p className="my-[5px] text-[20px] font-bold text-[#3D435A]">
            Create Account for Partner
          </p>
          {/* <p className="pb-[10px] text-xs text-[#3D435A]">Step 1 of 2</p> */}
          <div className="flex w-full flex-col gap-[15px] pt-3">
            <div>
              <TextBoxComponent
                placeholder="Name of Organization"
                floatLabelType="Auto"
                cssClass="e-outline"
                name="name_of_org"
                value={formData.name_of_org}
                change={(e) =>
                  handleChange({
                    target: { name: "name_of_org", value: e.value },
                  })
                }
              />
              {missingFields.includes("name_of_org") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>

            {/* <div>
              <TextBoxComponent
                placeholder="Area Serviced (State, County)"
                floatLabelType="Auto"
                cssClass="e-outline"
                name="area_serviced"
                value={formData.area_serviced}
                change={(e) =>
                  handleChange({
                    target: { name: "area_serviced", value: e.value },
                  })
                }
              />
              {missingFields.includes("area_serviced") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div> */}

            <div className="flex gap-1">
              <DropDownListComponent
                dataSource={USStates}
                fields={{ text: "abbreviation", value: "abbreviation" }}
                value={selectedState}
                change={(e) =>
                  handleStateChange({ target: { value: e.value } })
                }
                cssClass="e-outline"
                floatLabelType="Auto"
                placeholder="Area Serviced (State)"
              />
              {missingFields.includes("state") && (
                <span className="text-xs text-red-500">Required</span>
              )}

              <DropDownListComponent
                dataSource={counties}
                fields={{ text: "County", value: "County" }}
                value={formData.county}
                change={(e) =>
                  handleCountyChange({ target: { value: e.value } })
                }
                enabled={!!selectedState}
                cssClass="e-outline"
                floatLabelType="Auto"
                placeholder="Area Serviced (County)"
              />
              {missingFields.includes("county") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>

            {centerHubNames.length > 0 && centerHubNames[0].value !== "" && (
              <DropDownListComponent
                dataSource={centerHubNames}
                fields={{ text: "text", value: "value" }}
                placeholder="Partner to a Center within your county"
                floatLabelType="Auto"
                cssClass="e-outline"
                value={centerHubNames[0].text}
              />
            )}

            <div className="w-full">
              <TextBoxComponent
                placeholder="Email Address"
                floatLabelType="Auto"
                cssClass="e-outline"
                name="email"
                value={formData.email}
                change={(e) =>
                  handleChange({ target: { name: "email", value: e.value } })
                }
              />
              {missingFields.includes("email") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>

            <div className="flex gap-2">
              <div className="w-full">
                <TextBoxComponent
                  placeholder="Contact Person First Name"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  name="first_name"
                  value={formData.first_name}
                  change={(e) =>
                    handleChange({
                      target: { name: "first_name", value: e.value },
                    })
                  }
                />
                {missingFields.includes("first_name") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>

              <div className="w-full">
                <TextBoxComponent
                  placeholder="Contact Person Last Name"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  name="last_name"
                  value={formData.last_name}
                  change={(e) =>
                    handleChange({
                      target: { name: "last_name", value: e.value },
                    })
                  }
                />
                {missingFields.includes("last_name") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>
            </div>

            <div>
              <TextBoxComponent
                placeholder="Contact Number"
                floatLabelType="Auto"
                name="mobile_number"
                cssClass="e-outline"
                value={formData.mobile_number}
                change={(e) =>
                  handleChange({
                    target: { name: "mobile_number", value: e.value },
                  })
                }
              />
              {missingFields.includes("mobile_number") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>

            <div className="w-full">
              <DropDownListComponent
                dataSource={yesNoOptions}
                fields={{ text: "text", value: "value" }}
                placeholder="Are you part of National Agency"
                floatLabelType="Auto"
                cssClass="e-outline"
                change={(e) =>
                  handleChange({
                    target: {
                      name: "part_of_national_agency",
                      value: e.value,
                    },
                  })
                }
                value={formData.part_of_national_agency}
              />
              {missingFields.includes("part_of_national_agency") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>

            <div className="w-full">
              <DropDownListComponent
                dataSource={yesNoOptions}
                fields={{ text: "text", value: "value" }}
                placeholder="Are you a 501c3"
                floatLabelType="Auto"
                cssClass="e-outline"
                change={(e) =>
                  handleChange({
                    target: { name: "a501c3", value: e.value },
                  })
                }
                value={formData.a501c3}
              />
              {missingFields.includes("a501c3") && (
                <span className="text-xs text-red-500">Required</span>
              )}
            </div>

            {a501c3 === "Yes" && (
              <div className="w-full">
                <TextBoxComponent
                  placeholder="EIN"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  type="number"
                  name="ein"
                  value={formData.ein}
                  change={(e) =>
                    handleChange({ target: { name: "ein", value: e.value } })
                  }
                />
                {a501c3 === "Yes" && missingFields.includes("ein") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>
            )}

            <div className="flex gap-2">
              <div className="relative w-full">
                <TextBoxComponent
                  placeholder="Password"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  change={(e) =>
                    handleChange({
                      target: { name: "password", value: e.value },
                    })
                  }
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
                {missingFields.includes("password") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>

              <div className="relative w-full">
                <TextBoxComponent
                  placeholder="Confirm Password"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPW}
                  change={(e) => setConfirmPW(e.value)}
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>

            <div className="flex flex-row gap-4 font-raleway">
              <p className="text-start text-xs font-semibold">
                Your password must include:
              </p>
              <div
                className={`flex items-center gap-1 text-xs font-medium ${
                  isValidLength && formData.password
                    ? "text-[#16A34A]"
                    : "text-[#991B1B]"
                }`}
              >
                <img
                  src={isValidLength && formData.password ? bxcheck : bxclose}
                  alt="check-icon"
                />
                <p>At least 8 characters</p>
              </div>
              <div
                className={`flex items-center gap-1 text-xs font-medium ${
                  hasLowerCase && formData.password
                    ? "text-[#16A34A]"
                    : "text-[#991B1B]"
                }`}
              >
                <img
                  src={hasLowerCase && formData.password ? bxcheck : bxclose}
                  alt="check-icon"
                />
                <p>At least 1 lowercase letter</p>
              </div>
              <div
                className={`flex items-center gap-1 text-xs font-medium ${
                  passwordsMatch && formData.password && confirmPW
                    ? "text-[#16A34A]"
                    : "text-[#991B1B]"
                }`}
              >
                <img
                  src={
                    passwordsMatch && formData.password && confirmPW
                      ? bxcheck
                      : bxclose
                  }
                  alt="check-icon"
                />
                <p>Passwords match</p>
              </div>
            </div>

            <div className="mt-[10px] flex flex-col ">
              <button
                className="text-medium h-10 w-full rounded-[4px] bg-[#348BFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
                onClick={handleSignup}
              >
                Create Account
              </button>
              <p className="mt-2 text-sm">
                Already have an account?
                <Link
                  to="/login"
                  className="text-[#348BFF] hover:cursor-pointer"
                >
                  {" "}
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div
          className="relative w-[288px] rounded-r-[10px] bg-[#348BFF]"
          style={{
            border: "1px solid var(--Text-Locked, #D5D7DB)",
            boxShadow: "0px 4px 9.2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img src={note} className="absolute right-0 top-[80px]" />
          <img src={check} className="absolute left-[-10px] top-[230px]" />
          <img src={umbrella} className="absolute right-0 top-[380px]" />
          <img src={lightning} className="absolute left-[-5px] top-[470px]" />
          <img src={fire} className="absolute right-0 top-[600px]" />
        </div>
      </div>
    </div>
  );
};

PartnerRegister.propTypes = {
  className: PropTypes.string,
};

export default PartnerRegister;
