import React, { useState, useRef, useEffect } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import dataService from "../../service/data/DataService";

const PartnerCitizenSearch = () => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(currentUser.uid);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const mapCitizenData = (citizens) => {
    return citizens.map((citizen) => ({
      name: `${citizen.first_name} ${citizen.last_name}`,
      address: `${citizen.address1}, ${citizen.address2}, ${citizen.city}, ${citizen.state}, ${citizen.zip}`,
      mobile_number: citizen.mobile_number,
      email: citizen.email,
    }));
  };

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });
        if (userDoc.data().center_id) {
          const citizens = await dataService.getUsersByCenterIdAndRole(
            userDoc.data().center_id,
            "citizen"
          );
          const gridData = mapCitizenData(citizens);
          setGridData(gridData);
        }
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const sortSettings = {
    allowSorting: true,
  };

  const filterSettings = {
    type: "Menu",
  };
  const [isGridVisible, setIsGridVisible] = useState(true);
  const toggleGrid = (event) => {
    event.preventDefault();
    setIsGridVisible(!isGridVisible);
  };

  return (
    <div>
      <section className="box-border flex max-w-full flex-col items-start justify-start gap-2.5 self-stretch px-0 pb-[5px] pt-0 text-left font-raleway text-xs text-dodgerblue">
        <header className="mb-2 flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="mb-1 font-raleway text-sm font-bold text-blue-500">
              Citizen Search
            </h2>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleGrid}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isGridVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>

        {isGridVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              dataSource={gridData}
              allowSorting={true}
              sortSettings={sortSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
              toolbar={["Search"]}
            >
              <ColumnsDirective>
                <ColumnDirective field="name" headerText="Name" width="100" />
                <ColumnDirective
                  field="address"
                  headerText="Address"
                  width="100"
                />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="100"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="100"
                />
              </ColumnsDirective>
              <Inject services={[Page, Edit, Toolbar, Sort, Filter, Search]} />
            </GridComponent>
          </div>
        )}
      </section>
    </div>
  );
};

export default PartnerCitizenSearch;
