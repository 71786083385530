import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const dataService = {
  getUserProfile: async (collectionName, docId) => {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error reading document: ", error);
      throw error;
    }
  },

  getUsersByCenterIdAndRole: async (centerIds, role) => {
    try {
      const usersRef = collection(db, "users");

      // Build multiple queries for each centerId in the array
      const queries = centerIds.map((centerId) => {
        return query(
          usersRef,
          where("center_id", "==", centerId),
          where("user_role", "==", role)
        );
      });

      const users = [];

      // Run each query and accumulate the results
      for (let q of queries) {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          users.push({ id: doc.id, ...doc.data() });
        });
      }

      return users;
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },

  getAllCenters: async () => {
    try {
      // Query centers from the "users" collection
      const usersQuery = query(
        collection(db, "users"),
        where("user_role", "==", "center")
      );
      const usersSnapshot = await getDocs(usersQuery);
      const users = usersSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      return users;
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },

  getAllCitizens: async (collectionName) => {
    try {
      const q = query(
        collection(db, collectionName),
        where("user_role", "==", "citizen")
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },

  getPartnerForApporval: async (centerId) => {
    try {
      const q = query(
        collection(db, "partner-for-approval"),
        where("center_id", "==", centerId),
        where("status", "==", "Applied")
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },
};
export default dataService;
