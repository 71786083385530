import { db } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

const communityProfileService = {
  // Get center profile
  getCommunityProfileByRole: async (collectionName, centerId) => {
    try {
      const q = query(
        collection(db, collectionName),
        where("user_role", "==", "center"),
        where("center_id", "==", centerId)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0]; // Assuming you want the first match
        return { id: doc.id, ...doc.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error reading document: ", error);
      throw error;
    }
  },

  // Update center profile
  updateCommunityProfile: async (collectionName, docId, data) => {
    try {
      const docRef = doc(db, collectionName, docId);
      const updatedData = { ...data, updated_at: new Date() };
      await updateDoc(docRef, updatedData);
      console.log("Document successfully updated!");
      return { id: docId, ...updatedData };
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  // Add a new center profile
  addCommunityProfile: async (collectionName, data) => {
    try {
      const docRef = await addDoc(collection(db, collectionName), {
        ...data,
        created_at: new Date(),
      });
      console.log("Document written with ID: ", docRef.id);
      return { ...data, id: docRef.id };
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  },

  // Delete a center profile
  deleteCommunityProfile: async (collectionName, docId) => {
    try {
      const docRef = doc(db, collectionName, docId);
      await deleteDoc(docRef);
      console.log("Document deleted successfully");
    } catch (error) {
      console.error("Error deleting document: ", error);
      throw error;
    }
  },

  getStaff: async (centerId) => {
    try {
      const staffRef = collection(db, "users", centerId, "staff");
      const snapshot = await getDocs(staffRef);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error getting members: ", error);
      throw error;
    }
  },

  addStaff: async (userId, staffData) => {
    try {
      console.log("Adding disaster:", staffData); // Log the data being passed
      const docRef = await addDoc(
        collection(db, "users", userId, "staff"),
        staffData
      );
      console.log("Document written with ID: ", docRef.id);
      return { ...staffData, id: docRef.id };
    } catch (error) {
      console.error("Error adding staff: ", error);
      throw error;
    }
  },

  updateStaff: async (centerId, staffId, data) => {
    try {
      // Reference to the specific disaster document
      const staffRef = doc(db, "users", centerId, "staff", staffId);

      // Reference to the partner document where the lastUpdated timestamp will be set
      const partnerRef = doc(db, "users", centerId);

      // Update the disaster document with the provided data
      await setDoc(staffRef, data, { merge: true });

      // Update the partner document with the lastUpdated timestamp
      await setDoc(
        partnerRef,
        { lastUpdatedStaff: serverTimestamp() },
        { merge: true }
      );

      console.log("Staff and lastUpdated timestamp updated successfully");
      return { id: staffId, ...data };
    } catch (error) {
      console.error("Error updating staff: ", error);
      throw error;
    }
  },

  deleteStaff: async (userId, staffId) => {
    try {
      console.log("Deleting staff:", userId, staffId);
      const staffRef = doc(db, "users", userId, "staff", staffId);
      console.log("Document reference:", staffRef.path);
      await deleteDoc(staffRef);
      console.log("Staff deleted successfully");
    } catch (error) {
      console.error("Error deleting staff:", error);
      throw error;
    }
  },

  getGov: async (centerId) => {
    try {
      const govRef = collection(db, "users", centerId, "gov");
      const snapshot = await getDocs(govRef);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error getting members: ", error);
      throw error;
    }
  },

  addGov: async (userId, govData) => {
    try {
      console.log("Adding gov:", govData); // Log the data being passed
      const docRef = await addDoc(
        collection(db, "users", userId, "gov"),
        govData
      );
      console.log("Document written with ID: ", docRef.id);
      return { ...govData, id: docRef.id };
    } catch (error) {
      console.error("Error adding staff: ", error);
      throw error;
    }
  },

  updateGov: async (centerId, govId, data) => {
    try {
      // Reference to the specific disaster document
      const govRef = doc(db, "users", centerId, "gov", govId);

      // Reference to the partner document where the lastUpdated timestamp will be set
      const ref = doc(db, "users", centerId);

      // Update the disaster document with the provided data
      await setDoc(govRef, data, { merge: true });

      // Update the partner document with the lastUpdated timestamp
      await setDoc(ref, { lastUpdatedGov: serverTimestamp() }, { merge: true });

      console.log("Gov and lastUpdated timestamp updated successfully");
      return { id: govId, ...data };
    } catch (error) {
      console.error("Error updating gov: ", error);
      throw error;
    }
  },

  deleteGov: async (userId, govId) => {
    try {
      console.log("Deleting gov:", userId, govId);
      const govRef = doc(db, "users", userId, "gov", govId);
      console.log("Document reference:", govRef.path);
      await deleteDoc(govRef);
      console.log("gov deleted successfully");
    } catch (error) {
      console.error("Error deleting gov:", error);
      throw error;
    }
  },
};

export default communityProfileService;
