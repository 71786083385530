import React from "react";

const PartnerSettings = () => {
  return (
    <div>
      <h1>PartnerSettings</h1>
    </div>
  );
};

export default PartnerSettings;
