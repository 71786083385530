import React from "react";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  PieSeries,
  AccumulationLegend,
  AccumulationDataLabel,
  AccumulationTooltip,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  ColumnSeries,
  Category,
  Inject,
  Legend,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";

const ReportGenerator = ({ className }) => {
  return (
    <div className={`w-full ${className}`}>
      <div className="flex items-center justify-between px-4 pt-4">
        <button className="flex cursor-pointer items-center bg-white text-[12px] font-bold text-blue-500">
          <span className="mr-2">&#8592;</span> BACK TO REPORTS
        </button>
        <button className="flex cursor-pointer items-center rounded bg-blue-500 px-4 py-2 text-[12px] font-bold text-white">
         +<span className="ml-2"> Add </span>
        </button>
      </div>
      <div className="m-4 min-w-[300px] flex-1 rounded-b-mini rounded-tr-mini border border-solid border-gainsboro-200 bg-white p-5">
        <section
          className={`text-13xl flex w-[1315.5px] max-w-full flex-col items-start justify-start gap-5 text-left  font-raleway ${className}`}
        >
          <div className="flex max-w-full flex-col items-start justify-start gap-2.5">
  <select className="relative font-raleway text-[20px] font-extrabold leading-normal text-blue-500 [text-decoration:none]">
    <option value="arizona-fire">ARIZONA FIRE</option>
    <option value="flood">Flood</option>
    <option value="earthquake">Earthquake</option>
    <option value="hurricane">Hurricane</option>
  </select>
  <h1 className="m-0 font-raleway text-[14px] font-extrabold leading-normal">
    Lorem ipsum dolor sit amet consectetur. Gravida tellus convallis eget viverra facilisi odio eget.
  </h1>
</div>

          <div className="flex max-w-full flex-row items-start justify-end self-stretch text-sm text-blue-500">
            <div className="flex w-[1141px] max-w-full flex-col items-start justify-start gap-5">
              <div className="mq1100:pl-[33px] mq1100:pr-[33px] mq1100:box-border box-border flex w-[1088px] max-w-full flex-row items-start justify-start px-[66px] py-0">
                <div className="flex max-w-full flex-1 flex-row flex-wrap content-start items-start justify-start gap-5">
                  <div className="z-[1] box-border flex w-[282px] flex-row items-start justify-start gap-[13px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white pb-6 pl-[22px] pr-3 pt-3">
                    <div className="relative box-border hidden h-[158px] w-[282px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white" />
                    <div className="flex flex-1 flex-col items-start justify-start px-0 pb-0 pt-3">
                      <div className="z-[2] flex flex-col items-start justify-start gap-[9px] self-stretch py-0 pl-0 pr-[37px]">
                        <h2 className="relative m-0 inline-block min-w-[108px] font-[inherit] text-inherit font-bold text-blue-800">
                          People Affected
                        </h2>
                        <div className="text-29xl flex flex-row items-start justify-start gap-2.5 self-stretch text-darkslategray-300">
                          <div className="relative inline-block text-[40px] font-semibold text-darkslategray-300">
                            1000
                          </div>
                          <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[18.5px] text-base">
                            <h1 className="relative m-0 inline-block min-w-[54px] font-[inherit] text-inherit font-semibold">
                              People
                            </h1>
                          </div>
                        </div>
                        <div className="relative pb-2 font-semibold text-gray1-100">
                          As of February 25, 2024
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-border flex min-w-[233px] max-w-full flex-1 flex-row items-start justify-between gap-5 rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white pb-[33px] pl-[23px] pr-[15px] pt-3">
                    <div className="relative box-border hidden h-[158px] w-[360px] max-w-full rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white" />
                    <div className="box-border flex w-[213px] flex-col items-start justify-start px-0 pb-0 pt-3">
                      <div className="relative z-[1] flex flex-col items-start justify-start gap-[9px] self-stretch px-0 pb-0 pt-[25px]">
                        <h2 className="absolute right-[-69px] top-[0px] !m-[0] font-[inherit] text-inherit font-bold">
                          Immediate Response Assistance Received
                        </h2>
                        <div className="relative inline-block text-[40px] font-semibold text-darkslategray-300">
                          $150,000
                        </div>
                        <div className="relative font-semibold text-gray1-100">
                          As of February 25, 2024
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-border flex w-[273px] flex-row items-start justify-start gap-[5.5px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white pb-[33px] pl-[23px] pr-[9px] pt-3">
                    <div className="relative box-border hidden h-[158px] w-[273px] rounded-b-mini rounded-tl-none rounded-tr-mini border-[1px] border-solid border-gainsboro-200 bg-white" />
                    <div className="flex flex-1 flex-col items-start justify-start px-0 pb-0 pt-3">
                      <div className="z-[1] flex flex-col items-start justify-start gap-[9px] self-stretch">
                        <h2 className="relative m-0 font-[inherit] text-inherit font-bold">
                          Recovery Assistance Received
                        </h2>
                        <div className="relative inline-block text-[40px] font-semibold text-darkslategray-300">
                          $140,000
                        </div>
                        <div className="relative font-semibold text-gray1-100">
                          As of February 25, 2024
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mx-auto mt-4 w-full max-w-[1315.5px]">
          <div className="flex flex-wrap justify-between gap-5">
            <ChartCard title="Disaster Impact">
              <AccumulationChartComponent
                id="disaster-impact"
                tooltip={{ enable: true }}
                height="300px"
                legendSettings={{
                  visible: true,
                  position: "Bottom",
                }}
              >
                <Inject
                  services={[
                    PieSeries,
                    AccumulationLegend,
                    AccumulationDataLabel,
                    AccumulationTooltip,
                  ]}
                />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective
                    dataSource={[
                      { x: "Affected", y: 40 },
                      { x: "Not Affected", y: 60 },
                    ]}
                    xName="x"
                    yName="y"
                    innerRadius="40%"
                    dataLabel={{
                      visible: true,
                      position: "Outside",
                      name: "x",
                    }}
                    palettes={["#1e90ff", "#282828"]}
                  />
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            </ChartCard>
            <ChartCard title="Injury">
              <ChartComponent
                id="injury"
                primaryXAxis={{
                  valueType: "Category",
                  labelStyle: { size: "10px" },
                  enableTextWrapping: true,
                  maximumLabelWidth: 60,
                  labelIntersectAction: "Wrap",
                  majorGridLines: { width: 0 },
                  minorGridLines: { width: 0 },
                  majorTickLines: { width: 0 },
                  minorTickLines: { width: 0 },
                }}
                primaryYAxis={{
                  minimum: 0,
                  maximum: 70,
                  interval: 10,
                  labelFormat: "{value}%",
                }}
                height="300px"
                enableSmartLabels={true}
                palettes={["#1e90ff"]}
              >
                <Inject
                  services={[
                    ColumnSeries,
                    Category,
                    Legend,
                    DataLabel,
                    Tooltip,
                  ]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={[
                      { x: "No Injury", y: 60 },
                      { x: "Injured but no medical needed", y: 10 },
                      { x: "Sought medical treatment", y: 5 },
                      { x: "Visited Hospital", y: 8 },
                      { x: "Admitted", y: 1 },
                    ]}
                    xName="x"
                    yName="y"
                    type="Column"
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </ChartCard>

            <ChartCard title="Home Damage">
              <ChartComponent
                id="home-damage"
                height="300px"
                primaryXAxis={{
                  valueType: "Category",
                  labelStyle: { size: "10px" },
                  enableTextWrapping: true,
                  maximumLabelWidth: 60,
                  labelIntersectAction: "Wrap",
                  majorGridLines: { width: 0 },
                  minorGridLines: { width: 0 },
                  majorTickLines: { width: 0 },
                  minorTickLines: { width: 0 },
                }}
                primaryYAxis={{
                  minimum: 0,
                  maximum: 70,
                  interval: 10,
                  labelFormat: "{value}%",
                }}
                palettes={["#1e90ff"]}
              >
                <Inject
                  services={[
                    ColumnSeries,
                    Category,
                    Legend,
                    DataLabel,
                    Tooltip,
                  ]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={[
                      { x: "No Damage", y: 5 },
                      { x: "Slight Damage", y: 8 },
                      { x: "Limited Damage", y: 18 },
                      { x: "Severe Damage", y: 45 },
                      { x: "Irreparable Damage", y: 10 },
                      { x: "Total Destruction", y: 8 },
                    ]}
                    xName="x"
                    yName="y"
                    type="Column"
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </ChartCard>
          </div>
        </section>

        <section className=" mt-4 w-full ">
          <div className="flex justify-between gap-5">
            <ChartCardLong title="Type of Home Damage">
              <ChartComponent
                id="home-damage-type"
                primaryXAxis={{
                  valueType: "Category",
                  labelStyle: { size: "10px" },
                  enableTextWrapping: true,
                  maximumLabelWidth: 60,
                  labelIntersectAction: "Wrap",
                  majorGridLines: { width: 0 },
                  minorGridLines: { width: 0 },
                  majorTickLines: { width: 0 },
                  minorTickLines: { width: 0 },
                }}
                primaryYAxis={{
                  minimum: 0,
                  maximum: 70,
                  interval: 10,
                  labelFormat: "{value}%",
                }}
                height="350px"
                width="920px"
                chartArea={{ border: { width: 0 } }}
                palettes={["#1e90ff"]}
              >
                <Inject
                  services={[ColumnSeries, Category, DataLabel, Tooltip]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={[
                      { x: "Gutters", y: 62 },
                      { x: "Foundation", y: 35 },
                      { x: "Flooring", y: 5 },
                      { x: "Drywall", y: 7 },
                      { x: "HVAC", y: 57 },
                      { x: "Shed/ Garage", y: 37 },
                      { x: "Yard/ Landscaping", y: 37 },
                      { x: "Roof", y: 28 },
                      { x: "Siding", y: 27 },
                      { x: "Ceiling", y: 27 },
                      { x: "Floor Joists", y: 27 },
                      { x: "Cabinets", y: 27 },
                      { x: "Electrical", y: 27 },
                      { x: "Driveway", y: 27 },
                      { x: "Other", y: 27 },
                    ]}
                    xName="x"
                    yName="y"
                    type="Column"
                    dataLabel={{
                      visible: true,
                      position: "Top",
                      format: "{value}%",
                      font: { size: "10px" },
                    }}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </ChartCardLong>

            <ChartCardLong title="Homes Destroyed">
              <AccumulationChartComponent
                id="homes-destroyed"
                tooltip={{ enable: true }}
                height="300px"
                width="350px"
                enableSmartLabels="true"
                dataLabel={{
                  textWrap: "Wrap",
                  maxWidth: 100,
                  enableRotation: true,
                  angle: 90,
                }}
                legendSettings={{
                  visible: true,
                  position: "Bottom",
                  alignment: "Center",
                  orientation: "Horizontal",
                }}
              >
                <Inject
                  services={[
                    PieSeries,
                    AccumulationLegend,
                    AccumulationDataLabel,
                    AccumulationTooltip,
                  ]}
                />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective
                    dataSource={[
                      { x: "Damaged", y: 55, color: "#808080" },
                      { x: "Destroyed", y: 23, color: "#B22222" },
                      { x: "Undamaged", y: 22, color: "#D3D3D3" },
                    ]}
                    xName="x"
                    yName="y"
                    innerRadius="40%"
                    dataLabel={{
                      visible: true,
                      position: "Outside",
                      name: "x",
                      format: "{point.x}: {point.y}%",
                    }}
                  />
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            </ChartCardLong>
          </div>
        </section>
      </div>
    </div>
  );
};

const ChartCard = ({ title, children }) => (
  <div className="min-w-[300px] flex-1 rounded-b-mini rounded-tr-mini border border-solid border-gainsboro-200 bg-white p-5">
    <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
      {title}
      <span className="text-gray-400">⋮</span>
    </h2>
    {children}
  </div>
);

const ChartCardLong = ({ title, children }) => (
  <div className="w-fit flex-1 rounded-b-mini rounded-tr-mini border border-solid border-gainsboro-200 bg-white p-5">
    <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
      {title}
      <span className="text-gray-400">⋮</span>
    </h2>
    {children}
  </div>
);

export default ReportGenerator;
