import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

const LockBoxService = {
  fetchDocumentsFromLockbox: async (userId, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "lockbox",
        userId,
        collectionName
      );
      const documentsSnapshot = await getDocs(documentsCollectionRef);

      const documentsList = documentsSnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          id: doc.id,
          ...docData,
        };
      });

      return documentsList;
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  },

  addDocumentToLockbox: async (userId, documentData, type) => {
    try {
      const documentsCollectionRef = collection(db, "lockbox", userId, type);

      await addDoc(documentsCollectionRef, documentData);

      console.log("Document successfully added to the lockbox!");
    } catch (error) {
      console.error("Error adding document to lockbox: ", error);
      throw error;
    }
  },
  updateDocumentInLockbox: async (userId, documentId, documentData, type) => {
    try {
      const documentsCollectionRef = collection(db, "lockbox", userId, type);

      const idField = type === "documents_data" ? "documentsID" : "PhotosID";

      const q = query(documentsCollectionRef, where(idField, "==", documentId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Update each matching document (should be only one in your case)
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await updateDoc(documentRef, documentData);
        });
        console.log("Document successfully updated in the lockbox!");
      } else {
        console.error("No document found with the given ID to update.");
        throw new Error("No document found with the given ID to update.");
      }
    } catch (error) {
      console.error("Error updating document in lockbox: ", error);
      throw error;
    }
  },

  // Delete a document from the "documents_data" collection based on documentId
  deleteDocumentFromLockbox: async (userId, documentId, type) => {
    try {
      // Log documentId to check if it's defined
      console.log("Deleting document with ID:", documentId);

      if (!documentId) {
        throw new Error("documentId is undefined or null");
      }

      // Create a query to find the document with the given documentId
      const documentsCollectionRef = collection(db, "lockbox", userId, type);
      const idField = type === "documents_data" ? "documentsID" : "PhotosID";

      const q = query(documentsCollectionRef, where(idField, "==", documentId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Delete each matching document (should be only one in your case)
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await deleteDoc(documentRef);
        });
        console.log("Document successfully deleted from the lockbox!");
      } else {
        console.error("No document found with the given ID to delete.");
        throw new Error("No document found with the given ID to delete.");
      }
    } catch (error) {
      console.error("Error deleting document from lockbox: ", error);
      throw error;
    }
  },
};
export default LockBoxService;
